import { Link } from 'react-router-dom';
import { HandySvg } from 'handy-svg';

//media
import searchIcon from '../../assets/bottomNav/search.svg';
import starIcon from '../../assets/bottomNav/star.svg';
import messageIcon from '../../assets/bottomNav/message.svg';
import profileIcon from '../../assets/bottomNav/profile.svg';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../utils/AuthContext';
import { useContext } from 'react';
import { useScrollDirection } from '../../hooks/useScrollDirection';

function BottomLayout() {
  const { user, user_status } = useSelector((state) => state.userState);
  const { setAuthType } = useContext(AuthContext);

  const scrollDirection = useScrollDirection();

  return (
    <div
      className='bottomNav'
      style={
        scrollDirection === 'down' ? { bottom: '-90px' } : { bottom: '0' }
      }>
      <div className='bottomNav__wrapper'>
        <Link to='/' className='bottomNav__link'>
          <div className='bottomNav__link-icon'>
            <span>
              <HandySvg src={searchIcon} width='22' height='22' />
            </span>
          </div>
          <p className='bottomNav__link-text'>Вакансии</p>
        </Link>
        <Link
          onClick={() => {
            if (user === null) setAuthType('login');
          }}
          to={user !== null && 'fav'}
          className='bottomNav__link'>
          <div className='bottomNav__link-icon'>
            <span>
              <HandySvg src={starIcon} width='22' height='22' />
            </span>
          </div>
          <p className='bottomNav__link-text'>Избранное</p>
        </Link>
        <Link
          onClick={() => {
            if (user === null) setAuthType('login');
          }}
          to={user !== null && '/chat'}
          className='bottomNav__link'>
          <div className='bottomNav__link-icon'>
            <span>
              <HandySvg src={messageIcon} width='22' height='22' />
            </span>
          </div>
          <p className='bottomNav__link-text'>Сообщения</p>
        </Link>
        <Link
          onClick={() => {
            if (user === null) setAuthType('login');
          }}
          to={user !== null && (user_status === 'entity' ? `/company` : '/me')}
          className='bottomNav__link'>
          <div className='bottomNav__link-icon'>
            <span>
              <HandySvg src={profileIcon} width='22' height='22' />
            </span>
          </div>
          <p className='bottomNav__link-text'>Кабинет</p>
        </Link>
      </div>
    </div>
  );
}

export default BottomLayout;
