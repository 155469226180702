import { useEffect } from 'react';
import SeoHelmet from '../../../hoc/SeoHelmet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TiArrowLeft } from 'react-icons/ti';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import {
  useGetCategoriesQuery,
  useGetSubCategoriesMutation,
} from '../../../redux';
import { useGetCategoryCardsMutation } from '../../../redux/api/categoryDataList';

function VacancesSubList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { category } = useParams();
  const { hash } = useLocation();
  const { data: categoriesData } = useGetCategoriesQuery();

  const [currentCategory, setCurrentCategory] = useState('');
  const [currentSubCategory, setCurrentSubCategory] = useState('');

  const categoryParent =
    categoriesData !== undefined
      ? categoriesData.find((el) => el.slug === category)
      : {};

  const [
    getSubCategories,
    { data: subCategoriesData, isLoading: isSubCategoryLoading },
  ] = useGetSubCategoriesMutation();

  useEffect(() => {
    let body = {
      spec_slug: category,
    };
    getSubCategories(body);
    if (hash.length > 1) {
      setCurrentCategory(hash.slice(1));
    } else setCurrentCategory(category);
  }, []);

  useEffect(() => {
    navigate(`#${currentCategory}`);
  }, [currentCategory]);

  return (
    <>
      <SeoHelmet
        title={`${categoryParent?.specialization ?? ''}${
          currentSubCategory ? ' - ' + currentSubCategory : ''
        }`}
        innerTitle={`${categoryParent?.specialization ?? ''}${
          currentSubCategory ? ' - ' + currentSubCategory : ''
        }`}
        description='Поиск по категориям/рубрикам'
        location={window.location.href}
        isLoading={isSubCategoryLoading}
      />
      <div className='vacancesList sub'>
        <p className='vacancesList__backLink' onClick={() => navigate('/')}>
          <TiArrowLeft size={28} />
          {width >= 1266 && <span>{t('CategoryList.backBtn')}</span>}
        </p>
        <h3 className='vacancesList__title unSelect'>
          {`${categoryParent?.specialization ?? ''}${
            currentSubCategory ? ' - ' + currentSubCategory : ''
          }`}
        </h3>
        <div className='vacancesList__group'>
          <ul>
            {!isSubCategoryLoading &&
              subCategoriesData?.length &&
              subCategoriesData.map((category) => (
                <li key={category.slug}>
                  <Link
                    onClick={() => {
                      setCurrentSubCategory(category?.specialization);
                      setCurrentCategory(category.slug);
                    }}
                    className={`${
                      hash.slice(1) == category.slug ? 'active' : ''
                    }`}>
                    {category?.specialization}
                  </Link>{' '}
                  <span>({category?.count_v})</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default VacancesSubList;
