import { Fragment, memo, useCallback, useContext, useState } from 'react';
import { AiFillStar, AiOutlineUser } from 'react-icons/ai';
import { ImNewTab } from 'react-icons/im';
import { VscCopy, VscFilePdf } from 'react-icons/vsc';
import {
  BsMailbox,
  BsInstagram,
  BsWhatsapp,
  BsTelephone,
  BsTelegram,
} from 'react-icons/bs';
import { HandySvg } from 'handy-svg';
import Fancybox from '../../utils/Fancybox';
import { toast } from 'react-toastify';
import { baseDOMAIN } from '../../configs/axiosConfig';
import Icon from '../../assets/svg/card-icon.svg';
import print from '../../assets/svg/print.svg';
import { ReactComponent as AiOutlineStar } from '../../assets/svg/star.svg';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { ContentContext } from '../../utils/ContentContext';
import { AuthContext } from '../../utils/AuthContext';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  useAddFavoriteVacancyMutation,
  useGetResumePDFMutation,
} from '../../redux';
import { useLazyGetMeQuery } from '../../redux/api/userApi';
import { declension, timeAgo, toMonthYear } from '../../hooks/toDateSet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import companyLogoPsh from '../../assets/images/logo-placeholder.jpg';
import userLogoPsh from '../../assets/images/placeholderUser.webp';
import { useGetContactsPbpMutation } from '../../redux/api/mockApi';

export function getAge(ageOfYear) {
  let today = new Date();
  let birthDate = new Date(ageOfYear);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return (
    age +
    ' ' +
    (age % 10 == 1 && age % 100 != 11
      ? 'Год'
      : age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)
      ? 'Года'
      : 'Лет')
  );
}

export const isEmpty = (val) => {
  if (val == null || val == 'null' || val == undefined) return false;
  if (Array.isArray(val)) {
    if (val?.length > 0 && val.every(isEmpty)) {
      return true;
    }
    return false;
  }
  switch (typeof val) {
    case 'object': {
      return Object?.keys(val)?.length > 0 ? true : false;
      break;
    }
    case 'string': {
      if (val.replace(/\s/g, '' && val != 'null')?.length > 0) return true;
      break;
    }
    default:
      return false;
  }
};

export function CardMore({ data }) {
  const [viewContacts, setViewContacts] = useState(false);
  const { user } = useSelector((s) => s.userState);
  const { setAuthType } = useContext(AuthContext);

  const handleViewContacts = () => {
    if (user !== null) {
      setViewContacts(true);
    } else {
      toast.info('Вы должны быть авторизованы');
      setAuthType('login');
    }
  };

  if (data?.profile) {
    return (
      <div className='card__moreInfo' data-aos='fade-down'>
        <div className='card__moreInfo-up'>
          <div className='card__moreInfo-part'>
            <h4>
              <HandySvg src={Icon} width='16px' height='16px' />
              <p>Требования</p>
            </h4>
            <ul className='card__moreInfo-list'>
              {isEmpty(data?.requirements) ? (
                <ReactMarkdown
                  children={data?.requirements}
                  rehypePlugins={[rehypeRaw]}
                />
              ) : (
                <li style={{ marginBottom: '0' }}>Не указано</li>
              )}
            </ul>
          </div>
          <div className='card__moreInfo-part'>
            <h4>
              <HandySvg src={Icon} width='16px' height='16px' />
              <p>Обязанности</p>
            </h4>
            <ul className='card__moreInfo-list'>
              {isEmpty(data?.responsibilities) ? (
                <ReactMarkdown
                  children={data?.responsibilities}
                  rehypePlugins={[rehypeRaw]}
                />
              ) : (
                <li>Не указано</li>
              )}
            </ul>
          </div>
          <div className='card__moreInfo-part'>
            <h4>
              <HandySvg src={Icon} width='16px' height='16px' />
              <p>Условия работы</p>
            </h4>
            <ul className='card__moreInfo-list'>
              {isEmpty(data?.terms) ? (
                <ReactMarkdown
                  children={data?.terms}
                  rehypePlugins={[rehypeRaw]}
                />
              ) : (
                <li>Не указано</li>
              )}
            </ul>
          </div>
          {!data?.is_anonymous && (
            <div className='card__moreInfo-part'>
              <h4>
                <HandySvg src={Icon} width='16px' height='16px' />
                <p>Контакты</p>
              </h4>
              {viewContacts ? (
                <ul className='card__moreInfo-contact'>
                  {isEmpty(data?.personal) ?? !data?.is_anonymous ? (
                    <>
                      {data?.personal?.full_name && (
                        <li>
                          ФИО:
                          <b>{data?.personal?.full_name}</b>
                        </li>
                      )}
                      {data?.personal?.position && (
                        <li>
                          Должность:
                          <b>{data?.personal?.position}</b>
                        </li>
                      )}
                      {data?.personal?.phone && (
                        <li>
                          Телефон:
                          <a href={`tel:${data?.personal?.phone}`}>
                            {data?.personal?.phone}
                          </a>
                        </li>
                      )}
                      {data?.profile?.address && (
                        <li>
                          Адрес: <b>{data?.profile?.address}</b>
                        </li>
                      )}
                      <CardSocsInfo data={data?.profile} />
                    </>
                  ) : (
                    <li>
                      <b style={{ margin: '0' }}>Не указано</b>
                    </li>
                  )}
                </ul>
              ) : (
                <h5
                  className='card__moreInfo-contact-showBtn'
                  onClick={handleViewContacts}>
                  <b>Показать контакты</b>
                </h5>
              )}
            </div>
          )}
        </div>
        <div className='card__moreInfo-footer'></div>
      </div>
    );
  }
  if (!data?.profile) {
    return (
      <div className='card__moreInfo' data-aos='fade-down'>
        <div className='card__moreInfo-up resume'>
          <div className='card__moreInfo-part'>
            <h4>
              <HandySvg src={Icon} width='16px' height='16px' />
              <p>Кратко о себе</p>
            </h4>
            <ul className='card__moreInfo-list'>
              <li>
                {data?.gender}, {getAge(data?.agge)} (
                {new Date(data.agge).toLocaleDateString()})
              </li>
              {data?.educations.length > 0 && (
                <li>
                  {data?.educations[
                    data?.educations.length - 1
                  ]?.category.toLowerCase()}{' '}
                  образование
                </li>
              )}
              {data?.family_position && <li>состоит в браке</li>}
              {!data?.family_position && <li>не состоит в браке</li>}
              {data?.driver_license && (
                <li>
                  права категории "{data?.driver_license}"{' '}
                  {data.auto && 'с личным автомобилем'}
                </li>
              )}
            </ul>
          </div>
          <div className='card__moreInfo-part'>
            <h4>
              <HandySvg src={Icon} width='16px' height='16px' />
              <p>Пожелания к работе</p>
            </h4>
            <ul className='card__moreInfo-list'>
              <li style={{ display: 'flex', gap: '16px' }}>
                <span>Тип занятости:</span>{' '}
                <div>{data?.type_of_employment}</div>
              </li>
              <li style={{ display: 'flex', gap: '16px' }}>
                <span>Готовность к переезду:</span>
                <div>
                  {data?.business_trips === 'ready'
                    ? data?.gender === 'мужчина'
                      ? 'готов'
                      : 'готова'
                    : data?.business_trips === 'not_ready'
                    ? data?.gender === 'женщина'
                      ? 'не готова'
                      : 'не готов'
                    : 'не имеет значения'}
                </div>
              </li>
            </ul>
          </div>
          <div className='card__moreInfo-part'>
            <h4>
              <HandySvg src={Icon} width='16px' height='16px' />
              <p>Основное образование</p>
            </h4>
            <ul className='card__moreInfo-list'>
              {isEmpty(data?.educations) ? (
                data?.educations.map((el) => (
                  <li key={el.id} style={{ display: 'flex', gap: '16px' }}>
                    <span
                      style={{
                        minWidth: '116px',
                        maxWidth: '116px',
                        display: 'inline-block',
                      }}>
                      {el.date_finish
                        ? toMonthYear(
                            el.date_finish.split('.').reverse().join('-')
                          )
                        : el?.category}
                    </span>
                    <div>
                      <span style={{ fontWeight: '500' }}>
                        {el.institution}
                      </span>
                      <p>{el.faculty}</p>
                    </div>
                  </li>
                ))
              ) : (
                <li>Не указано</li>
              )}
            </ul>
          </div>
          {data?.courses.filter((el) => el?.title).length > 0 && (
            <div className='card__moreInfo-part'>
              <h4>
                <HandySvg src={Icon} width='16px' height='16px' />
                <p>Курсы и повышение квалификации</p>
              </h4>
              <ul className='card__moreInfo-list'>
                {data?.courses
                  .filter((el) => el?.title)
                  .map((el) => (
                    <li key={el.id} style={{ display: 'flex', gap: '16px' }}>
                      <span
                        style={{
                          minWidth: '116px',
                          maxWidth: '116px',
                          display: 'inline-block',
                        }}>
                        {el.date_finish
                          ? toMonthYear(
                              el.date_finish.split('.').reverse().join('-')
                            )
                          : 'Не указано'}
                      </span>
                      <div>
                        <span style={{ fontWeight: '500' }}>{el.title}</span>
                        <p>{el.company}</p>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          )}
          {!data?.no_experience && (
            <div className='card__moreInfo-part'>
              <h4>
                <HandySvg src={Icon} width='16px' height='16px' />
                <p>Опыт работы</p>
              </h4>
              <ul className='card__moreInfo-list'>
                {isEmpty(data?.experiences) ? (
                  data?.experiences
                    .filter((el) => el?.place_of_work)
                    .map((el) => (
                      <li key={el.id} style={{ display: 'flex', gap: '16px' }}>
                        <div>
                          <span
                            style={{
                              minWidth: '116px',
                              maxWidth: '116px',
                              display: 'inline-block',
                            }}>
                            {el?.work_date_from
                              ? toMonthYear(
                                  el?.work_date_from
                                    .split('.')
                                    .reverse()
                                    .join('-')
                                )
                              : 'Дата не указана'}
                          </span>
                          <br />
                          {el?.work_date_to ? (
                            <>
                              <span>–</span>
                              <br />
                              <span
                                style={{
                                  minWidth: '116px',
                                  maxWidth: '116px',
                                  display: 'inline-block',
                                }}>
                                {toMonthYear(
                                  el?.work_date_to
                                    .split('.')
                                    .reverse()
                                    .join('-')
                                )}
                              </span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          <span style={{ fontWeight: '500' }}>
                            {el?.place_of_work}{' '}
                            {el.city ? `(${el.city.trim()})` : ''}
                          </span>
                          <p>{el?.field_of_activity}</p>
                          <p>Должность: {el?.position ?? 'Не указано'}</p>
                          <p>
                            Обязанности, достижения:{' '}
                            {el?.description || 'Не указано'}
                          </p>
                        </div>
                      </li>
                    ))
                ) : (
                  <li>Не указано</li>
                )}
              </ul>
            </div>
          )}
          {isEmpty(data.recommendations) && (
            <div className='card__moreInfo-part'>
              <h4>
                <HandySvg src={Icon} width='16px' height='16px' />
                <p>Рекомендации бывших работодателей</p>
              </h4>
              <ul className='card__moreInfo-list'>
                {data?.recommendations.map((el) => (
                  <li key={el.id} style={{ display: 'flex', gap: '16px' }}>
                    <span
                      style={{
                        minWidth: '116px',
                        maxWidth: '150px',
                        overflowX: 'scroll',
                        display: 'inline-block',
                      }}>
                      {el.place_of_work || 'Место не указано'}
                    </span>
                    <div>
                      <span style={{ fontWeight: '500' }}>{el.full_name}</span>
                      <p>Должность: {el?.position || 'Не указано'}</p>
                      <p>
                        Почта:{' '}
                        <a
                          style={{ color: '#000', fontWeight: '500' }}
                          href={`mailto:${el?.mail}`}>
                          {el?.email || 'Не указано'}
                        </a>
                      </p>
                      <p>
                        Контакты:{' '}
                        <a
                          style={{ color: '#000', fontWeight: '500' }}
                          href={`tel:${el?.phone}`}>
                          {el?.phone || 'Не указано'}
                        </a>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className='card__moreInfo-part'>
            <h4>
              <HandySvg src={Icon} width='16px' height='16px' />
              <p>Профессиональные навыки</p>
            </h4>
            <ul className='card__moreInfo-list'>
              <li style={{ display: 'flex', gap: '16px' }}>
                <span>Иностранные языки:</span>
                <div>
                  {isEmpty(data?.languages) ? (
                    data?.languages
                      .filter((language) => language?.title)
                      .map((language, idx) => (
                        <Fragment key={idx}>
                          {isEmpty(language?.title) && (
                            <p>
                              <span style={{ textTransform: 'capitalize' }}>
                                {language?.title}:
                              </span>{' '}
                              <span style={{ textTransform: 'lowercase' }}>
                                {language?.level}
                              </span>
                            </p>
                          )}
                        </Fragment>
                      ))
                  ) : (
                    <p>Не указано</p>
                  )}
                </div>
              </li>
              <li style={{ display: 'flex', gap: '104px' }}>
                <span>Навыки:</span>
                <div>
                  {isEmpty(data?.key_skills) ? (
                    <p>{data?.key_skills}</p>
                  ) : (
                    <p>Не указано</p>
                  )}
                </div>
              </li>
            </ul>
          </div>
          {isEmpty(data.additional_information) && (
            <div className='card__moreInfo-part'>
              <h4>
                <HandySvg src={Icon} width='16px' height='16px' />
                <p>Дополнительная информация</p>
              </h4>
              <ul className='card__moreInfo-list'>
                <li>{data?.additional_information}</li>
              </ul>
            </div>
          )}
        </div>
        <div className='card__moreInfo-footer'></div>
      </div>
    );
  }
}

export function CardMoreFooter({ data, isDetailPage, isPrivateResume }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userState);
  const { setAuthType } = useContext(AuthContext);
  const { hash } = useLocation();
  const isVacancy = !!data?.profile;
  let currentUrl = `https://${baseDOMAIN}/${
    isVacancy ? 'vacancies' : 'resumes'
  }/${data.id}`;
  let quote = `${data?.position}`;

  const [getResumePDF, { isLoadingPDF }] = useGetResumePDFMutation();

  const notify = useCallback(() => {
    toast.success('Скопировано!', {
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  function printCall() {
    if (!isDetailPage) {
      navigate({
        pathname: `/${isPrivateResume ? 'resume-one' : 'resumes'}/${data.id}`,
        hash: '#print',
      });
      return;
    }
    window.print();
  }

  function redirectCall() {
    navigate({
      pathname: `/resume-one/${data.id}`,
    });
  }

  useEffect(() => {
    if (hash.trim() === '#print') {
      setTimeout(() => printCall(), 600);
      return;
    }
  }, []);

  async function downloadCall() {
    if (!user) {
      setAuthType('login');
      return;
    }
    await getResumePDF(data.id);
  }

  function copyUrl() {
    const el = document.createElement('input');
    el.value = currentUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    notify();
  }

  return (
    <div className='card__moreInfo-footer'>
      <h3>
        {isVacancy
          ? 'Расскажите друзьям'
          : `Распечатать/Скачать/${
              isPrivateResume && !isDetailPage
                ? 'Перейти'
                : 'Скопировать ссылку'
            }`}{' '}
      </h3>
      <div className='card__moreInfo-footer-socs'>
        {isVacancy && (
          <>
            <TelegramShareButton
              url={currentUrl}
              quote={quote}
              hashtag='#hrgroup'>
              <TelegramIcon size={28} />
            </TelegramShareButton>
            <WhatsappShareButton
              url={currentUrl}
              quote={quote}
              hashtag='#hrgroup'>
              <WhatsappIcon size={28} />
            </WhatsappShareButton>
            <FacebookShareButton
              url={currentUrl}
              quote={quote}
              hashtag='#hrgroup'>
              <FacebookIcon size={28} />
            </FacebookShareButton>
            <VKShareButton url={currentUrl} quote={quote} hashtag='#hrgroup'>
              <VKIcon size={28} />
            </VKShareButton>
            <TwitterShareButton
              url={currentUrl}
              quote={quote}
              hashtag='#hrgroup'>
              <TwitterIcon size={28} />
            </TwitterShareButton>
          </>
        )}
        {!isVacancy && (
          <>
            <button onClick={printCall} title='Распечатать'>
              <HandySvg src={print} width={28} height={28} />
            </button>
            <button
              disabled={isLoadingPDF}
              onClick={downloadCall}
              title='Скачать'>
              <VscFilePdf size={26} color='#5B5B5B' />
            </button>
          </>
        )}
        {isPrivateResume && !isDetailPage ? (
          <button onClick={redirectCall} title='Перейти'>
            <ImNewTab size={26} color='#5B5B5B' />
          </button>
        ) : (
          <button onClick={copyUrl} title='Скопировать ссылку'>
            <VscCopy size={26} color='#5B5B5B' />
          </button>
        )}
      </div>
    </div>
  );
}

const CardSocsInfo = ({ data }) => {
  if (!data?.social_media_type) return null;

  const whatsapp =
    data.social_media_type === 'whatsApp' ? data.social_media.trim() : null;
  const telegram =
    data.social_media_type === 'telegram' ? data.social_media.trim() : null;

  return (
    <>
      {whatsapp && (
        <li>
          <BsWhatsapp size={20} color={'#25d366'} />{' '}
          <a
            target='_blank'
            href={`https://wa.me/${whatsapp.replaceAll(' ', '')}`}
            rel='noreferrer'>
            {whatsapp}
          </a>
        </li>
      )}
      {telegram && (
        <li>
          <BsTelegram size={20} color={'#0088cc '} />{' '}
          <a
            target='_blank'
            href={`https://telegram.me/${telegram.replaceAll(' ', '')}`}
            rel='noreferrer'>
            {telegram}
          </a>
        </li>
      )}
    </>
  );
};

const CardContactsInfo = ({ data, resumeId, isPrivateResume }) => {
  const {
    phones = [],
    instagram = '',
    social_type = '',
    social_text = '',
    email = '',
    name = '',
    last_name = '',
    first_name = '',
  } = data;

  const whatsapp =
    social_type.trim() === 'whatsApp' ? social_text.trim() : null;
  const telegram =
    social_type.trim() === 'telegram' ? social_text.trim() : null;

  return (
    <ul
      className={`card__moreInfo-contact ${
        isPrivateResume ? 'card__moreInfo-contact--gold' : ''
      }`}
      data-aos='flip-up'>
      {!isPrivateResume && (name || last_name || first_name) && (
        <li>
          <AiOutlineUser size={16} />{' '}
          <p>
            <a target='_blank' href={`/resumes/${resumeId}`} rel='noreferrer'>
              {last_name} {name} {first_name}
            </a>
          </p>
        </li>
      )}
      {phones.length > 0 && (
        <li>
          <BsTelephone size={16} />{' '}
          <p>
            {phones.map(({ phone }) => (
              <a
                key={phone}
                style={{ display: 'block' }}
                href={`tel:${phone.replaceAll(' ', '')}`}>
                {phone}
              </a>
            ))}
          </p>
        </li>
      )}
      {whatsapp && (
        <li>
          <BsWhatsapp size={16} />{' '}
          <a
            target='_blank'
            href={`https://wa.me/${whatsapp.replaceAll(' ', '')}`}
            rel='noreferrer'>
            {whatsapp}
          </a>
        </li>
      )}
      {telegram && (
        <li>
          <BsTelegram size={16} />{' '}
          <a
            target='_blank'
            href={`https://telegram.me/${telegram.replaceAll(' ', '')}`}
            rel='noreferrer'>
            {telegram}
          </a>
        </li>
      )}
      {instagram.length > 0 && (
        <li>
          <BsInstagram size={16} />{' '}
          <a
            target='_blank'
            href={`http://www.instagram.com/${instagram.replaceAll(' ', '')}`}
            rel='noreferrer'>
            {instagram}
          </a>
        </li>
      )}
      {email.length > 0 && (
        <li>
          <BsMailbox size={16} />{' '}
          <a href={`mailto:${email.replaceAll(' ', '')}`}>{email}</a>
        </li>
      )}
    </ul>
  );
};

function CardInner({
  data,
  isDetailPage = false,
  isPrivateResume = false,
  isOpen = false,
}) {
  const navigate = useNavigate();
  const { setSendPopup } = useContext(ContentContext);
  const { setAuthType } = useContext(AuthContext);
  const { user, user_status } = useSelector((state) => state.userState);
  const {
    id,
    profile,
    group,
    up_time,
    image,
    position,
    appeal,
    salary,
    negotiable,
    city,
    city_for_work,
    vip_status,
    no_experience,
    experiences,
    status_active_search,
    status_variant_view,
    is_anonymous,
    name = '',
    last_name = '',
    first_name = '',
  } = data;

  //Constants
  const workState = cleanArray(experiences)?.sort((a, b) => {
    let keyA = new Date(a.work_date_from),
      keyB = new Date(b.work_date_from);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  const [color, setColor] = useState(
    !!user?.favorites
      .map((el) => el.resume || el.vacancies)
      .find((el) => el.id === id) ?? false
  );

  function cleanArray(arr) {
    if (arr !== undefined && arr?.length > 0) {
      return arr.map((obj) => {
        let clean = Object.entries(obj)
          .filter((x) => x[1] !== null && x[1]?.length)
          // eslint-disable-next-line no-sequences
          .reduce((acc, [k, v]) => ((acc[k] = v), acc), {});
        return Object.keys(clean).length > 0 ? clean : undefined;
      });
    }
    return arr;
  }

  const [getMe] = useLazyGetMeQuery();

  const [addFavoriteVacancy, { isSuccess, isError, isLoading }] =
    useAddFavoriteVacancyMutation();

  const [
    getContactsPbp,
    {
      isSuccess: isContactSuccess,
      isError: isContactError,
      isLoading: isContactFetching,
      data: contactData,
      error,
    },
  ] = useGetContactsPbpMutation();

  useEffect(() => {
    if (isPrivateResume) return;
    if (isContactSuccess) {
      user?.my_tariffs?.contact_amount > 0 &&
        toast.info(
          `Осталось просмотров: ${+user?.my_tariffs?.contact_amount - 1} шт`
        );
      getMe(null);
    }
    if (isContactError) {
      toast.info(error?.data?.error);
      if (user.profile?.is_active && user.profile?.is_moderation) {
        navigate('/company/order-service');
      }
    }
  }, [isContactSuccess, isContactError, isContactFetching]);

  useEffect(() => {
    let fetchRes = 'status';
    if (isLoading) {
      !color &&
        toast('Добавление...', {
          toastId: fetchRes,
          type: 'loading',
          isLoading,
        });
      color &&
        toast('Удаление...', {
          toastId: fetchRes,
          type: 'loading',
          isLoading,
        });
    }
    if (isSuccess) {
      setColor((prev) => !prev);
      !color &&
        toast.update(fetchRes, {
          render: 'Добавлено в избранное',
          type: 'success',
          autoClose: 2000,
          isLoading,
        });
      color &&
        toast.update(fetchRes, {
          render: 'Удалено из избранных',
          type: 'success',
          autoClose: 2000,
          isLoading,
        });
      getMe(null);
    }
    if (isError) {
      toast.update(fetchRes, {
        render: 'Что-то пошло не так',
        type: 'error',
        autoClose: 2000,
        isLoading,
      });
    }
  }, [isSuccess, isError, isLoading]);

  useEffect(() => {
    async function callback() {
      if (isPrivateResume) {
        await getContactsPbp(id);
      }
    }
    callback();
  }, []);

  const setPopupHandler = async () => {
    if (user_status === 'applicant') {
      let data = {
        employer: profile?.company,
        postPos: position,
        salary,
        id,
      };
      if (user !== null) setSendPopup({ data, status: true });
    }
    if (user_status === 'entity') {
      await getContactsPbp(id);
    }
    if (user === null) {
      setAuthType('login');
    }
  };

  const addFavHandler = async () => {
    let data = {
      employer: profile?.company,
      postPos: position,
      salary,
    };
    if (user !== null) {
      let type = profile ? 'entity' : 'applicant';
      let isType = profile ? 'vacancy' : 'resume';
      let body = {
        isType,
        id,
        type,
      };
      await addFavoriteVacancy(body);
    } else {
      setSendPopup({ data });
      setAuthType('login');
    }
  };

  const [show, setShow] = useState(isDetailPage || isOpen);

  return (
    <div
      className={`card ${!profile ? 'resume' : ''} ${
        isDetailPage ? 'card__page' : ''
      }`}
      style={{ paddingLeft: `${vip_status ? '32px' : '20px'}` }}>
      {vip_status ? (
        <div className='card-vip'>
          <p>
            <span>
              <AiFillStar />
            </span>
          </p>
          {[...Array(!show ? 3 : 12).fill()].map((_, idx) => (
            <p key={idx}>
              <span
                data-aos='fade-up'
                data-aos-once='true'
                data-aos-anchor-placement='bottom-bottom'>
                <AiFillStar />
                <span>ТОП</span>
              </span>
            </p>
          ))}
        </div>
      ) : null}
      <div className='card__main'>
        <div className='card__main-info'>
          <div className='card__main-info-breadcumbs'>
            {new Date(up_time).toLocaleDateString()} /{' '}
            {timeAgo(new Date(up_time))} / {city && city}
            {isEmpty(city_for_work) && city_for_work}
          </div>
          <div className='card__main-info-text'>
            <h3
              onClick={() => {
                if (!isDetailPage) {
                  setShow(!show);
                }
              }}>
              {position.charAt(0).toUpperCase() + position.slice(1)}
            </h3>
            <div className='card__main-info-types'>
              {isEmpty(city) ||
                (isEmpty(city_for_work) && (
                  <div className='card__main-info-types-city'>
                    {city && city}
                    {city_for_work && city_for_work}
                  </div>
                ))}
              {group && (
                <div className='card__main-info-types-forself'>{group}</div>
              )}
              {status_active_search && (
                <div className='card__main-info-types-forself'>
                  Активно ищу работу
                </div>
              )}
              {status_variant_view && (
                <div className='card__main-info-types-forself'>
                  Рассматриваю варианты
                </div>
              )}
              {}
            </div>
          </div>
          <div className='card__main-salary'>
            {negotiable || salary == null ? 'Договорная' : `${salary} KGS`}
            {/* {salary_currency === null ? 'KGS' : salary_currency} */}
          </div>
          {isPrivateResume && (
            <h2 className='card__main-fullname'>
              {last_name} {name} {first_name}
            </h2>
          )}
        </div>
        <div className='card__main-logo'>
          <Fancybox>
            {profile && (
              <a
                data-fancybox={id}
                data-caption={!is_anonymous && profile?.company}
                href={`${
                  !!profile?.image && !is_anonymous
                    ? profile?.image
                    : companyLogoPsh
                }`}
                data-sizes={`(max-width: 800px)`}>
                <img
                  src={
                    !!profile?.image && !is_anonymous
                      ? profile?.image
                      : companyLogoPsh
                  }
                  alt='logo'
                />
              </a>
            )}
            {!profile && (
              <a
                data-fancybox={id}
                href={`${image || userLogoPsh}`}
                data-sizes={`(max-width: 800px)`}>
                <img src={image || userLogoPsh} alt='logo' />
              </a>
            )}
          </Fancybox>
        </div>
      </div>
      <div className='card__more'>
        {isPrivateResume && contactData && (
          <CardContactsInfo
            data={contactData}
            resumeId={id}
            isPrivateResume={isPrivateResume}
          />
        )}
        <div
          className='card__more-name'
          style={{ marginTop: !isPrivateResume ? '5px' : '20px' }}>
          {profile && !is_anonymous && (
            <>
              <span>Компания:</span> {'  '}{' '}
              <Link to={`/companies/${profile?.id}`}>{profile?.company}</Link>
            </>
          )}
          {!profile && (
            <Link to={`/resumes/${id}`}>
              {!no_experience && workState && isEmpty(workState[0]) ? (
                <p>
                  {workState.filter((el) => el?.place_of_work).length}{' '}
                  {declension(
                    ['место', 'места', 'мест'],
                    workState.filter((el) => el?.place_of_work).length
                  )}{' '}
                  работы
                  {workState[0]?.work_date_from &&
                    `, начиная с ${workState[0]?.work_date_from.slice(
                      0,
                      -6
                    )} года`}
                </p>
              ) : (
                'Без опыта работы'
              )}
            </Link>
          )}
        </div>
        <Link to={`/vacancies/${id}`}>
          <span className={`card__more-description`}>
            {show && (
              <ReactMarkdown children={appeal} rehypePlugins={[rehypeRaw]} />
            )}
          </span>
        </Link>
      </div>
      {show && <CardMore data={data} />}
      {!isPrivateResume && (
        <div className='card__handlers'>
          {!profile && user_status === 'entity' && !contactData && (
            <button disabled={isContactFetching} onClick={setPopupHandler}>
              Открыть контакты
            </button>
          )}
          {!profile && user_status === 'entity' && !!contactData && (
            <CardContactsInfo data={contactData} resumeId={id} />
          )}
          {profile && user_status === 'applicant' && (
            <button onClick={setPopupHandler}>Отправить резюме</button>
          )}
          {user_status === null && (
            <>
              <button onClick={setPopupHandler}>
                {profile ? 'Отправить резюме' : 'Открыть контакты'}
              </button>
              <div
                className='card__handlers-fav unSelect'
                onClick={addFavHandler}>
                <AiOutlineStar
                  width={23}
                  height={23}
                  fill={color ? '#ffb310' : 'rgb(117, 112, 112)'}
                />{' '}
                Добавить в избранное
              </div>
            </>
          )}
          {profile && user_status === 'applicant' && (
            <div
              className='card__handlers-fav unSelect'
              onClick={addFavHandler}>
              <AiOutlineStar
                width={23}
                height={23}
                fill={color ? '#ffb310' : 'rgb(117, 112, 112)'}
              />{' '}
              Добавить в избранное
            </div>
          )}
          {!profile && user_status === 'entity' && (
            <div
              className='card__handlers-fav unSelect'
              onClick={addFavHandler}>
              <AiOutlineStar
                width={23}
                height={23}
                fill={color ? '#ffb310' : 'rgb(117, 112, 112)'}
              />{' '}
              Добавить в избранное
            </div>
          )}
        </div>
      )}
      {show && (
        <CardMoreFooter
          data={data}
          isDetailPage={isDetailPage}
          isPrivateResume={isPrivateResume}
        />
      )}
    </div>
  );
}

const Card = memo(CardInner);

export default Card;
