import { useSelector } from 'react-redux';
import CardsList from '../../components/CardsList/CardsList';
import SeoHelmet from '../../hoc/SeoHelmet';

function Favourites() {
  const { user_favorites, user } = useSelector((state) => state.userState);

  let data = !!user_favorites.length
    ? user_favorites
    : user?.favorites.map((el) => el.resume);

  if (data?.length) {
    return <CardsList cards={data} />;
  } else {
    return (
      <>
        <SeoHelmet
          title='Избранное'
          innerTitle='Избранное'
          description='Ваши избранное на сайте HRGroup.kg'
          location={window.location.href}
        />
        <div>
          <h2 className='favourites__title'>Избранное</h2>
          <p className='favourites__text'>
            У вас пока нет сохраненных вакансий
          </p>
        </div>
      </>
    );
  }
}

export default Favourites;
