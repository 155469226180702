// const languages = [
//   { value: 'zz', label: 'Кыргызский' },
//   { value: 'en', label: 'Английский' },
//   { value: 'ar', label: 'Арабский' },
//   { value: 'zh', label: 'Китайский' },
//   { value: 'es', label: 'Испанский' },
//   { value: 'it', label: 'Итальянский' },
//   { value: 'la', label: 'Латинский' },
//   { value: 'zh', label: 'Китайский' },
//   { value: 'kk', label: 'Казахский' },
//   { value: 'ko', label: 'Корейский' },
//   { value: 'de', label: 'Немецкий' },
//   { value: 'pt', label: 'Португальский' },
//   { value: 'ru', label: 'Русский' },
//   { value: 'uz', label: 'Узбекский' },
//   { value: 'ukr', label: 'Украинский' },
//   { value: 'tr', label: 'Турецкий' },
//   { value: 'fr', label: 'Французский' },
//   { value: 'hi', label: 'Хинди' },
//   { value: 'ja', label: 'Японский' },
// ];

const languages = [
  { value: 'Кыргызский', label: 'Кыргызский' },
  { value: 'Английский', label: 'Английский' },
  { value: 'Арабский', label: 'Арабский' },
  { value: 'Китайский', label: 'Китайский' },
  { value: 'Испанский', label: 'Испанский' },
  { value: 'Итальянский', label: 'Итальянский' },
  { value: 'Латинский', label: 'Латинский' },
  { value: 'Китайский', label: 'Китайский' },
  { value: 'Казахский', label: 'Казахский' },
  { value: 'Корейский', label: 'Корейский' },
  { value: 'Немецкий', label: 'Немецкий' },
  { value: 'Португальский', label: 'Португальский' },
  { value: 'Русский', label: 'Русский' },
  { value: 'Узбекский', label: 'Узбекский' },
  { value: 'Украинский', label: 'Украинский' },
  { value: 'Турецкий', label: 'Турецкий' },
  { value: 'Французский', label: 'Французский' },
  { value: 'Хинди', label: 'Хинди' },
  { value: 'Японский', label: 'Японский' },
];

export default languages;
