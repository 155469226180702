import Card from '../Card/Card';
import Pagination from '../Pagination/Pagination';

function CardsList({ cards = [], setPage, page, paginationCount, pageSize }) {
  return (
    <div className='cards'>
      {cards.map((el) => (
        <Card key={el.slug || el.id} data={{ ...el }} />
      ))}
      {paginationCount !== undefined && paginationCount !== null ? (
        <Pagination
          setPage={setPage}
          page={page}
          paginationCount={paginationCount}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
}

export default CardsList;
