import { useEffect, useState } from 'react';
import SeoHelmet from '../../hoc/SeoHelmet';
import { useSearchParams } from 'react-router-dom';
import Aside from '../../components/Aside/Aside';
import CardsList from '../../components/CardsList/CardsList';
import CardsSkeletonList from '../../components/CardsList/CardsSkeletonList';
import { goToTop } from '../../hooks/goToTopScroll';
import { useGetVacanciesQuery } from '../../redux';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import VacancesList from '../Home/VacancesList/VacancesList';

function AllVacancies() {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get('page') || 2);
  const { width } = useWindowDimensions();
  const pageSize = width >= 580 ? 40 : 20;

  const { data = [], isFetching } = useGetVacanciesQuery(
    { page, pageSize },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    isFetching && goToTop();
  }, [isFetching]);

  return (
    <>
      <SeoHelmet
        title='Вакансии'
        innerTitle='Вакансии'
        description='На странице базы вакансий hrgroup.kg вы найдете множество интересных и актуальных вакансий от различных компаний. Используйте нашу удобную систему поиска, чтобы быстро и легко находить подходящие варианты для вашей карьеры.'
        location={window.location.href}
      />
      <VacancesList />
      <div className='contentContent__wrapper'>
        {width >= 860 && <Aside />}
        <div className='allResVac'>
          <h3 className='allResVac__title'>Все вакансии</h3>
          {isFetching && <CardsSkeletonList isVacancy={true} />}
          {!isFetching && (
            <CardsList
              cards={data?.results}
              setPage={setPage}
              page={page}
              paginationCount={data?.count}
              pageSize={pageSize}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AllVacancies;
