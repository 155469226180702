import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';

export const replenishApi = createApi({
  reducerPath: 'api/replenishApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  endpoints: (build) => ({
    // getAllTariffs: build.query({
    //   query: () => `alltariffs/`,
    // }),
    postPaybox: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `payment/paybox/create/`,
          method: 'POST',
          body: { amount: body },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.pay_url) {
            window.open(data.pay_url, '_blank');
          }
        } catch (error) {}
      },
    }),
  }),
});

export const { usePostPayboxMutation } = replenishApi;
