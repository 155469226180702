import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";
import { getAllCompanies } from "./companiesAction";

export const getAllTarif = createAsyncThunk(
  "adminTarif/getAllTarif",
  async () => {
    try {
      const res = await ApiFetch({ url: "alltariffs/" });
      return res;
    } catch (e) {
      return [];
    }
  }
);

export const postTarifCompanyAdmin = createAsyncThunk(
  "adminTarif/postTarifCompanyAdmin",
  async (props, { dispatch }) => {
    try {
      const post = await ApiFetch({
        url: `myadmin/transaction-minus/`,
        method: "POST",
        body: props.body,
      });
      dispatch(getAllCompanies());
      return post;
    } catch (e) {
      return [];
    }
  }
);
const getTarif = createSlice({
  name: "adminTarif",
  initialState: { tarif: [], status: null },
  extraReducers: {
    [getAllTarif.fulfilled]: (state, actions) => {
      state.tarif = actions.payload;
      state.status = "success";
    },
    [getAllTarif.rejected]: (state) => {
      state.tarif = [];
      state.status = "error";
    },
    [postTarifCompanyAdmin.fulfilled]: (state) => {
      state.status = "success";
    },
  },
});
export default getTarif;
