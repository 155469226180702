import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import { setSearchResult } from '../features/searchParamsSlice';

export const searchApi = createApi({
  reducerPath: 'api/search',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}`,
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getPositions: builder.query({
      query: () => `positions`,
    }),
    searchResult: builder.mutation({
      query: (body) => {
        const { type, queries } = body;
        return {
          url: `/${type}/search/?${queries}`,
          method: 'GET',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setSearchResult(data));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetPositionsQuery, useSearchResultMutation } = searchApi;
