import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = () => {
  let location = useLocation();

  useEffect(() => {
    const gtag = window.gtag;
    if (typeof gtag === 'function') {
      gtag('config', 'UA-251795502-1', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  }, [location]);

  return;
};

export default GoogleAnalytics;
