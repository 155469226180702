import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../../configs/axiosConfig';
import GetCookie from '../../../hooks/cookies/getCookie';
import SetCookie from '../../../hooks/cookies/setCookie';
import ApiFetch from '../../api/fetchApi';
import { userApi } from '../../api/userApi';

export const getEmployersAdmin = createAsyncThunk(
  'admin_employer/getEmployersAdmin',
  async () => {
    try {
      const response = await ApiFetch({
        url: 'myadmin/employer/',
      });
      return response;
    } catch (e) {
      return [];
    }
  }
);
export const getAllUsers = createAsyncThunk(
  'admin_employer/getAllUsers',
  async (num) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/user/list/search/?${num || 1}`,
      });
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const deleteUser = createAsyncThunk(
  'admin_employer/deleteUser',
  async (num) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/user/list/${num}`,
        method: 'DELETE',
      });
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const authAsUser = createAsyncThunk(
  'admin_employer/authAsUser',
  async (email, { dispatch }) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/admin-login/like-user/`,
        method: 'POST',
        body: { user_email: email },
      });
      await SetCookie('refresh', response.refresh);
      await SetCookie('access', response.access);
      await SetCookie('status', response.status);
      await dispatch(userApi.endpoints.getMe.initiate(response));
      if (response.status === 'applicant') {
        return await window.open('/me/', '_self');
      }
      if (response.status === 'entity') {
        return await window.open('/company/', '_self');
      }
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const editEmployer = createAsyncThunk(
  'admin_employer/editEmployer',
  async (props, { dispatch }) => {
    const formData = new FormData();
    if (props?.body?.image) {
      formData.append('image', props?.body?.image, props?.body?.image?.name);
    }
    formData.append('link', props?.body?.link);
    formData.append(
      'employer_tariff_title',
      props?.body?.employer_tariff_title
    );
    formData.append('is_active', true);
    formData.append('dead_time', props?.body?.dead_time);
    formData.append('tariff_control', props?.body?.tariff_control);
    try {
      const response = await API({
        method: 'PUT',
        url: `myadmin/employer/${props.id}/`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      dispatch(getEmployersAdmin());
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const deleteEmployment = createAsyncThunk(
  'admin_employer/deleteEmployment',
  async (id, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/employer/${id.id}/`,
        method: 'PUT',
        body: { is_active: false, ...id },
      });
      dispatch(getEmployersAdmin());
      return res;
    } catch (e) {
      return [];
    }
  }
);

export const addUserComment = createAsyncThunk(
  'admin_employer/addUserComment',
  async ({ userId, text }, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/user/comment/crud/`,
        method: 'POST',
        body: { user: userId, text },
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);

export const editUserComment = createAsyncThunk(
  'admin_employer/addUserComment',
  async ({ userId, text, id }, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/user/comment/crud/${id}`,
        method: 'PUT',
        body: { user: userId, text },
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);

export const deleteUserComment = createAsyncThunk(
  'admin_employer/addUserComment',
  async (id, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/user/comment/crud/${id}`,
        method: 'DELETE',
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);

const EmployerSlice = createSlice({
  name: 'admin_employer',
  initialState: { employers: [], status: false, allusers: [] },
  extraReducers: {
    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.allusers = payload;
    },
    [getEmployersAdmin.fulfilled]: (state, { payload }) => {
      state.employers = payload;
    },
    [deleteEmployment.fulfilled]: (state) => {
      state.status = !state.status;
    },
    [addUserComment.fulfilled]: (state) => {
      state.status = !state.status;
    },
  },
});
export default EmployerSlice;
