import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { goToTop } from '../../hooks/goToTopScroll';

function Pagination({ setPage, page, paginationCount, pageSize = 40 }) {
  let pagesCount = Math.ceil(+paginationCount / pageSize);

  let [searchParamsPage, setSearchParamsPage] = useSearchParams();
  const [inputPage, setInputPage] = useState(searchParamsPage.get('page') || 1);

  const submitPage = (e) => {
    e.preventDefault();
    setSearchParamsPage((searchParams) => {
      searchParams.set('page', inputPage);
      return searchParams;
    });
    setPage(inputPage);
  };

  useEffect(() => {
    if (searchParamsPage.get('page') === page) return;
    setSearchParamsPage((searchParams) => {
      searchParams.set('page', page);
      return searchParams;
    });
    setInputPage(page);
  }, [page]);

  useEffect(() => goToTop(), [page]);

  return (
    <div className='pagination'>
      <form onSubmit={submitPage}>
        <input
          pattern='\d*'
          className='pagination__pageValue'
          type='text'
          onChange={(e) => setInputPage(e.target.value)}
          name='page_value'
          value={inputPage}
        />
      </form>
      <p className='pagination__pages'>из {pagesCount || 1} стр</p>
      <button
        disabled={page <= 1 && true}
        style={page <= 1 ? { color: 'rgba(255, 255, 255, 0.3)' } : {}}
        className='pagination__nav-left'
        onClick={() => setPage(page - 1)}>
        <RiArrowLeftSLine size={20} />
      </button>
      <button
        disabled={+page >= +pagesCount && true}
        style={
          +page >= +pagesCount ? { color: 'rgba(255, 255, 255, 0.3)' } : {}
        }
        className='pagination__nav-right'
        onClick={() => setPage(+page + +1)}>
        <RiArrowRightSLine size={20} />
      </button>
    </div>
  );
}

export default Pagination;
