import axios from 'axios';
import GetCookie from '../hooks/cookies/getCookie';
export const baseDOMAIN = 'hrgroup.kg';
export const baseURL = `https://${baseDOMAIN}/api/v1/`;
export const baseWS = `wss://${baseDOMAIN}/ws/`;

const API = axios.create({
  baseURL,
  headers: {
    Authorization: 'Bearer ' + GetCookie('access'),
  },
});
export default API;
