export const days = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
];
export const months = [
  { value: '1', label: 'Январь' },
  { value: '2', label: 'Февраль' },
  { value: '3', label: 'Март' },
  { value: '4', label: 'Апрель' },
  { value: '5', label: 'Май' },
  { value: '6', label: 'Июнь' },
  { value: '7', label: 'Июль' },
  { value: '8', label: 'Август' },
  { value: '9', label: 'Сентябрь' },
  { value: '10', label: 'Октябрь' },
  { value: '11', label: 'Ноябрь' },
  { value: '12', label: 'Декабрь' },
];
export const typeOfEmployment = [
  { value: 'Полная занятость', label: 'Полная занятость' },
  { value: 'Частичная занятость', label: 'Частичная занятость' },
  { value: 'Вахта', label: 'Вахта' },
  { value: 'Удалённая работа', label: 'Удалённая работа' },
  { value: 'Стажировка', label: 'Стажировка' },
];
export const aggeOptions = () => {
  const numRender = (num) => (num < 10 ? `0${num}` : num);
  let currentDate = new Date();
  let fullYear = currentDate.getFullYear();
  let month = numRender(currentDate.getMonth() + 1);
  let day = numRender(currentDate.getDate());
  let dayAndMonth = `${month}-${day}`;

  return [
    {
      value: `${fullYear - 22}-${dayAndMonth}$${fullYear - 18}-${dayAndMonth}`,
      label: 'от 18 - 22 лет',
    },
    {
      value: `${fullYear - 30}-${dayAndMonth}$${fullYear - 22}-${dayAndMonth}`,
      label: 'от 22 - 30 лет',
    },
    {
      value: `${fullYear - 50}-${dayAndMonth}$${fullYear - 30}-${dayAndMonth}`,
      label: 'от 30 - 50 лет',
    },
    {
      value: `${fullYear - 50}-${dayAndMonth}`,
      label: '50 и выше',
    },
  ];
};
export const driverLicenses = [
  { value: 'A', label: 'A' },
  { value: 'A1', label: 'A1' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'C1', label: 'C1' },
  { value: 'D', label: 'D' },
  { value: 'D1', label: 'D1' },
  { value: 'BE', label: 'BE' },
  { value: 'CE', label: 'CE' },
  { value: 'C1E', label: 'C1E' },
  { value: 'DE', label: 'DE' },
  { value: 'D1E', label: 'D1E' },
];
export const lngLevel = [
  { value: 'начальный', label: 'начальный' },
  { value: 'ниже среднего', label: 'ниже среднего' },
  { value: 'средний', label: 'средний' },
  { value: 'выше среднего', label: 'выше среднего' },
  { value: 'продвинутый', label: 'продвинутый' },
  { value: 'профессиональный', label: 'профессиональный' },
];
