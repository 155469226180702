import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetMeQuery } from '../redux/api/userApi';
import GetCookie from '../hooks/cookies/getCookie';
import { logout } from '../redux';
import { useRefreshTokenMutation } from '../redux/api/authApi';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

export const AuthContext = createContext();
export const AuthContextProvider = (props) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  let refresh = GetCookie('refresh');
  let access = GetCookie('access');
  let isLogged = !!access && !!refresh;

  const [authType, setAuthType] = useState('');

  const [refreshToken] = useRefreshTokenMutation();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  const { refetch } = useGetMeQuery();

  const endSession = () => {
    dispatch(logout());
    navigate('/');
    setAuthType('login');
    toast.info('Ваша сессия истекла, повторите попытку авторизации', {
      position: 'top-center',
      autoClose: 5000,
    });
  };

  useEffect(() => {
    if (isLogged) {
      let decodedAccess = parseJwt(access);
      let decodedRefresh = parseJwt(refresh);
      let currentTime = Date.now();

      if (decodedAccess?.exp * 1000 < currentTime) {
        refreshToken({ refresh });
      }
      if (decodedRefresh?.exp * 1000 < currentTime) {
        endSession();
      }
      if (GetCookie('status') == 'entity' && width <= 930) {
        setTimeout(() => {
          dispatch(logout());
        }, 2000);
      }
      refetch(null);
    }
    // if (logged == 'true' && (!access || !refresh)) {
    //   navigate('/');
    //   dispatch(logout());
    //   setAuthType('login');
    //   toast.info('Повторите попытку авторизации', {
    //     position: 'top-center',
    //     autoClose: 5000,
    //   });
    // }
  }, [location]);

  const value = {
    authType,
    setAuthType,
  };

  return (
    <>
      <AuthContext.Provider value={value}>
        {props.children}
      </AuthContext.Provider>
      <ToastContainer
        position={width > 491 ? 'bottom-center' : 'top-center'}
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='light'
      />
    </>
  );
};
