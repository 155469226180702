import { useState } from 'react';
import SeoHelmet from '../../hoc/SeoHelmet';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Aside from '../../components/Aside/Aside';
import CardsList from '../../components/CardsList/CardsList';
import CardsSkeletonList from '../../components/CardsList/CardsSkeletonList';
import { goToTop } from '../../hooks/goToTopScroll';
import { useGetResumesQuery } from '../../redux';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import ResumesList from '../Resumes/ResumesList/ResumesList';

function AllResumes() {
  const [searchParams] = useSearchParams();
  // const { pathname } = useLocation();
  const [page, setPage] = useState(searchParams.get('page') || 1);
  const { width } = useWindowDimensions();

  const { data = [], isFetching } = useGetResumesQuery(page, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    isFetching && goToTop();
  }, [isFetching]);

  return (
    <>
      <SeoHelmet
        title='Резюме'
        innerTitle='Резюме'
        description='На странице базы резюме hrgroup.kg вы найдете широкий выбор квалифицированных кандидатов, подходящих для разных должностей в вашей компании. Используйте нашу удобную систему поиска, чтобы быстро и легко находить подходящие резюме, которые соответствуют вашим требованиям.'
        location={window.location.href}
      />
      <ResumesList />
      <div className='contentContent__wrapper'>
        {width >= 860 && <Aside />}
        <div className='allResVac'>
          <h3 className='allResVac__title'>Все резюме</h3>
          {isFetching && <CardsSkeletonList />}
          {!isFetching && (
            <CardsList
              cards={data?.results}
              setPage={setPage}
              page={page}
              paginationCount={data?.count}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AllResumes;
