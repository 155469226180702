import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";

export const getPhoneAdmin = createAsyncThunk(
  "phone_admin/getPhoneAdmin",
  async () => {
    try {
      const res = await ApiFetch({
        url: "myadmin/call-request/",
      });
      return res;
    } catch (e) {
      return e;
    }
  }
);
export const editPhoneAdmin = createAsyncThunk(
  "phone_admin/getPhoneAdmin",
  async (props, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: "myadmin/call-request-update/",
        method: "POST",
        body: props,
      });
      dispatch(getPhoneAdmin());
      return res;
    } catch (e) {
      return e;
    }
  }
);

const phoneAdminSlice = createSlice({
  name: "phone_admin",
  initialState: { data: null, num: null },
  extraReducers: {
    [getPhoneAdmin.fulfilled]: (state, { payload }) => {
      state.data = payload;
      const num = payload?.filter((i) => i.status === "new");
      state.num = num?.length;
    },
  },
});
export default phoneAdminSlice;
