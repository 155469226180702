import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import API from "../../../configs/axiosConfig";
import ApiFetch from "../../api/fetchApi";

export const getAllBanners = createAsyncThunk(
  "main_banner/getAllBanners",
  async () => {
    try {
      const responce = await ApiFetch({
        url: `myadmin/banner/`,
      });
      return responce;
    } catch (error) {
      return [];
    }
  }
);

export const postPhoto = createAsyncThunk(
  "main_banner/postPhoto",
  async (props, { dispatch }) => {
    console.log(props, "props");
    const formData = new FormData();
    if (props?.body?.image) {
      formData.append("image", props?.body?.image, props?.body?.image?.name);
    }
    // formData.append("banner_link", props?.body?.banner_link);
    formData.append("link", props?.body?.link);
    // formData.append("rubric", props?.body?.rubric);
    for (let k = 0; k < props?.body?.rubric?.length; k++) {
      // formData.append("rubric", JSON.stringify(props?.body?.rubric));
      formData.append("rubric", props?.body?.rubric[k]);
    }

    formData.append("dead_time", props?.body?.dead_time?.slice(0, 10));
    formData.append("tariff_control", props?.body?.tariff_control);
    formData.append("is_active", true);
    formData.append("is_moderation", true);
    try {
      const response = await API({
        method: "PUT",
        url: `myadmin/banner/${props.id}/`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(getAllBanners());
      return response;
    } catch (e) {
      return {};
    }
  }
);
export const deleteBanner = createAsyncThunk(
  "main_banner/deleteBanner",
  async (id, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/banner/${id.id}/`,
        method: "PUT",
        body: {
          is_active: false,
          ...id,
          dead_time: id?.dead_time?.slice(0, 10),
        },
      });
      dispatch(getAllBanners());
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const getRubricList = createAsyncThunk(
  "main_banner/getRubricList",
  async () => {
    try {
      const res = await ApiFetch({
        url: "category/",
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);

const initialState = {
  main_banner: [],
  banner_rubric_list: [],
  status: null,
};

const AllBannersSlice = createSlice({
  name: "main_banner",
  initialState,
  extraReducers: {
    [getRubricList.fulfilled]: (state, actions) => {
      state.banner_rubric_list = actions.payload;
    },
    [getAllBanners.fulfilled]: (state, { payload }) => {
      state.main_banner = payload;
      state.status = "success";
    },
    [getAllBanners.rejected]: (state) => {
      state.status = "error";
    },
  },
});

export default AllBannersSlice;
