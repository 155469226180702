import { createContext, useState } from 'react';
import { useGetChatsListQuery } from '../redux/ws/chatApi';
import GetCookie from '../hooks/cookies/getCookie';

export const ChatContext = createContext();
export const ChatContextProvider = ({ children }) => {
  let refresh = GetCookie('refresh');
  let access = GetCookie('access');
  let isLogged = !!access && !!refresh;

  const [status, setStatus] = useState({
    loading: true,
    connected: false,
    error: null,
  });

  const {
    data: chatsData = [],
    isFetching = true,
    refetch: refetchChatsList,
  } = useGetChatsListQuery(
    {},
    {
      skip: !isLogged,
      pollingInterval: 15000,
      refetchOnMountOrArgChange: true,
    }
  );
  const value = { chatsData, isFetching, refetchChatsList, status, setStatus };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
