import { lazy } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Route, Routes } from 'react-router-dom';
import Layout from '../../layout/Layout';
import SearchLayout from '../../layout/SearchLayout/SearchLayout';
import AllResumes from '../../pages/AllResVac/AllResumes';
import AllVacancies from '../../pages/AllResVac/AllVacancies';
import Favourites from '../../pages/Favourites/Favourites';
import Home from '../../pages/Home/Home';
import ResumesPage from '../../pages/Resumes/ResumesPage';
import SearchByCategory from '../../pages/SearchByCategory/SearchByCategory';
import Search from '../../pages/Search/Search';
import Chat from '../../pages/Chat/Chat';
import NotFoundPage from '../../pages/NotFoundPage';

//Lazy imports
const EmployerDetail = lazy(() =>
  import('../../pages/EmployerDetail/EmployerDetail')
);
const About = lazy(() => import('../../pages/About/About'));
const Agreement = lazy(() => import('../../pages/Agreement/Agreement'));
const ResumeDetail = lazy(() =>
  import('../../pages/ResumeDetail/ResumeDetail')
);
const VacancyDetail = lazy(() =>
  import('../../pages/VacancyDetail/VacancyDetail')
);
const Companies = lazy(() => import('../../pages/Companies/Companies'));
const Tariffs = lazy(() => import('../../pages/Tariffs/Tariffs'));
const ChatLayout = lazy(() => import('../../layout/ChatLayout/ChatLayout'));

const UserRoute = ({ status }) => {
  const { width } = useWindowDimensions();
  return (
    <Layout>
      <Routes>
        <Route exact path='/' element={<SearchLayout />}>
          <Route exact path='/' element={<Home />} />
          <Route exact path='resumes' element={<ResumesPage />} />
          <Route exact path='vacancies-all' element={<AllVacancies />} />
          <Route exact path='resumes-all' element={<AllResumes />} />
          <Route exact path='search/resumes' element={<Search />} />
          <Route exact path='search/vacancies' element={<Search />} />
          <Route
            exact
            path='/categories/:category'
            element={<SearchByCategory filter='vacancies' />}
          />
          <Route
            exact
            path='/resumes/categories/:category'
            element={<SearchByCategory filter='resumes' />}
          />
        </Route>
        <Route exact path='resumes/:resumeID' element={<ResumeDetail />} />
        <Route
          exact
          path='resume-one/:resumeID'
          element={<ResumeDetail isPrivate={true} />}
        />
        <Route exact path='vacancies/:vacancyID' element={<VacancyDetail />} />
        <Route exact path='agreement' element={<Agreement />} />
        <Route exact path='tariffs' element={<Tariffs />} />
        <Route exact path='about' element={<About />} />
        <Route exact path='companies' element={<Companies />} />
        <Route exact path='companies/:id' element={<EmployerDetail />} />
        {status && <Route exact path='/fav' element={<Favourites />} />}
        {status && (
          <Route exact path='/chat' element={<ChatLayout />}>
            {width >= 580 && <Route path=':room' element={<Chat />} />}
          </Route>
        )}
        {status && width < 580 && (
          <Route path='/chat/:room' element={<Chat />} />
        )}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Layout>
  );
};
export default UserRoute;
