import MessagesList from './MessagesList';
import ChatForm from './ChatForm';
import CompanionInfo from './CompanionInfo';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetCookie from '../../hooks/cookies/getCookie';
import { baseWS } from '../../configs/axiosConfig';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  ConnectionChat,
  DeleteMessageAlert,
  ErrorChat,
  LoadingChat,
} from './ChatStatus';
import useChatsData from '../../hooks/useChatsData';

const connectWebSocket = (url, onMessage, setStatus) => {
  const ws = new WebSocket(url);

  ws.onopen = () => {
    console.log('connected');
    setStatus((prevStatus) => ({
      ...prevStatus,
      loading: false,
      connected: true,
    }));
    ws.send(JSON.stringify({ command: 'all_messages' }));
  };

  ws.onmessage = (event) => {
    const wsMessage = JSON.parse(event.data);
    onMessage(wsMessage);
  };

  ws.onerror = (error) => {
    console.error(error);
    setStatus((prevStatus) => ({ ...prevStatus, error }));
  };

  ws.onclose = () => {
    console.log('disconnected');
    setStatus((prevStatus) => ({ ...prevStatus, connected: false }));
    // setSocket(null);
  };

  return ws;
};

function Chat() {
  const resetStyles = `body{overflow:hidden}; jdiv, #jivo-iframe-container, #jvlabelWrap {display: none!important; visibility: hidden!important; opacity: 0!important; transform: scale(0)!important}`;
  const { width } = useWindowDimensions();
  const { refetchChatsList, status, setStatus } = useChatsData();

  const [deleteAlert, setDeleteAlert] = useState(null);
  const { loading: isLoading, connected: isConnected, error: isError } = status;
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const { room: chatName } = useParams();

  const webSocketUrl = `${baseWS}chat/${chatName}/${GetCookie('access')}/`;

  const errorRef = useRef();
  const connectRef = useRef();

  const handleClose = (statusName) => {
    let valOfStatus = status[statusName];
    setStatus((prevStatus) => ({ ...status, [statusName]: !valOfStatus }));
  };

  const handleSendMessage = (message) => {
    if (message.trim().length)
      socket.send(
        JSON.stringify({ message: message.trim(), command: 'new_message' })
      );
  };

  const onDeleteMessage = () => {
    socket.send(JSON.stringify({ command: 'delete', message_id: deleteAlert }));
  };

  const handleReconnect = () => {
    const ws = connectWebSocket(webSocketUrl, onMessage, setStatus);
    setSocket(ws);
  };

  const deleteMessage = (messageId) => {
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message.id !== messageId)
    );
  };

  const onMessage = (wsMessage) => {
    switch (wsMessage.command) {
      case 'new_message':
        setMessages((prevMessages) => [...prevMessages, wsMessage.message]);
        break;
      case 'all_messages':
        setMessages(wsMessage.messages);
        break;
      case 'delete':
        deleteMessage(wsMessage.message_id);
        break;
      default:
        break;
    }
    if (!!wsMessage.error || !!wsMessage.error_code) {
      setStatus((prevStatus) => ({ ...prevStatus, error: wsMessage }));
    }
    refetchChatsList();
  };

  const hideJivoChat = () => {
    const targets = document.querySelectorAll('jdiv');
    for (let i = 0; i < targets.length; i++) {
      targets[i].style.display = 'none';
    }
  };

  useEffect(() => {
    const ws = connectWebSocket(webSocketUrl, onMessage, setStatus);
    setSocket(ws);
    return () => {
      ws.close();
    };
  }, [chatName]);

  if (isLoading) {
    return <LoadingChat />;
  }

  hideJivoChat();

  return (
    <>
      <style>{resetStyles}</style>
      <div
        className={`messages-content ${
          width < 580 ? 'messages-content--mobile' : ''
        }`}>
        {!isConnected ? (
          <ConnectionChat
            ref={connectRef}
            handleReconnect={handleReconnect}
            handleClose={handleClose}
          />
        ) : !!isError ? (
          <ErrorChat
            ref={errorRef}
            isError={isError}
            handleClose={handleClose}
          />
        ) : !!deleteAlert ? (
          <DeleteMessageAlert
            setDeleteAlert={setDeleteAlert}
            onDeleteMessage={onDeleteMessage}
          />
        ) : null}
        <CompanionInfo />
        <MessagesList
          messages={messages}
          onDeleteMessage={onDeleteMessage}
          setDeleteAlert={setDeleteAlert}
        />
        <ChatForm handleSendMessage={handleSendMessage} />
      </div>
    </>
  );
}

export default Chat;
