import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { MdOutlineClose } from 'react-icons/md';
import { FaRegSmile } from 'react-icons/fa';
import { ContentContext } from '../../utils/ContentContext';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSetPostingsMutation } from '../../redux/api/vacancies';

function CardPopup() {
  const [emoji, setEmoji] = useState(true);
  const { user } = useSelector((s) => s.userState);
  const [resumesOptions, setResumesOptions] = useState([]);
  const [resumeCur, setResumeCur] = useState();
  const { sendPopup, setSendPopup } = useContext(ContentContext);
  const { data, status } = sendPopup;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const [
    setPostings,
    {
      isLoading,
      isSuccess,
      isError,
      error: setPostingError,
      data: setPostingData,
    },
  ] = useSetPostingsMutation();

  // const [openedResumes, setOpenedResumes] = useState([]);

  useEffect(() => {
    if (user?.resumes.length) {
      let options = user.resumes
        .filter((el) => el.is_moderation && el.is_active)
        .reduce(
          (acc, rec) => [...acc, { value: rec?.id, label: rec?.position }],
          []
        );
      setResumesOptions(options);
    }
  }, []);

  useEffect(() => {
    let fetchRes = 'addingStatus';
    if (isLoading) {
      toast('Добавление...', {
        toastId: fetchRes,
        type: 'loading',
        isLoading,
      });
    }
    if (isSuccess) {
      toast.update(fetchRes, {
        render: setPostingData?.detail,
        type: 'success',
        autoClose: 2000,
        isLoading,
      });
      setSendPopup({ data: {}, status: false });
    }
    if (isError) {
      toast.update(fetchRes, {
        render: setPostingError?.data?.error,
        type: 'error',
        autoClose: 2000,
        isLoading,
      });
    }
  }, [isLoading, isSuccess, isError]);

  const onSubmit = async (data) => {
    if (resumeCur?.value) {
      let body = {
        vacancy: sendPopup?.data?.id,
        resume: resumeCur.value,
        text: data.message,
      };
      await setPostings(body);
    } else {
      toast.error('Выберите резюме!');
    }
  };

  return (
    <>
      <div className='popup'>
        <div
          className='popup__bg'
          onClick={() => setSendPopup({ data: {}, status: !status })}></div>
        <div className='popup__content'>
          <MdOutlineClose
            size={22}
            className='popup__close'
            onClick={() => setSendPopup({ data: {}, status: !status })}
          />
          <div className='cards__popup'>
            <h3 className='cards__popup__title'>Отправить резюме к отклику</h3>
            <p>{data?.postPos}</p>
            <p>{data?.employer}</p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='cards__popup__form'>
              <Select
                isSearchable={false}
                className='card__popup__select'
                styles={{ fontFamily: 'Noto Sans' }}
                placeholder='Выберите резюме'
                options={resumesOptions}
                onChange={(val) => setResumeCur(val)}
                noOptionsMessage={() => 'Нет одобренных резюме'}
              />
              <label>
                {emoji && (
                  <FaRegSmile color='rgba(7, 7, 7, 0.3)' size={'20px'} />
                )}
                <textarea
                  {...register('message')}
                  className={`${errors?.message && 'error'}`}
                  type='text'
                  onChange={(e) => {
                    e.target.value.length > 0
                      ? setEmoji(false)
                      : setEmoji(true);
                  }}
                  placeholder='        Написать сообщение'
                  rows='2'></textarea>
              </label>
              {errors?.message && (
                <p className='authPopup__form-err'>
                  {errors?.message?.message || 'Ошибка'}
                </p>
              )}
              <input type='submit' value='Отправить' />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardPopup;
