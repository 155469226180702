// import {useState} from 'react'
// import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useGetResumesQuery } from '../../../redux';
import CardsList from '../../../components/CardsList/CardsList';
import CardsSkeletonList from '../../../components/CardsList/CardsSkeletonList';

function ResumeBottom() {
  const navigate = useNavigate();
  const { data = [], isLoading } = useGetResumesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const { t } = useTranslation();
  // const [positionValue, setPositionValue] = useState()

  // const handleInputChange = (newValue) => {
  //   setPositionValue(newValue.value)
  //   return newValue
  // }
  return (
    <div className='contentContent__bottom'>
      <h3 className='contentContent__bottom__title'>
        {t('Home.newContent.resumeTitle')}
      </h3>
      <div className='regionSwiper'>
        <div className='regionSwiper__top'>
          {/* <ul className="regionSwiper__list">
            <li className="regionSwiper__list-item">
              <p>Бишкек</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Токмок</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Кант</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Каракол</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Токтогул</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Талас</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Нарын</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Джалал-Абад</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Ош</p>
            </li>
            <li className="regionSwiper__list-item">
              <p>Баткен</p>
            </li>
          </ul> */}
          <div className='regionSwiper__list-bottom'></div>
          {/* <div className="regionSwiper__form">
            <Select
              className="regionSwiper__form-input"
              placeholder="Время"
              onChange={handleInputChange}></Select>
            <Select
              className="regionSwiper__form-input"
              placeholder="Зарплата"
              onChange={handleInputChange}></Select>
            <Select
              className="regionSwiper__form-input initial"
              placeholder="Профессия"
              onChange={handleInputChange}></Select>
          </div> */}
          <div className='regionSwiper__cards'>
            {!isLoading && data?.results.length && (
              <CardsList cards={data.results} />
            )}
            {isLoading && <CardsSkeletonList />}
            {!isLoading && !data?.results.length && (
              <p className='favourites__text'>Пусто</p>
            )}
          </div>
          <Link
            to={{
              pathname: '/resumes-all',
              search: '?page=2',
            }}
            className='regionSwiper__link-more unSelect'>
            {t('Home.newContent.viewBtn')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ResumeBottom;
