import { BsTelegram } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';
import { ImMail4 } from 'react-icons/im';
import logo from '../../../assets/svg/logoSquare.svg';
import { HandySvg } from 'handy-svg';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ContentContext } from '../../../utils/ContentContext';

function ManagerSub({ setManagerShow }) {
  const { user } = useSelector((s) => s.userState);
  const [managerInfo, setManagerInfo] = useState({});
  const { postShow, setPostShow } = useContext(ContentContext);

  useEffect(() => {
    if (user?.manager !== undefined) setManagerInfo(user?.manager[0]);
  }, []);

  return (
    <div
      className='preHeader__auth-profile-sub preHeader__auth-manager-sub preHeader__auth-profile-sub--entity'
      onMouseOut={() => setManagerShow(false)}
      onMouseOver={() => setManagerShow(true)}>
      <div className='preHeader__auth-manager-sub__logo'>
        <HandySvg src={logo} width='44' height='44' />
      </div>
      <h5 className='preHeader__auth-manager-sub__title'>Ваш менеджер</h5>
      <p className='preHeader__auth-manager-sub__schedule'>
        Работаем по будням, с 9:00 до 18:00
      </p>
      <p className='preHeader__auth-manager-sub__phone'>
        <span>Вы можете оставить заявку на:</span> <br />
        <i onClick={() => setPostShow(true)}>Звонок</i>
      </p>
      <p className='preHeader__auth-manager-sub__post'>
        <span> Либо позвонить по номеру:</span> <br />{' '}
        <a href={`tel:0312975507`}>0312975507</a>
      </p>
      {/* <button className='preHeader__auth-manager-sub__post'>
        Оставить свою заявку
      </button> */}
      <div className='preHeader__auth-manager-sub__socs'>
        <ul>
          <li>
            <IoLogoWhatsapp size={20} className='whatsapp' />
            <a target='_blank' href={`https://wa.me/${managerInfo?.whatsapp}`}>
              {managerInfo?.whatsapp}
            </a>
          </li>
          <li>
            <BsTelegram size={20} className='telegram' />
            <a
              target='_blank'
              href={`https://telegram.me/${managerInfo?.telegram}`}>
              {managerInfo?.telegram}
            </a>
          </li>
          <li>
            <ImMail4 size={20} className='mail' />
            <a target='_blank' href={`mailto:${managerInfo?.email}`}>
              {managerInfo?.email}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ManagerSub;
