import PartnersSlider from '../../components/PartnersSlider/PartnersSlider';
import SeoHelmet from '../../hoc/SeoHelmet';
import VacanciesTop from './VacanciesTop/VacanciesTop';
import VacanciesBottom from './VacanciesBottom/VacanciesBottom';
import VacancesList from './VacancesList/VacancesList';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import Aside from '../../components/Aside/Aside';

function Home() {
  const { width } = useWindowDimensions();

  return (
    <>
      <SeoHelmet
        description='Сайт hrgroup.kg — это удобное, простое решение при поиске сотрудников. Легкий интерфейс и простая процедура публикаций сделает поиск работы и сотрудников максимально удобным. Мы хотим помочь каждому соискателю найти достойную работу, а каждому работодателю достойного сотрудника.'
        location={window.location.href}
      />
      <VacancesList />
      {width >= 580 ? <PartnersSlider /> : null}
      <div className='contentContent'>
        <h3 className='contentContent__title'>Топ вакансии</h3>
        <div className='contentContent__wrapper'>
          {width >= 860 && <Aside />}
          <div className='contentContent__main'>
            <VacanciesTop />
            <VacanciesBottom />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
