import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const SeoHelmet = ({
  title,
  innerTitle,
  description = '',
  location = window.location.href,
  image_url,
  isLoading = true,
}) => {
  const { pathname } = useLocation();
  const MAIN_TITLE = `${
    title
      ? title + ' | HRGroup.kg'
      : pathname == '/'
      ? 'HRGroup.kg - Работа в Бишкеке и в Кыргызстане'
      : isLoading
      ? 'Загрузка'
      : 'Не найдено'
  }`;
  const MAIN_DESCRIPTION = `${
    description?.length > 300 ? description?.slice(0, 160) : description
  }`;
  const MAIN_IMAGE_URL =
    image_url === null || image_url === undefined
      ? `https://hrgroup.kg/%PUBLIC_URL%/favicon.ico`
      : image_url[0] === '/'
      ? `https://hrgroup.kg/${image_url}`
      : image_url;
  return (
    <Helmet>
      {/* HTML Meta Tags */}
      <title>{`${MAIN_TITLE}`}</title>
      <meta name='title' content={innerTitle} />
      <meta name='description' content={MAIN_DESCRIPTION} />
      {/* Facebook Meta Tags */}
      <meta property='og:title' content={`${MAIN_TITLE}`} />
      <meta property='og:description' content={MAIN_DESCRIPTION} />
      <meta property='og:image' itemProp='image' content={MAIN_IMAGE_URL} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={location} />
      {/* Twitter Meta Tags */}
      <meta property='twitter:title' content={`${MAIN_TITLE}`} />
      <meta property='twitter:description' content={MAIN_DESCRIPTION} />
      <meta property='twitter:domain' content='hrgroup.kg' />
      <meta property='twitter:url' content={location} />
      <meta
        property='twitter:image'
        itemProp='image'
        content={MAIN_IMAGE_URL}
      />
      <meta property='twitter:card' content={MAIN_IMAGE_URL} />
    </Helmet>
  );
};

export default SeoHelmet;
