import { useGetActiveBannersQuery } from '../../redux/api/mockApi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import AsideSkeleton from './AsideSkeleton';

function Aside() {
  const { data, isLoading } = useGetActiveBannersQuery(
    {},
    { refetchOnReconnect: true }
  );
  return (
    <aside>
      {!isLoading && data?.length > 0 && (
        <Swiper loop='true' autoplay={{ delay: 10000 }} modules={[Autoplay]}>
          {data?.map(({ image, link, tariff_title, profile }, idx) => {
            return (
              <SwiperSlide key={idx}>
                <a
                  href={link}
                  target='_blank'
                  title={tariff_title}
                  rel='noreferrer'>
                  <img src={image || profile?.image} alt={tariff_title} />
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      {isLoading && <AsideSkeleton />}
    </aside>
  );
}

export default Aside;
