import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const ContentContext = createContext();
export const ContentContextProvider = (props) => {
  const [sendPopup, setSendPopup] = useState({
    data: {},
    status: false,
  });

  const [cart, setCart] = useState([]);
  let localCart = localStorage.getItem('cart');

  const [createdPopup, setCreatedPopup] = useState(false);

  const [orderListTYPE, setOrderListTYPE] = useState(0);

  const [postShow, setPostShow] = useState(false);

  const [cartShow, setCartShow] = useState(false);

  const addCart = (cartObj) => {
    let cartCopy = [...cart];
    let { tariff } = cartObj;
    let existingCart = cartCopy.find((cartItem) => cartItem.tariff === tariff);
    if (existingCart) {
      toast.warning('Данная услуга уже есть в корзине');
    } else {
      cartCopy.push(cartObj);
    }
    setCart(cartCopy);
    localStorage.setItem('cart', JSON.stringify(cartCopy));
  };
  const removeCart = (tariff) => {
    let cartCopy = [...cart];
    cartCopy = cartCopy.filter((item) => item.tariff != tariff);
    setCart(cartCopy);
    let cartString = JSON.stringify(cartCopy);
    localStorage.setItem('cart', cartString);
  };
  const clearCart = () => {
    let cartCopy = [];
    setCart(cartCopy);
    let cartString = JSON.stringify(cartCopy);
    localStorage.setItem('cart', cartString);
  };

  useEffect(() => {
    localCart = JSON.parse(localCart);
    if (localCart) setCart(localCart);
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const { pg_order_id, pg_payment_id, pg_salt, pg_sig } = params;
    if (pg_order_id || pg_payment_id || pg_salt || pg_sig) {
      window.location.search = '';
    }
  }, []);

  const value = {
    sendPopup,
    setSendPopup,
    createdPopup,
    setCreatedPopup,
    postShow,
    setPostShow,
    orderListTYPE,
    setOrderListTYPE,
    addCart,
    removeCart,
    clearCart,
    cart,
    cartShow,
    setCartShow,
  };

  return (
    <ContentContext.Provider value={value}>
      {props.children}
    </ContentContext.Provider>
  );
};
