import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  params: {},
  queryParams: {},
  searchResult: [],
};
export const searchParamsSlice = createSlice({
  initialState,
  name: 'searchParamsSlice',
  reducers: {
    addParamOfSearchState: (state, action) => {
      let obj = action.payload;
      state.params = {
        ...state.params,
        obj,
      };
    },
    removeParamOfSearchState: (state, action) => {
      let updated = state.params;
      delete updated[action.payload];
      state.params = updated;
    },
    setParamOfSearchState: (state, action) => {
      let actionValue = action.payload;
      let transformed = Object.entries(actionValue).reduce((accum, [k, v]) => {
        if (v !== undefined && Array.isArray(v)) {
          accum[k] = v.reduce((acc, rec) => (acc += `$${rec.value}`), '');
        } else if (k == 'type' || k == 'agge' || k == 'gender' || k == 'time') {
          accum[k] = v?.value;
        } else accum[k] = v?.label;
        return accum;
      }, {});
      state.params = actionValue;
      state.queryParams = transformed;
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
    resetParamOfSearchState: (state) => {
      state.params = {};
      state.queryParams = {};
    },
  },
});

export default searchParamsSlice.reducer;

export const {
  addParamOfSearchState,
  resetParamOfSearchState,
  removeParamOfSearchState,
  setParamOfSearchState,
  setSearchResult,
  setAgreeState,
} = searchParamsSlice.actions;
