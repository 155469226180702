import { useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';

//Constants
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetActiveEmployersQuery } from '../../redux/api/mockApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import PartnersSliderSkeleton from './PartnersSliderSkeleton';

// const
const PartnersSlider = () => {
  const { t } = useTranslation();
  const sliderRef = useRef(null);
  const { width } = useWindowDimensions();
  const [slideSliceLength, setSlideSliceLength] = useState(10);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const { data: partnersCompanies = [], isLoading } =
    useGetActiveEmployersQuery({}, { refetchOnReconnect: true });

  useEffect(() => {
    if (width <= 960) setSlideSliceLength(8);
    if (width <= 760) setSlideSliceLength(6);
    if (width <= 520) setSlideSliceLength(2);
  }, [width]);

  function sliceSlides(array, slide_size, slide_number) {
    return array.slice(
      (slide_number - 1) * slide_size,
      slide_number * slide_size
    );
  }
  return (
    <>
      {partnersCompanies.length > 0 && (
        <div className='employers'>
          {isLoading && (
            <PartnersSliderSkeleton slideSliceLength={slideSliceLength} />
          )}
          {!isLoading && (
            <>
              <h3 className='employers__title'>{t('Home.partners.title')}</h3>
              <Swiper
                autoplay={{ delay: 15000 }}
                loop='true'
                modules={[Autoplay]}
                ref={sliderRef}>
                {[
                  ...Array(
                    Math.ceil(partnersCompanies.length / slideSliceLength)
                  ).fill(),
                ].map((x, i) => {
                  return (
                    <SwiperSlide key={i} className='employers__cards'>
                      {sliceSlides(
                        partnersCompanies,
                        slideSliceLength,
                        i + 1
                      ).map(
                        ({ id, image, link, title, vacancies_count }, idx) => (
                          <a
                            key={idx}
                            href={link}
                            target={'_blank'}
                            className='employers__cards-card'>
                            <img src={image} alt='logo' />
                            <div className='employers__cards-card-hover'>
                              <h4>{title}</h4>
                              <p>{vacancies_count} вакансий</p>
                            </div>
                          </a>
                        )
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          )}
          <div className='employers__nav'>
            <div className='employers__nav-prevBtn' onClick={handlePrev}>
              <RiArrowLeftSLine width={24} height={24} />
            </div>
            <div className='employers__nav-nextBtn' onClick={handleNext}>
              <RiArrowRightSLine width={24} height={24} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnersSlider;
