export const toDateSet = (date) => {
  return new Intl.DateTimeFormat(
    `${localStorage.getItem('i18nextLng') || 'ru'}`,
    {
      day: '2-digit',
      month: 'long',
      time: 'numeric',
    }
  ).format(new Date(date));
};
export const toDateSetTime = (date) => {
  return new Intl.DateTimeFormat(
    `${localStorage.getItem('i18nextLng') || 'ru'}`,
    {
      hour: '2-digit',
      minute: '2-digit',
    }
  ).format(new Date(date));
};
export const toDateGetMinutes = (date) => {
  return new Intl.DateTimeFormat(
    `${localStorage.getItem('i18nextLng') || 'ru'}`,
    {
      hour: '2-digit',
      minute: '2-digit',
    }
  ).format(new Date(date));
};
export const toMonthYear = (date) => {
  try {
    return new Intl.DateTimeFormat(
      `${localStorage.getItem('i18nextLng') || 'ru'}`,
      {
        month: 'long',
        year: 'numeric',
      }
    ).format(new Date(date));
  } catch (error) {
    console.error(error);
    return 'Некорректная дата';
  }
};

export function declension(forms, val) {
  const cases = [2, 0, 1, 1, 1, 2];
  return forms[
    val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5]
  ];
}

const MONTH_NAMES = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];

export const getFormattedDate = (
  date,
  prefomattedDate = false,
  hideYear = false
) => {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day} ${month} ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day} ${month} ${year}. ${hours}:${minutes}`;
};

export const timeAgo = (dateParam) => {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return 'сейчас';
  } else if (seconds < 60) {
    return `${seconds} секунд назад`;
  } else if (seconds < 90) {
    return 'более минуты назад';
  } else if (minutes < 60) {
    return `${minutes} ${declension(
      ['минута', 'минуты', 'минут'],
      minutes
    )} назад`;
  } else if (isToday) {
    return getFormattedDate(date, 'Сегодня в'); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Вчера в'); // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, 'В'); // 10. January at 10:20
  }
};
