import PreHeader from './PreHeader/PreHeader';
import Footer from './Footer/Footer';
import CardPopup from '../components/CardPopup/CardPopup';
import { ToastContainer } from 'react-toastify';
import CreatedPopup from '../components/CreatedPopup/CreatedPopup';
import { useContext } from 'react';
import { ContentContext } from '../utils/ContentContext';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import BottomLayout from './BottomLayout/BottomLayout';
import { useLocation } from 'react-router-dom';
import Loader from '../hoc/Loader/Loader';
import { Suspense } from 'react';
import ErrorBoundary from '../hoc/ErrorBoundary';

const Layout = ({ children }) => {
  const { sendPopup, createdPopup } = useContext(ContentContext);
  const { width } = useWindowDimensions();
  const { status } = sendPopup;
  const { pathname } = useLocation();

  const isInChat = pathname.includes('chat') && pathname.length > 6;

  return (
    <>
      <PreHeader />
      <main className='container'>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>{children}</Suspense>
        </ErrorBoundary>
      </main>
      {width <= 491 && !isInChat && <BottomLayout />}
      {status && <CardPopup />}
      {createdPopup && <CreatedPopup />}
      {/* <ToastContainer
        position={width > 491 ? 'bottom-center' : 'top-center'}
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme='light'
      /> */}
      {!window.location.pathname.includes('chat') && <Footer />}
    </>
  );
};
export default Layout;
