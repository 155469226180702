import { validURL } from './validURL';

export const transformBeforeFetch = (data, userID, photo) => {
  const formData = new FormData();
  for (var key in data) {
    if (Array.isArray(data[key]) && data[key].length > 0) {
      formData.append([key], JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  }
  formData.delete('specialization');
  if (data?.specialization.length > 0)
    for (var spec in data?.specialization) {
      formData.append('specialization', data?.specialization[spec]);
    }
  formData.append('user', userID);
  if (typeof photo === 'string' || validURL(data.image))
    formData.delete('image');
  if (typeof photo !== 'string') formData.append('image', photo);
  if (data.no_experience === undefined) formData.append('no_experience', false);
  if (data.negotiable === undefined) {
    formData.delete('negotiable');
    formData.append('negotiable', false);
  }
  if (
    typeof data.salary !== 'string' ||
    data.salary === null ||
    data.salary === undefined ||
    data.salary === ''
  ) {
    formData.delete('salary');
    formData.append('salary', 0);
  }
  return formData;
};
