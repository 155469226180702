import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { RiCheckDoubleLine, RiCheckFill } from 'react-icons/ri';
import useTextWithLinks from '../../hooks/useTextWithLinks';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useSelector } from 'react-redux';
import { baseDOMAIN } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const MessagesListMessage = ({ message, last_message, setDeleteAlert }) => {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const messageContext = useRef();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const {
    text = '',
    created_at = '',
    resume_id = '',
    vacancy_id = '',
    resume_position = '',
    author = '',
    status = '',
    id = '',
  } = message;

  const originURL = `https://${baseDOMAIN}/`;
  const isApplicant = GetCookie('status') === 'applicant';
  const isPostingMessage = !!resume_id && !!vacancy_id && !!resume_position;
  const postingHref = `${
    isApplicant
      ? originURL + 'resumes/' + resume_id
      : originURL + 'company/company-vacancies/detail/' + vacancy_id
  }`;
  const postingLink = `[${resume_position}](${postingHref})`;

  const messageOutput = useTextWithLinks(decodeURIComponent(escape(text)));

  const {
    user: { username: currentAuthor },
  } = useSelector((s) => s.userState);
  let isMine = currentAuthor === author;
  // let isLastMessage = last_message.id === id;

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({ x: event.clientX, y: event.clientY });
    setContextMenuOpen(true);
  };

  const handleClick = () => {
    setContextMenuOpen(false);
  };

  useOnClickOutside(messageContext, () => setContextMenuOpen(false));

  useEffect(() => {
    let listOfMessages = document.querySelector('.messages__list');
    if (contextMenuOpen) {
      listOfMessages.style.overflowY = 'hidden';
    } else {
      listOfMessages.style.overflowY = 'scroll';
    }
  }, [contextMenuOpen]);

  function MessageCTX() {
    return (
      <div
        ref={messageContext}
        className={`messages__text-message-context messages__text-message-context--${
          isMine ? 'mine' : 'companion'
        }`}
        style={{ top: position.y, left: position.x }}>
        <ul>
          {/* Добавить иконку мусорки и цвет - #D71F4B*/}
          <li onClick={() => setDeleteAlert(id)}>Удалить Сообщение</li>
        </ul>
      </div>
    );
  }

  return (
    <div
      onContextMenu={handleContextMenu}
      onClick={handleClick}
      className={`messages__text-message messages__text-message--${
        isMine ? 'mine' : 'companion'
      }`}>
      <ReactMarkdown
        children={
          isPostingMessage
            ? `${postingLink} \n\n ${messageOutput}`
            : messageOutput
        }
      />
      {contextMenuOpen && <MessageCTX />}
      <div className='messages__text-message-info unSelect'>
        <span>{moment(created_at).format('h:mm')}</span>
        {isMine ? (
          status ? (
            <RiCheckDoubleLine size='17' title='Просмотрено' />
          ) : (
            <RiCheckFill size='17' title='Не просмотрено' />
          )
        ) : null}
      </div>
    </div>
  );
};

const MessagesList = ({ messages, setDeleteAlert }) => {
  const messagesRef = useRef();

  const sortedMessages = useMemo(
    () => messages.sort((a, b) => moment(a.created_at) - moment(b.created_at)),
    [messages]
  );

  const uniqueDates = useMemo(
    () => [
      ...new Set(
        sortedMessages.map((message) =>
          moment(message.created_at).format('YYYY-MM-DD')
        )
      ),
    ],
    [sortedMessages]
  );

  const sections = uniqueDates.map((date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');

    const momentDate = moment(date);

    const isToday = momentDate.isSame(today, 'day');
    const isYesterday = momentDate.isSame(yesterday, 'day');

    const formattedDate = isToday
      ? 'Сегодня'
      : isYesterday
      ? 'Вчера'
      : momentDate.format('DD MMMM YYYY');

    const messagesForDate = sortedMessages.filter(
      (message) => moment(message.created_at).format('YYYY-MM-DD') === date
    );

    return {
      date: formattedDate,
      messages: messagesForDate,
    };
  });

  useEffect(() => {
    const node = messagesRef.current;
    node.scrollTop = node.scrollHeight;
  }, [messages]);

  return (
    <div className='messages__list' ref={messagesRef}>
      {sections.map((section) => (
        <div className='messages__by-date' key={section.date}>
          <h2>{section.date}</h2>
          <div className='messages__by-list'>
            {section.messages.map((message) => {
              let last_day = sections[sections.length - 1];
              let last_message =
                last_day.messages[last_day.messages.length - 1];
              return (
                <MessagesListMessage
                  setDeleteAlert={setDeleteAlert}
                  key={message.id}
                  message={message}
                  last_message={last_message}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessagesList;
