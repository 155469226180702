import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addResume: { salary_currency: 'KGS' },
  agree: false,
};
export const addResumeSlice = createSlice({
  initialState,
  name: 'addResumeSlice',
  reducers: {
    addParamOfResumeState: (state, action) => {
      state.addResume = {
        ...state.addResume,
        [action.payload.name]: action.payload.value,
      };
    },
    removeParamOfResumeState: (state, action) => {
      let updated = state.addResume;
      delete updated[action.payload];
      state.addResume = updated;
    },
    setParamOfResumeState: (state, action) => {
      state.addResume = action.payload;
    },
    resetParamOfResumeState: (state) => {
      state.addResume = {};
      state.agree = false;
    },
    setAgreeState: (state, action) => {
      state.agree = action.payload;
    },
  },
});

export default addResumeSlice.reducer;

export const {
  addParamOfResumeState,
  resetParamOfResumeState,
  removeParamOfResumeState,
  setParamOfResumeState,
  setAgreeState,
} = addResumeSlice.actions;
