function Loader() {
  const css = `
  .loader-container{
    min-height: 50vh;
  }
        .animation {
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .animation span {
          width: 5px;
          height: 50px;
          background: linear-gradient(180deg, #003865, #ffb310);
          display: inline-block;
          margin: 0 1px;
          border-radius: 50px;
          animation: animate 1.5s infinite ease-in-out;
        }

        @keyframes animate {
          0%,
          50%,
          100% {
            transform: scaleY(0.5);
          }
          20% {
            transform: scaleY(1);
          }
        }
        .animation span:nth-child(1) {
          animation-delay: 1.1s;
        }
        .animation span:nth-child(2) {
          animation-delay: 1.2s;
        }
        .animation span:nth-child(3) {
          animation-delay: 1.3s;
        }
        .animation span:nth-child(4) {
          animation-delay: 1.4s;
        }
        .animation span:nth-child(5) {
          animation-delay: 1.5s;
        }
      `;
  return (
    <div className='loader-container'>
      <div className='animation'>
        <style>{css}</style>
        <div>
          <svg
            width='54'
            height='42'
            viewBox='0 0 54 42'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.0957031 0H8.81842V9.88185H18.3499V0H27.1111V28.2394H18.3499V16.8165H8.81842V28.2394H0.0957031V0Z'
              fill='#003865'
            />
            <path
              d='M27.6826 0H42.182C45.3399 0 47.7019 0.751252 49.268 2.25375C50.8469 3.75626 51.6364 5.89443 51.6364 8.66829C51.6364 11.5192 50.7763 13.7473 49.0562 15.3525C47.3488 16.9577 44.7365 17.7604 41.2192 17.7604H36.4438V28.2394H27.6826V0ZM36.4438 12.0393H38.5812C40.2628 12.0393 41.4438 11.7503 42.1242 11.1725C42.8046 10.5817 43.1447 9.83048 43.1447 8.9187C43.1447 8.03261 42.8495 7.28136 42.259 6.66495C41.6685 6.04854 40.5581 5.74033 38.9278 5.74033H36.4438V12.0393Z'
              fill='#003865'
            />
            <path
              d='M7.68186 34.4053C7.57958 34.0842 7.44202 33.7966 7.2692 33.5426C7.0999 33.285 6.8971 33.0662 6.66079 32.8863C6.42448 32.7028 6.15466 32.5634 5.85134 32.4682C5.55154 32.3729 5.22176 32.3253 4.862 32.3253C4.25183 32.3253 3.70161 32.4823 3.21136 32.7963C2.7211 33.1103 2.33313 33.5708 2.04744 34.1777C1.76528 34.781 1.62419 35.5202 1.62419 36.3953C1.62419 37.2738 1.76704 38.0166 2.05273 38.6234C2.33842 39.2303 2.72992 39.6908 3.22723 40.0048C3.72454 40.3188 4.29063 40.4758 4.92549 40.4758C5.5145 40.4758 6.02769 40.3559 6.46504 40.1159C6.90592 39.876 7.24627 39.5373 7.48611 39.0998C7.72948 38.6587 7.85116 38.14 7.85116 37.5438L8.2744 37.6231H5.17415V36.2735H9.43303V37.5067C9.43303 38.417 9.23904 39.2074 8.85107 39.8778C8.46662 40.5446 7.93404 41.0598 7.25333 41.4232C6.57614 41.7866 5.80019 41.9683 4.92549 41.9683C3.94498 41.9683 3.08438 41.7425 2.34371 41.2909C1.60656 40.8393 1.03165 40.1989 0.618993 39.3697C0.206331 38.537 0 37.549 0 36.4058C0 35.5414 0.119919 34.7651 0.359757 34.0771C0.599594 33.3891 0.936425 32.8051 1.37025 32.3253C1.8076 31.8419 2.32078 31.4732 2.9098 31.2191C3.50234 30.9615 4.14955 30.8328 4.85142 30.8328C5.43691 30.8328 5.98183 30.9192 6.4862 31.0921C6.99409 31.265 7.44555 31.5102 7.84058 31.8278C8.23913 32.1453 8.56891 32.5229 8.82991 32.9604C9.09091 33.3944 9.26726 33.876 9.35896 34.4053H7.68186Z'
              fill='#FFB310'
            />
            <path
              d='M11.5082 41.8202V30.981H15.3703C16.2098 30.981 16.9064 31.1256 17.4601 31.4149C18.0174 31.7043 18.4336 32.1047 18.7087 32.6164C18.9838 33.1244 19.1213 33.7119 19.1213 34.3788C19.1213 35.0421 18.982 35.6261 18.7034 36.1306C18.4283 36.6317 18.0121 37.0216 17.4548 37.3003C16.9011 37.579 16.2045 37.7184 15.365 37.7184H12.4394V36.3106H15.2169C15.746 36.3106 16.1763 36.2347 16.5078 36.083C16.8429 35.9313 17.088 35.7108 17.2432 35.4214C17.3984 35.1321 17.476 34.7846 17.476 34.3788C17.476 33.9695 17.3966 33.6149 17.2379 33.315C17.0827 33.0151 16.8376 32.7857 16.5025 32.6269C16.171 32.4646 15.7354 32.3835 15.1957 32.3835H13.143V41.8202H11.5082ZM16.857 36.9298L19.534 41.8202H17.6717L15.0476 36.9298H16.857Z'
              fill='#FFB310'
            />
            <path
              d='M30.5224 36.4006C30.5224 37.5579 30.3108 38.5529 29.8876 39.3856C29.4643 40.2147 28.8841 40.8534 28.147 41.3015C27.4134 41.7461 26.5792 41.9683 25.6446 41.9683C24.7064 41.9683 23.8687 41.7461 23.1316 41.3015C22.3979 40.8534 21.8195 40.213 21.3963 39.3803C20.973 38.5476 20.7614 37.5543 20.7614 36.4006C20.7614 35.2432 20.973 34.25 21.3963 33.4208C21.8195 32.5881 22.3979 31.9495 23.1316 31.5049C23.8687 31.0568 24.7064 30.8328 25.6446 30.8328C26.5792 30.8328 27.4134 31.0568 28.147 31.5049C28.8841 31.9495 29.4643 32.5881 29.8876 33.4208C30.3108 34.25 30.5224 35.2432 30.5224 36.4006ZM28.9035 36.4006C28.9035 35.5185 28.7607 34.7757 28.475 34.1724C28.1928 33.5655 27.8049 33.1068 27.3111 32.7963C26.8208 32.4823 26.2653 32.3253 25.6446 32.3253C25.0203 32.3253 24.463 32.4823 23.9727 32.7963C23.4825 33.1068 23.0945 33.5655 22.8088 34.1724C22.5267 34.7757 22.3856 35.5185 22.3856 36.4006C22.3856 37.2827 22.5267 38.0271 22.8088 38.634C23.0945 39.2374 23.4825 39.6961 23.9727 40.0101C24.463 40.3206 25.0203 40.4758 25.6446 40.4758C26.2653 40.4758 26.8208 40.3206 27.3111 40.0101C27.8049 39.6961 28.1928 39.2374 28.475 38.634C28.7607 38.0271 28.9035 37.2827 28.9035 36.4006Z'
              fill='#FFB310'
            />
            <path
              d='M39.5283 30.981H41.1683V38.1101C41.1683 38.8687 40.9902 39.5408 40.634 40.1265C40.2777 40.7087 39.7769 41.1674 39.1315 41.5026C38.486 41.8343 37.7295 42.0001 36.8618 42.0001C35.9977 42.0001 35.2429 41.8343 34.5975 41.5026C33.952 41.1674 33.4512 40.7087 33.095 40.1265C32.7387 39.5408 32.5606 38.8687 32.5606 38.1101V30.981H34.1954V37.9777C34.1954 38.4682 34.303 38.9039 34.5181 39.285C34.7368 39.6661 35.0454 39.966 35.444 40.1847C35.8425 40.4 36.3151 40.5076 36.8618 40.5076C37.412 40.5076 37.8864 40.4 38.285 40.1847C38.6871 39.966 38.9939 39.6661 39.2055 39.285C39.4207 38.9039 39.5283 38.4682 39.5283 37.9777V30.981Z'
              fill='#FFB310'
            />
            <path
              d='M43.5451 41.8202V30.981H47.4072C48.2501 30.981 48.9485 31.1344 49.5022 31.4414C50.056 31.7484 50.4704 32.1683 50.7455 32.701C51.0206 33.2303 51.1582 33.8266 51.1582 34.4899C51.1582 35.1568 51.0189 35.7566 50.7402 36.2894C50.4651 36.8187 50.0489 37.2385 49.4917 37.549C48.9379 37.856 48.2413 38.0095 47.4019 38.0095H44.746V36.6228H47.2538C47.7863 36.6228 48.2184 36.5311 48.5499 36.3476C48.8815 36.1606 49.1248 35.9066 49.28 35.5855C49.4352 35.2644 49.5128 34.8992 49.5128 34.4899C49.5128 34.0806 49.4352 33.7172 49.28 33.3997C49.1248 33.0821 48.8797 32.8334 48.5447 32.6534C48.2131 32.4735 47.7758 32.3835 47.2326 32.3835H45.1799V41.8202H43.5451Z'
              fill='#FFB310'
            />
            <path
              d='M50.7909 23.145L48.645 24.5923L42.751 15.6968L44.8975 14.2495L50.7909 23.145Z'
              fill='#FFB310'
            />
            <path
              d='M52.4348 26.9513L51.2051 27.7765C50.3929 28.3223 49.4646 28.3554 49.1315 27.8501L44.1708 20.325C44.1163 20.2419 44.1804 20.1022 44.3136 20.0121L48.0008 17.5365C48.1347 17.4464 48.2869 17.4413 48.3421 17.5244L53.3022 25.0502C53.6353 25.5523 53.2495 26.4047 52.4348 26.9513Z'
              fill='#FFB310'
            />
            <path
              d='M44.6623 15.1673C41.109 17.5623 36.3051 16.6171 33.9478 13.0599C31.5906 9.50273 32.5646 4.65951 36.1134 2.26456C39.6623 -0.13039 44.4713 0.816672 46.8279 4.37133C49.1845 7.92598 48.2137 12.7724 44.6623 15.1673ZM36.503 2.85298C33.2759 5.03021 32.3926 9.43163 34.536 12.6645C36.6794 15.8973 41.0468 16.7568 44.2739 14.5802C47.5011 12.4036 48.3843 8.00152 46.2409 4.76869C44.0976 1.53585 39.7308 0.675755 36.503 2.85235V2.85298Z'
              fill='#FFB310'
            />
            <path
              d='M40.3881 15.9258C44.3687 15.9258 47.5956 12.6977 47.5956 8.71561C47.5956 4.7335 44.3687 1.50537 40.3881 1.50537C36.4076 1.50537 33.1807 4.7335 33.1807 8.71561C33.1807 12.6977 36.4076 15.9258 40.3881 15.9258Z'
              fill='white'
            />
            <path
              d='M45.6273 8.71761L45.5759 8.69285C45.7531 8.4306 45.8462 8.12066 45.8431 7.80419C45.8431 7.01454 45.2866 6.3728 44.6026 6.3728C43.9186 6.3728 43.3621 7.01454 43.3621 7.80419C43.359 8.12066 43.4521 8.4306 43.6292 8.69285C43.602 8.70491 43.5842 8.71443 43.5766 8.71824C43.2205 8.88157 42.9187 9.14342 42.7066 9.47288C42.4945 9.80233 42.3812 10.1856 42.3799 10.5775V11.0732C42.3799 11.176 42.4206 11.2745 42.4932 11.3472C42.5658 11.4199 42.6642 11.4609 42.7669 11.461H46.4376C46.5403 11.4609 46.6388 11.4199 46.7113 11.3472C46.7839 11.2745 46.8247 11.176 46.8247 11.0732V10.5775C46.8234 10.1854 46.71 9.80197 46.4978 9.47238C46.2857 9.1428 45.9836 8.88089 45.6273 8.71761Z'
              fill='#FFB310'
            />
            <path
              d='M37.1957 8.71761L37.1443 8.69285C37.3214 8.4306 37.4146 8.12066 37.4114 7.80419C37.4114 7.01454 36.855 6.3728 36.1709 6.3728C35.4869 6.3728 34.9305 7.01454 34.9305 7.80419C34.9273 8.12066 35.0205 8.4306 35.1976 8.69285C35.1703 8.70491 35.1526 8.71443 35.1449 8.71824C34.7889 8.88157 34.487 9.14342 34.275 9.47288C34.0629 9.80233 33.9495 10.1856 33.9482 10.5775V11.0732C33.9482 11.176 33.989 11.2745 34.0616 11.3472C34.1341 11.4199 34.2326 11.4609 34.3353 11.461H38.006C38.1087 11.4609 38.2071 11.4199 38.2797 11.3472C38.3523 11.2745 38.393 11.176 38.393 11.0732V10.5775C38.3918 10.1854 38.2784 9.80197 38.0662 9.47238C37.854 9.1428 37.552 8.88089 37.1957 8.71761Z'
              fill='#FFB310'
            />
            <path
              d='M41.661 7.49553L41.5976 7.46633C41.8041 7.15991 41.9127 6.79802 41.9091 6.4285C41.9091 5.50556 41.2588 4.75464 40.4593 4.75464C39.6598 4.75464 39.0088 5.50556 39.0088 6.4285C39.0053 6.79809 39.1142 7.16001 39.321 7.46633C39.2892 7.4803 39.2683 7.49109 39.2575 7.49617C38.8412 7.68715 38.4882 7.99335 38.2403 8.3786C37.9924 8.76385 37.8598 9.21204 37.8584 9.67022V10.2498C37.8584 10.37 37.9061 10.4852 37.9911 10.5702C38.0761 10.6552 38.1913 10.703 38.3114 10.703H42.6084C42.7284 10.7028 42.8435 10.655 42.9283 10.57C43.0131 10.485 43.0608 10.3698 43.0608 10.2498V9.67022C43.0594 9.21186 42.9269 8.76348 42.6788 8.3781C42.4308 7.99273 42.0776 7.68646 41.661 7.49553Z'
              fill='#FFB310'
            />
            <path
              d='M45.5718 8.83887C45.5648 8.84712 45.5585 8.85537 45.5515 8.86299C45.438 9.00531 45.294 9.12023 45.13 9.19921C44.9661 9.2782 44.7864 9.31921 44.6045 9.31921C44.4225 9.31921 44.2429 9.2782 44.0789 9.19921C43.915 9.12023 43.7709 9.00531 43.6575 8.86299L43.6378 8.83887C43.3042 8.99089 43.0211 9.23554 42.8224 9.54373C42.6237 9.85192 42.5175 10.2107 42.5166 10.5775V11.0732C42.5166 11.1406 42.5433 11.2051 42.5909 11.2528C42.6385 11.3004 42.7031 11.3271 42.7704 11.3271H46.4411C46.5084 11.3271 46.573 11.3004 46.6206 11.2528C46.6681 11.2051 46.6949 11.1406 46.6949 11.0732V10.5775C46.6938 10.2105 46.5874 9.85157 46.3883 9.54335C46.1892 9.23514 45.9058 8.99061 45.5718 8.83887Z'
              fill='#003865'
            />
            <path
              d='M44.6028 9.10227C44.7455 9.10032 44.8862 9.06847 45.0158 9.00878C45.1454 8.94908 45.2611 8.86287 45.3554 8.75569C45.5876 8.49342 45.7136 8.15389 45.7088 7.80355C45.7088 7.08691 45.2139 6.5061 44.6028 6.5061C43.9918 6.5061 43.4962 7.08691 43.4962 7.80355C43.4913 8.15402 43.6176 8.49365 43.8503 8.75569C43.9445 8.86293 44.0602 8.94918 44.1898 9.00888C44.3194 9.06859 44.4601 9.1004 44.6028 9.10227Z'
              fill='#003865'
            />
            <path
              d='M37.1396 8.83887C37.1326 8.84712 37.1262 8.85537 37.1193 8.86299C37.0058 9.00531 36.8617 9.12023 36.6978 9.19921C36.5338 9.2782 36.3542 9.31921 36.1722 9.31921C35.9903 9.31921 35.8106 9.2782 35.6467 9.19921C35.4827 9.12023 35.3387 9.00531 35.2252 8.86299L35.2056 8.83887C34.8724 8.99126 34.5899 9.23607 34.3916 9.54423C34.1934 9.8524 34.0876 10.211 34.0869 10.5775V11.0732C34.0869 11.1406 34.1137 11.2051 34.1613 11.2528C34.2088 11.3004 34.2734 11.3271 34.3407 11.3271H38.0114C38.0787 11.3271 38.1433 11.3004 38.1909 11.2528C38.2385 11.2051 38.2652 11.1406 38.2652 11.0732V10.5775C38.2639 10.2102 38.1571 9.85109 37.9575 9.54285C37.758 9.2346 37.474 8.99024 37.1396 8.83887Z'
              fill='#003865'
            />
            <path
              d='M36.1731 9.10227C36.3158 9.10032 36.4565 9.06847 36.5861 9.00878C36.7157 8.94908 36.8314 8.86287 36.9257 8.75569C37.1579 8.49342 37.2839 8.15389 37.2791 7.80355C37.2791 7.08691 36.7842 6.5061 36.1731 6.5061C35.5621 6.5061 35.0665 7.08691 35.0665 7.80355C35.0616 8.15402 35.1879 8.49365 35.4206 8.75569C35.5148 8.86293 35.6305 8.94918 35.7601 9.00888C35.8897 9.06859 36.0304 9.1004 36.1731 9.10227Z'
              fill='#003865'
            />
            <path
              d='M41.5935 7.63672C41.5859 7.64624 41.5782 7.6564 41.57 7.66592C41.4373 7.83221 41.2688 7.96647 41.0771 8.05874C40.8855 8.15102 40.6755 8.19893 40.4628 8.19893C40.25 8.19893 40.0401 8.15102 39.8484 8.05874C39.6567 7.96647 39.4883 7.83221 39.3555 7.66592C39.3473 7.6564 39.3397 7.64624 39.3321 7.63672C38.9422 7.81448 38.6115 8.10028 38.3791 8.46028C38.1466 8.82028 38.0222 9.23938 38.0205 9.66795V10.2475C38.0205 10.3261 38.0517 10.4015 38.1073 10.4571C38.1629 10.5127 38.2382 10.5439 38.3168 10.5439H42.6087C42.6872 10.5438 42.7624 10.5124 42.8178 10.4569C42.8732 10.4013 42.9044 10.326 42.9044 10.2475V9.66795C42.9028 9.23944 42.7785 8.82035 42.5462 8.46034C42.3138 8.10034 41.9832 7.81451 41.5935 7.63672Z'
              fill='#003865'
            />
            <path
              d='M40.4619 7.94514C40.6286 7.94288 40.793 7.90567 40.9445 7.83592C41.096 7.76616 41.2312 7.66541 41.3413 7.54016C41.6129 7.23423 41.7605 6.83783 41.755 6.4287C41.755 5.59081 41.1764 4.91162 40.4619 4.91162C39.7474 4.91162 39.1681 5.59081 39.1681 6.4287C39.1627 6.83783 39.3102 7.23423 39.5818 7.54016C39.6921 7.66546 39.8273 7.76623 39.9789 7.83599C40.1305 7.90574 40.295 7.94293 40.4619 7.94514Z'
              fill='#003865'
            />
          </svg>
        </div>
        <div>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default Loader;
