import Cookies from 'js-cookie';

const SetCookie = (cookieName, usrin) => {
  Cookies.set(cookieName, usrin, {
    expires: 365,
    sameSite: 'strict',
    path: '/',
  });
};
export default SetCookie;
