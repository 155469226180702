export const searchType = [
  { value: 'vacancies', label: 'Вакансии' },
  { value: 'resumes', label: 'Резюме' },
];
export const cities = [
  { value: 'Айдаркен', label: 'Айдаркен' },
  { value: 'Базаркоргон', label: 'Базаркоргон' },
  { value: 'Балыкчы', label: 'Балыкчы' },
  { value: 'Баткен', label: 'Баткен' },
  { value: 'Бишкек', label: 'Бишкек' },
  { value: 'Джалал-Абад', label: 'Джалал-Абад' },
  { value: 'Кадамжай', label: 'Кадамжай' },
  { value: 'Каинды', label: 'Каинды' },
  { value: 'Кант', label: 'Кант' },
  { value: 'Кара-Балта', label: 'Кара-Балта' },
  { value: 'Каракол', label: 'Каракол' },
  { value: 'Кара-Куль', label: 'Кара-Куль' },
  { value: 'Кара-Суу', label: 'Кара-Суу' },
  { value: 'Кемин', label: 'Кемин' },
  { value: 'Кербен', label: 'Кербен' },
  { value: 'Кок-Джангак', label: 'Кок-Джангак' },
  { value: 'Кочкор-Ата', label: 'Кочкор-Ата' },
  { value: 'Кызыл-Кия', label: 'Кызыл-Кия' },
  { value: 'Майлуу-Суу', label: 'Майлуу-Суу' },
  { value: 'Нарын', label: 'Нарын' },
  { value: 'Ноокат', label: 'Ноокат' },
  { value: 'Орловка', label: 'Орловка' },
  { value: 'Ош', label: 'Ош' },
  { value: 'Раззаков', label: 'Раззаков' },
  { value: 'Сулюкта', label: 'Сулюкта' },
  { value: 'Талас', label: 'Талас' },
  { value: 'Таш-Кумыр', label: 'Таш-Кумыр' },
  { value: 'Токмок', label: 'Токмок' },
  { value: 'Токтогул', label: 'Токтогул' },
  { value: 'Узген', label: 'Узген' },
  { value: 'Чолпон-Ата', label: 'Чолпон-Ата' },
  { value: 'Шопоков', label: 'Шопоков' },
];
export const salaryOptions = [
  { value: '10000', label: '10 000' },
  { value: '15000', label: '15 000' },
  { value: '25000', label: '25 000' },
  { value: '30000', label: '30 000' },
  { value: '50000', label: '50 000' },
  { value: '60000', label: '60 000' },
  { value: '80000', label: '80 000' },
  { value: '100000', label: '100 000' },
  { value: '130000', label: '130 000' },
  { value: '150000', label: '150 000' },
];
export const educationType = [
  { value: 'Высшее', label: 'Высшее' },
  { value: 'Неоконченное высшее', label: 'Неоконченное высшее' },
  { value: 'Среднее', label: 'Среднее' },
  { value: 'Cредне-специальное', label: 'Cредне-специальное' },
];
export const socGroupsType = [
  { value: 'Пенсионерам', label: 'Пенсионер' },
  { value: 'Студентам', label: 'Студент' },
  { value: 'Для людей с инвалидностью', label: 'Инвалид' },
];

export const timeVacancies = [
  { value: '1', label: 'за последний час' },
  { value: '24', label: 'за последний день' },
  { value: '168', label: 'за последнюю неделю' },
  { value: '720', label: 'за последний месяц' },
  { value: '1000000', label: 'больше месяца' },
];

export const genderOptions = [
  { value: 'мужчина', label: 'Мужcкой' },
  { value: 'женщина', label: 'Женский' },
];
export const expOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: 'более 5', label: 'более 5' },
];
export const periodFrom = [
  { value: '24', label: 'День' },
  { value: '168', label: 'Неделя' },
  { value: '720', label: 'Месяц' },
];

export const scheduleOptions = [
  { value: 'Полный день', label: 'Полный день' },
  { value: 'Посменно', label: 'Посменно' },
  { value: 'Удаленно', label: 'Удаленно' },
  { value: 'Свободный график', label: 'Свободный график' },
];
