import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ApiFetch from "../../api/fetchApi";

export const getInfoAdminProfile = createAsyncThunk(
  "adminprofile/getInfoAdminProfile",
  async () => {
    try {
      const res = await ApiFetch({
        url: "me-info/applicant/",
      });
      return res;
    } catch (e) {
      return {};
    }
  }
);
export const getAllStaff = createAsyncThunk(
  "adminprofile/getAllStaff",
  async () => {
    try {
      const res = await ApiFetch({
        url: "myadmin/personal-list/",
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const getAllManager = createAsyncThunk(
  "adminprofile/getAllManager",
  async () => {
    try {
      const res = await ApiFetch({
        url: "myadmin/manager-custom-list/",
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const deleteStaff = createAsyncThunk(
  "adminprofile/deleteStaff",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: "myadmin/personal-delete/",
        method: "POST",
        body: { user: id },
      });
      return res;
    } catch (e) {
      return e;
    }
  }
);
export const updateManager = createAsyncThunk(
  "adminprofile/updateManager",
  async (props) => {
    try {
      const res = await ApiFetch({
        url: "myadmin/personal-update/",
        method: "POST",
        body: props,
      });
      return res;
    } catch (e) {
      return e;
    }
  }
);
export const clearPassword = createAsyncThunk(
  "adminprofile/clearPassword",
  async (props) => {
    try {
      const res = await ApiFetch({
        url: "myadmin/down-pwd-user/",
        method: "POST",
        body: { user: props.user },
      });
      return { message: "success", res };
    } catch (e) {
      return { message: "error" };
    }
  }
);
export const addStaff = createAsyncThunk(
  "adminprofile/addStaff",
  async (props) => {
    try {
      const res = await ApiFetch({
        url: "user/register/",
        method: "POST",
        body: props,
      });
      return res;
    } catch (e) {
      return e;
    }
  }
);
const admininfoGet = createSlice({
  name: "adminprofile",
  initialState: {
    data: {},
    status: false,
    allstaff: [],
    allmanager: null,
    password: null,
    passwordData: null,
    message: "",
  },
  extraReducers: {
    [getAllManager.fulfilled]: (state, { payload }) => {
      console.log(payload, "manager");
      state.allmanager = payload;
    },
    [clearPassword.fulfilled]: (state, { payload }) => {
      state.password = "success";
      state.passwordData = payload;
    },
    [clearPassword.rejected]: (state) => {
      state.password = "error";
    },
    [getAllStaff.fulfilled]: (state, { payload }) => {
      state.allstaff = payload;
    },
    [getAllStaff.rejected]: (state) => {
      state.allstaff = [];
    },
    [addStaff.fulfilled]: (state, { payload }) => {
      state.status = !state.status;
      state.message = payload;
    },
    [addStaff.rejected]: (state, { payload }) => {
      state.status = !state.status;
      state.message = payload;
    },
    [deleteStaff.fulfilled]: (state) => {
      state.status = !state.status;
    },
    [deleteStaff.rejected]: (state) => {
      state.status = !state.status;
    },
    [updateManager.fulfilled]: (state) => {
      state.status = !state.status;
    },
    [updateManager.rejected]: (state) => {
      state.status = !state.status;
    },
    [getInfoAdminProfile.fulfilled]: (state, { payload }) => {
      state.data = payload;
    },
    [getInfoAdminProfile.rejected]: (state) => {
      state.data = {};
    },
  },
});
export default admininfoGet;
