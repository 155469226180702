import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MetrixVerify = () => {
  let location = useLocation();

  useEffect(() => {
    window.ym(91988527, 'hit', window.location.href);
  }, [location]);

  return;
};

export default MetrixVerify;
