import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
// import {
//   setCategoryDataList,
//   setCategoryIsLoading,
// } from '../features/categoryDataListSlice';

export const categoryDataList = createApi({
  reducerPath: 'api/categoryDataList',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  endpoints: (build) => ({
    getCategoryCards: build.mutation({
      query(body) {
        let { data, url } = body;
        return {
          url: `category/${url}/`,
          method: 'POST',
          body: data,
        };
      },
      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   try {
      //     dispatch(setCategoryIsLoading(true));
      //     const { data } = await queryFulfilled;
      //     dispatch(setCategoryDataList(data));
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    }),
  }),
});

export const { useGetCategoryCardsMutation } = categoryDataList;
