import { HandySvg } from 'handy-svg';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../../assets/logoSvg.svg';
import { useTranslation } from 'react-i18next';
import AuthTemplate from '../../components/AuthTemplate/AuthTemplate';
import AuthLogged from '../../components/AuthLogged/AuthLogged';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useContext } from 'react';
import { ContentContext } from '../../utils/ContentContext';
import ReactInputMask from 'react-input-mask';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useRef } from 'react';
import { useSendCallRequestMutation } from '../../redux/api/mockApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useScrollDirection } from '../../hooks/useScrollDirection';

function PreHeader() {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userState);
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const { postShow, setPostShow } = useContext(ContentContext);

  const scrollDirection = useScrollDirection();

  const [sendCallRequest, { isLoading, isError, isSuccess, error }] =
    useSendCallRequestMutation();

  const postRef = useRef();
  useOnClickOutside(postRef, () => setPostShow(false));

  const onSubmit = async (e) => {
    e.preventDefault();
    let phone = e.target[0].value;
    await sendCallRequest({ phone });
  };

  useEffect(() => {
    let fetchRes = 'callRequest';
    if (isLoading) {
      toast('Отправка...', {
        toastId: fetchRes,
        type: 'loading',
        isLoading,
      });
    }
    if (isSuccess) {
      toast.update(fetchRes, {
        render: 'Заявка успешно отправлена',
        type: 'success',
        autoClose: 2000,
        isLoading,
      });
      setTimeout(() => setPostShow(false), 3000);
    }
    if (isError) {
      toast.update(fetchRes, {
        render: error?.data?.message || 'Что-то пошло не так',
        type: 'error',
        autoClose: 2000,
        isLoading,
      });
    }
  }, [isLoading, isError, isSuccess]);

  return (
    <div
      className={`preHeader ${user === null && width <= 560 ? 'noreg' : ''}`}>
      <div
        className='preHeader__fixed'
        style={
          scrollDirection === 'down' && width <= 560
            ? { top: '-124px' }
            : { top: '0' }
        }>
        <div className='preHeader__inner container'>
          <div className='preHeader__logo'>
            <h1>
              <Link to='/'>
                <HandySvg src={logo} width='60' height='30' />
                <span>GROUP</span>
              </Link>
            </h1>
          </div>
          {width >= 940 && (
            <div className='preHeader__nav'>
              <NavLink
                className={
                  pathname.includes('vacancies' || 'vacancy') ? 'active' : ''
                }
                to='/'
                exact='true'>
                {t('PreHeader.nav.vacancies')}
              </NavLink>
              <NavLink
                className={pathname.includes('resume') ? 'active' : ''}
                to='/resumes'
                exact='true'>
                {t('PreHeader.nav.resumes')}
              </NavLink>
            </div>
          )}
          <div className='preHeader__right'>
            {width > 490 && (
              <div className='preHeader__auth'>
                {user === null && <AuthTemplate />}
                {user !== null && <AuthLogged />}
              </div>
            )}
            {postShow && (
              <div className='preHeader__post-popup'>
                <div
                  ref={postRef}
                  className='preHeader__post-inner'
                  data-aos='fade-down'>
                  <h4>Заявка</h4>
                  <p>Введите номер телефона:</p>
                  <form onSubmit={onSubmit}>
                    <ReactInputMask
                      mask='+\9\96 999 999 999'
                      placeholder='+996 000 000 000'
                    />
                    <button type='submit'>Оставить заявку</button>
                  </form>
                </div>
              </div>
            )}
            {/* <LangSwitcher /> */}
          </div>
        </div>
        {width <= 490 && user === null && (
          <div className='preHeader__auth'>
            <AuthTemplate />
          </div>
        )}
      </div>
    </div>
  );
}

export default PreHeader;
