import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL, baseWS } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';
import { baseDOMAIN } from '../../configs/axiosConfig';

export const chatApi = createApi({
  reducerPath: 'api/chatApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (build) => ({
    getChatsList: build.query({
      query: () => ({
        url: `${baseURL}my-chats/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${GetCookie('access')}`,
          Origin: `https://${baseDOMAIN}`,
        },
      }),
    }),
  }),
});

export const { useGetChatsListQuery } = chatApi;
