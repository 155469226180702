import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronDown } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { useGetCategoriesQuery } from '../../../redux';

function VacancesList() {
  const [showList, setShowList] = useState(false);
  const [showListPop, setShowListPop] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const { data = [], isLoading } = useGetCategoriesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const handleGroup = () => {
    if (width >= 720) {
      setShowList(!showList);
    } else {
      setShowListPop(true);
    }
  };

  return (
    <div className={`vacancesList ${showList ? 'show' : ''}`}>
      <h3 className='vacancesList__title unSelect' onClick={handleGroup}>
        {t('CategoryList.vacancies')} <BsChevronDown />
      </h3>
      {width >= 720 && (
        <div className='vacancesList__group'>
          <ul>
            {!isLoading &&
              data?.map((category) => (
                <li key={category.slug}>
                  <Link to={`/categories/${category.slug}`}>
                    {category.specialization}
                  </Link>{' '}
                  <span>({category?.count_v})</span>
                </li>
              ))}
          </ul>
        </div>
      )}
      {showListPop && (
        <div className='handlePopup'>
          <MdOutlineClose
            size={25}
            className='handlePopup__close'
            fill='#fff'
            onClick={() => setShowListPop(false)}
          />
          <div className='handlePopup__content'>
            <div className='vacancesList__pop'>
              <h3 className='vacancesList__pop-title'>Вакансии по профессии</h3>
              <input
                placeholder='Поиск'
                className='vacancesList__pop-input'
                onChange={(e) => setSearchValue(e.target.value)}
                type='text'
              />
              <ul className='vacancesList__pop-options'>
                {!isLoading &&
                  data
                    ?.filter((el) => {
                      let value = searchValue.toLowerCase();
                      return (
                        el.specialization.toLowerCase().includes(value) ||
                        el.slug.toLowerCase().includes(value)
                      );
                    })
                    .map((category) => (
                      <li key={category.slug}>
                        <Link to={`/categories/${category.slug}`}>
                          {category.specialization}{' '}
                          <span>({category?.count_v})</span>
                        </Link>
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VacancesList;
