import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../api/fetchApi";

export const getPositions = createAsyncThunk(
  "positions/getPositions",
  async () => {
    try {
      const responce = await ApiFetch({
        url: "positions/",
      });
      return responce;
    } catch (e) {
      return []
    }
  }
);
const initialState = {
  positions: [],
  status: null,
};

const GetPositionsSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {},
  extraReducers: {
    [getPositions.fulfilled]: (state, actions) => {
      state.status = "success";
      state.positions = actions.payload;
    },
    [getPositions.rejected]: (state) => {
      state.status = "error";
    },
  },
});

export default GetPositionsSlice;
