import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { AuthContext } from '../../utils/AuthContext';

const Footer = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { user_status, user } = useSelector((state) => state.userState);
  const { setAuthType } = useContext(AuthContext);

  return (
    <footer className='footer'>
      <div className='footer__wrapper'>
        <ul className='footer__list'>
          <li>
            <h4>{t('Footer.list1.title')}</h4>
          </li>
          <li>
            <Link to='/vacancies-all'>{t('Footer.list1.link1')}</Link>
          </li>
          <li>
            <Link to='/companies'>{t('Footer.list1.link2')}</Link>
          </li>
          {width <= 492 && (
            <li>
              <Link to='/resumes-all'>{t('Footer.list2.link1')}</Link>
            </li>
          )}
        </ul>
        {width > 492 && (
          <ul className='footer__list'>
            <li>
              <h4>{t('Footer.list2.title')}</h4>
            </li>
            <li>
              <Link to='/resumes-all'>{t('Footer.list2.link1')}</Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  if (user_status !== 'entity') setAuthType('login');
                }}
                to={user_status === 'entity' && `/company/add-vacancy`}>
                {t('Footer.list2.link2')}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  if (user === null) setAuthType('login');
                }}
                to={
                  user !== null &&
                  (user_status === 'entity' ? `/company/` : '/me/')
                }>
                {t('Footer.list2.link3')}
              </Link>
            </li>
            <li>
              <Link to='/tariffs'>{t('Footer.list2.link4')}</Link>
            </li>
          </ul>
        )}
        <ul className='footer__list'>
          <li>
            <h4>{t('Footer.list3.title')}</h4>
          </li>
          <li>
            <Link to='/agreement'>{t('Footer.list3.link1')}</Link>
          </li>
          <li>
            <HashLink
              smooth
              className='footer__list-contact'
              to='/about#contacts'>
              {t('Footer.contacts')}
            </HashLink>
          </li>
        </ul>
      </div>
      <p className='footer__copyright'>
        «HRGROUP.KG» — работа в Кыргызстане, проект основан в 2022 году
        <br />
        <a href='https://www.instagram.com/asoikg/' target='_blank'>
          © ASOI studios, 2022 - {new Date().getFullYear()}
        </a>
      </p>
    </footer>
  );
};

export default Footer;
