import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";
import { getAllCompanies } from "./companiesAction";

export const addBalansAdmin = createAsyncThunk(
  "balansadmin/addBalansAdmin",
  async (props, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: "myadmin/transaction-plus/",
        method: "POST",
        body: {
          amount: +props.amount,
          wallet: +props.id,
          comment: props?.comment,
        },
      });
      dispatch(getAllCompanies());
      return res;
    } catch (e) {
      return [];
    }
  }
);

const balansAdmin = createSlice({
  name: "balansadmin",
  initialState: { balans: null },
  extraReducers: {},
});
export default balansAdmin;
