import GetCookie from '../../hooks/cookies/getCookie';

export const InitialUrl = 'https://hrgroup.kg/api/v1/';
// const InitialUrl = "http://ovz10.j91967227.me78p.vps.myjino.ru/api/v1/";
function ApiFetch(props) {
  const requestOptions = {
    method: props.method || 'GET',
    headers: GetCookie('access')
      ? {
          Authorization: `Bearer ${GetCookie('access')}`,
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip, deflate',
        }
      : { 'Content-Type': 'application/json' },
  };
  if (props.method !== 'GET' && props.body) {
    requestOptions.body = JSON.stringify(props.body);
  }
  const promise = new Promise((resolve, reject) => {
    fetch(InitialUrl + props.url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.message);
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
}

export default ApiFetch;

export function ImageUpload(props) {
  const requestOptions = {
    method: props.method || 'GET',
    headers: GetCookie('access') && {
      Authorization: `Bearer ${GetCookie('access')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  var formData = new FormData();
  formData.append(
    'image',
    props.body.body?.image,
    props?.body.body?.image?.name
  );
  formData.append('profile', props.body.body?.profile);
  if (props.method !== 'GET' && props.body) {
    requestOptions.body = JSON.stringify(formData);
  }
  const promise = new Promise((resolve, reject) => {
    fetch(InitialUrl + props.url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.message);
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
}
