import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { HandySvg } from 'handy-svg';
import { AuthContext } from '../utils/AuthContext';
import Loader from '../hoc/Loader/Loader';
import notFoundBoundary from '../assets/boundary/page_not_found.svg';

const NotFoundPage = ({ isLetAuth = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => setIsLoading(false), 3000);
  const { setAuthType } = useContext(AuthContext);

  const handleAuth = () => {
    setAuthType('login');
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='boundary' data-aos='zoom-in'>
            <div className='boundary__content'>
              <HandySvg src={notFoundBoundary} className='boundary__media' />
              <p className='boundary__text'>Страница не найдена</p>
              <Link className='boundary__home' to='/'>
                Вернуться на Главную
              </Link>
              {isLetAuth && (
                <button onClick={handleAuth} className='boundary__login'>
                  Пожалуйста, войдите в свой аккаунт, чтобы просмотреть данную
                  страницу
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default NotFoundPage;
