import CardSkeleton from '../Card/CardSkeleton';

const CardsSkeletonList = ({ count = 10, isVacancy }) => {
  return (
    <div className='cards'>
      {[...Array(count).fill()].map((x, i) => (
        <CardSkeleton key={i} company={isVacancy} />
      ))}
    </div>
  );
};

export default CardsSkeletonList;
