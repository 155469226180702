import { forwardRef, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { BiErrorAlt, BiNoSignal } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';

const LoadingChat = () => {
  return (
    <div className='messages-content'>
      <div className='messages__loading'>
        <span className='messages__loading-part'></span>
        <span className='messages__loading-part'></span>
        <span className='messages__loading-part'></span>
      </div>
    </div>
  );
};

const ErrorChat = forwardRef(({ isError, handleClose }, ref) => {
  return (
    <div ref={ref} className='messages__toast messages__toast--error'>
      <div
        onClick={() => handleClose('error')}
        className='messages__toast-close'>
        <GrClose />
      </div>
      <div className='messages__toast-icon messages__toast-icon'>
        <BiErrorAlt size={24} color='#d71f4b' />
      </div>
      <h3 className='messages__toast-title messages__toast-title'>Ошибка</h3>
      <p className='messages__toast-text'>
        {isError?.error ||
          'Что-то пошло не так. Пожалуйста, попробуйте переподключиться или снова войдите в аккаунт '}
      </p>
    </div>
  );
});

const ConnectionChat = forwardRef(({ handleReconnect, handleClose }, ref) => {
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    handleReconnect();
  };

  setTimeout(() => setIsShow(true), 1500);

  return (
    isShow && (
      <div ref={ref} className='messages__toast messages__toast--connect'>
        <div
          onClick={() => handleClose('connected')}
          className='messages__toast-close'>
          <GrClose />
        </div>
        <div className='messages__toast-icon messages__toast-icon'>
          <BiNoSignal size={22} color='#ffb310' />
        </div>
        <h3 className='messages__toast-title messages__toast-title'>
          Нет соединения
        </h3>
        <p className='messages__toast-text'>
          Проверьте ваше интернет-подключение и перезагрузите чат
        </p>
        <button className='messages__toast-btn' onClick={handleClick}>
          {loading ? (
            <div class='messages__toast-btn-container'>
              <div class='messages__toast-btn-loading'></div>
            </div>
          ) : null}{' '}
          Переподключиться
        </button>
      </div>
    )
  );
});

const DeleteMessageAlert = forwardRef(
  ({ onDeleteMessage, setDeleteAlert }, ref) => {
    const handleClick = () => {
      onDeleteMessage();
      setDeleteAlert();
    };
    return (
      <div ref={ref} className='messages__toast'>
        <div
          onClick={() => setDeleteAlert(null)}
          className='messages__toast-close'>
          <GrClose />
        </div>
        <div className='messages__toast-icon messages__toast-icon'>
          <AiOutlineWarning size={22} color='#ffb310' />
        </div>
        <h3 className='messages__toast-title messages__toast-title'>
          Вы точно хотите удалить сообщение
        </h3>
        <div className='messages__toast-btns'>
          <button className='messages__toast-btn' onClick={handleClick}>
            Да
          </button>
          <button
            className='messages__toast-btn messages__toast-btn--not'
            onClick={() => setDeleteAlert(null)}>
            Нет
          </button>
        </div>
      </div>
    );
  }
);

export { LoadingChat, ErrorChat, ConnectionChat, DeleteMessageAlert };
