import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store } from './redux';
import { persistStore } from 'redux-persist';
import { AuthContextProvider } from './utils/AuthContext';
import { ContentContextProvider } from './utils/ContentContext';
import { Suspense } from 'react';
import Loader from './hoc/Loader/Loader';
import { ChatContextProvider } from './utils/ChatContext';

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ContentContextProvider>
      <ChatContextProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <PersistGate loading={null} persistor={persistor}>
              <Suspense fallback={<Loader />}>
                <App />
              </Suspense>
            </PersistGate>
          </AuthContextProvider>
        </BrowserRouter>
      </ChatContextProvider>
    </ContentContextProvider>
  </Provider>
);
