import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import SeoHelmet from '../../hoc/SeoHelmet';
import ResumeBottom from './ResumeBottom/ResumeBottom';
import ResumesList from './ResumesList/ResumesList';
import Aside from '../../components/Aside/Aside';

function ResumesPage() {
  const { width } = useWindowDimensions();

  return (
    <>
      <SeoHelmet
        title='Главная - резюме'
        innerTitle='Главная'
        description='На странице базы резюме hrgroup.kg вы найдете широкий выбор квалифицированных кандидатов, подходящих для разных должностей в вашей компании. Используйте нашу удобную систему поиска, чтобы быстро и легко находить подходящие резюме, которые соответствуют вашим требованиям.'
        location={window.location.href}
      />
      <ResumesList />
      <div className='contentContent'>
        {/* <h3 className='contentContent__title'>Топ Резюме</h3> */}
        <div className='contentContent__wrapper'>
          {width >= 860 && <Aside />}
          <div className='contentContent__main'>
            {/* <ResumeTop /> */}
            <ResumeBottom />
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumesPage;
