// import VendorRoute from './vendor/VendorRoute';
// import CompanyRoute from './company/CompanyRoute';
import { useEffect, lazy } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GetCookie from '../hooks/cookies/getCookie';
import { useDispatch, useSelector } from 'react-redux';
import UserRoute from './user/UserRoute';
import MetrixVerify from '../utils/ymMetriks/MetrixVerify';
import GoogleAnalytics from '../utils/googleAnalytics/GoogleAnalytics';
import { logout } from '../redux';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const MainRoute = lazy(() => import('./main/MainRoute'));
const VendorRoute = lazy(() => import('./vendor/VendorRoute'));
const CompanyRoute = lazy(() => import('./company/CompanyRoute'));

const AllRoute = () => {
  const { user, user_status } = useSelector((i) => i.userState);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  const isModeratorRole =
    user_status === 'main' ||
    user_status === 'manager' ||
    user_status === 'moderator';
  const navigate = useNavigate();

  const toPcToast = () => {
    if (JSON.parse(localStorage.getItem('toPcToast'))) {
      setTimeout(() => {
        toast.warning(
          'Зайдите с полной версии сайта(через компьютер) для полноценной работы',
          {
            autoClose: 5000,
          }
        );
        localStorage.setItem('toPcToast', false);
      }, 1500);
    }
  };

  useEffect(() => {
    if (
      user_status === 'entity' &&
      !user.profile.is_moderation &&
      !user.profile.is_active
    ) {
      toast.info('Ваш профиль проходит процесс модерации');
    }
    if (pathname == '/p' || pathname == '/p/') {
      navigate('/');
    }
  }, [user_status]);

  useEffect(() => {
    toPcToast();
    if ((user_status === 'entity' || isModeratorRole) && width <= 930) {
      localStorage.setItem('toPcToast', true);
      navigate('/');
      dispatch(logout());
    } else {
      localStorage.setItem('toPcToast', false);
    }
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path='/*' element={<UserRoute status={user_status} />} />
        {user_status === 'applicant' && (
          <Route path='/me/*' element={<VendorRoute />} />
        )}
        {user_status === 'entity' && (
          <Route path='/company/*' element={<CompanyRoute />} />
        )}
        {(GetCookie('status') === 'main' ||
          GetCookie('status') === 'manager' ||
          GetCookie('status') === 'moderator') && (
          <Route path='/main/*' element={<MainRoute />} />
        )}
      </Routes>
      <MetrixVerify />
      <GoogleAnalytics />
    </>
  );
};
export default AllRoute;
