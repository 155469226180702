import { Component } from 'react';
import { baseURL } from '../configs/axiosConfig';
import { ReactComponent as ErrorBoundaryMedia } from '../assets/boundary/sorry_error.svg';
import { ReactComponent as DisconnectMedia } from '../assets/boundary/disconnect_error.svg';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isServerAvailable: true };
  }

  static getDerivedStateFromError(error) {
    // Обновляем состояние, чтобы показать UI ошибки
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Здесь вы можете отправить отчет об ошибке в службу мониторинга ошибок
    console.log('Error:', error);
    console.log('Error Info:', errorInfo);
  }

  componentDidMount() {
    // Проверяем соединение с вашим сервером
    this.checkServerAvailability();
  }

  checkServerAvailability = async () => {
    try {
      // Выполняем запрос к вашему серверу
      const response = await fetch(baseURL);
      if (!response.ok) {
        throw new Error('Server not available');
      }
    } catch (error) {
      console.log('Server Availability Error:', error);
      this.setState({ isServerAvailable: false });
    }
  };

  handleRefresh() {
    window.location.reload();
  }

  styles = 'footer{display:none}';

  render() {
    if (!this.state.isServerAvailable) {
      return (
        <div className='boundary' data-aos='zoom-in'>
          <style>{this.styles}</style>
          <div className='boundary__content'>
            {/* <HandySvg src={disconnectMedia} className='boundary__media' /> */}
            <DisconnectMedia className='boundary__media' />
            <p className='boundary__text'>
              Отсутствует соединение с сервером или сервер недоступен <br />
              Проверьте подключение и повторите попытку позже.
            </p>
            <p className='boundary__home' onClick={this.handleRefresh}>
              Перезагрузить
            </p>
          </div>
        </div>
      );
    }

    if (this.state.hasError) {
      return (
        <div className='boundary' data-aos='zoom-in'>
          <div className='boundary__content'>
            {/* <HandySvg src={errorBoundaryMedia} className='boundary__media' /> */}
            <ErrorBoundaryMedia className='boundary__media' />
            <p className='boundary__text'>
              Извините, что-то пошло не так <br />
              Пожалуйста, свяжитесь с нами через онлайн мессенджер Jivo для
              получения помощи <br />(
              <i>Вы можете найти нас в правом нижнем углу экрана</i>)
            </p>
            <p className='boundary__home' onClick={this.handleRefresh}>
              Перезагрузить
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
