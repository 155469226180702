import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';
import SetCookie from '../../hooks/cookies/setCookie';
import { userApi } from './userApi';
import RemoveCookie from '../../hooks/cookies/removeCookie';
import emptyCache from '../../hooks/emptyCache';
import { toast } from 'react-toastify';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}`,
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query({ userData, redirectUrls }) {
        return {
          url: 'login/',
          method: 'POST',
          body: userData,
        };
      },
      async onQueryStarted({ redirectUrls }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const { data } = await queryFulfilled;
          if (data.user_status === 'entity' && window.innerWidth <= 930) {
            return toast.warning(
              'Зайдите с полной версии сайта(через компьютер) для полноценной работы',
              {
                autoClose: 10000,
              }
            );
          } else {
            SetCookie('refresh', data.refresh);
            SetCookie('access', data.access);
            SetCookie('status', data.user_status);
          }
          await dispatch(userApi.endpoints.getMe.initiate(data));
          if (data.user_status === 'applicant') {
            return await window.open(
              redirectUrls?.applicantUrl || '/me/',
              '_self'
            );
          }
          if (data.user_status === 'entity') {
            return await window.open(
              redirectUrls?.entityUrl || '/company/',
              '_self'
            );
          }
          if (
            data.user_status === 'main' ||
            data.user_status === 'manager' ||
            data.user_status === 'moderator'
          )
            return await window.open('/main', '_self');
        } catch (error) {
          console.log(error);
        }
      },
    }),
    registerUser: builder.mutation({
      query(body) {
        return {
          url: `user/register/`,
          body,
          method: 'POST',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const { data } = await queryFulfilled;
          const { detail } = data;
          if (data.user_status === 'entity' && window.innerWidth <= 930) {
            return toast.warning(
              'Зайдите с полной версии сайта(через компьютер) для полноценной работы',
              {
                autoClose: 10000,
              }
            );
          } else {
            SetCookie('refresh', data.refresh);
            SetCookie('access', data.access);
            SetCookie('status', data.user_status);
          }
          SetCookie('refresh', detail.refresh);
          SetCookie('access', detail.access);
          SetCookie('status', detail.user_status);
          await dispatch(userApi.endpoints.getMe.initiate(detail));
          if (detail.user_status === 'applicant') {
            return await window.open('/me/add-resume#personal-info', '_self');
          }
          if (detail.user_status === 'entity') {
            return await window.open('/company/settings#description', '_self');
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    verifyEmailSend: builder.mutation({
      query(body) {
        return {
          url: `user/check-mail/`,
          body,
          method: 'POST',
        };
      },
    }),
    verifyEmailCheck: builder.mutation({
      query(body) {
        return {
          url: `user/check-code/`,
          body,
          method: 'POST',
        };
      },
    }),
    refreshToken: builder.mutation({
      query(body) {
        return {
          url: `/token/refresh/`,
          body,
          method: 'POST',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const { data } = await queryFulfilled;
          SetCookie('access', data.access);
          await dispatch(userApi.endpoints.getMe.initiate(data));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    forgotPwdVerify: builder.mutation({
      query(body) {
        return {
          url: `/user/forgot-pwd-verify/`,
          body,
          method: 'POST',
        };
      },
    }),
    forgotPwdSubmit: builder.mutation({
      query(body) {
        return {
          url: `/user/forgot-pwd/`,
          body,
          method: 'POST',
        };
      },
    }),
    changePwd: builder.mutation({
      query(body) {
        return {
          headers: { authorization: `Bearer ${GetCookie('access')}` },
          url: `/user/change-pwd/`,
          body,
          method: 'POST',
        };
      },
    }),
    changeEmail: builder.mutation({
      query(body) {
        return {
          headers: { authorization: `Bearer ${GetCookie('access')}` },
          url: `user/change-email/`,
          body,
          method: 'POST',
        };
      },
    }),
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat({
      // Middleware для обработки ошибок авторизации
      onError: async (error, dispatch) => {
        // Если сервер вернул 401
        let currentTime = Date.now();
        let refresh = await GetCookie('refresh');
        if (
          error.status === 401 &&
          JSON.parse(atob(refresh.split('.')[1]))?.exp * 1000 < currentTime
        ) {
          // Обновляем токен
          await dispatch(authApi.endpoints.refreshToken.initiate({ refresh }));
          // Получаем новый токен из состояния
          const newToken = GetCookie('access');
          // Обновляем токен в запросе
          error.config.headers.Authorization = `Bearer ${newToken}`;
          // Повторяем запрос
          return dispatch(fetchBaseQuery(error.config));
        } else {
          toast.warning(
            'Повторите попытку входа в аккаунт, ваша сессия истекла'
          );
        }
        // Если не 401, просто передаем ошибку дальше
        throw error;
      },
    }),
});

// export const refreshAccessToken = async () => {
//   const response = await fetch('/token/refresh/', {
//     method: 'POST',
//     credentials: 'include',
//   });
//   const data = await response.json();
//   SetCookie('access', data.access);
//   return data.access;
// };

export const {
  useLoginUserMutation,
  useChangeEmailMutation,
  useRegisterUserMutation,
  useVerifyEmailCheckMutation,
  useVerifyEmailSendMutation,
  useRefreshTokenMutation,
  useChangePwdMutation,
  useForgotPwdSubmitMutation,
  useForgotPwdVerifyMutation,
} = authApi;
