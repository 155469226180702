import { HandySvg } from 'handy-svg';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoTrashOutline } from 'react-icons/io5';
import boxStar from '../../../assets/svg/boxStar.svg';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { ContentContext } from '../../../utils/ContentContext';
import { toast } from 'react-toastify';
import { useGetCategoriesQuery } from '../../../redux';
import { useSelector } from 'react-redux';

function CartSub({ setCartShow }) {
  const navigate = useNavigate();
  const outRef = useRef();
  const { user } = useSelector((s) => s.userState);
  const { cart: cartList, removeCart } = useContext(ContentContext);

  const { data: rubricsList } = useGetCategoriesQuery();

  useOnClickOutside(outRef, () => setCartShow(false));

  const handleRemove = (id) => {
    removeCart(id);
    toast.success('Удалено из корзины');
  };

  return (
    <div
      ref={outRef}
      data-aos='fade-left'
      className='preHeader__auth-cart'
      onMouseOut={() => setCartShow(false)}
      onMouseOver={() => setCartShow(true)}>
      {cartList.length > 0 ? (
        <>
          <div className='preHeader__auth-cart-title'>
            Корзина ({cartList.length})
          </div>
          <ul className='preHeader__auth-cart-list'>
            {cartList.map((el, idx) => (
              <li key={el.tariff + idx} className='preHeader__auth-cart-item'>
                <div className='preHeader__auth-cart-item-logo'>
                  <HandySvg src={boxStar} width='32' height='32' />
                </div>
                <div className='preHeader__auth-cart-item-text'>
                  <h4 className='preHeader__auth-cart-item-name'>{el.title}</h4>
                  <div className='preHeader__auth-cart-item-info'>
                    {el?.rubrics?.length == 1 && (
                      <p>
                        рубрика:{' '}
                        <span>
                          {
                            rubricsList?.find(
                              (item) => item.slug == el?.rubrics?.[0]
                            ).specialization
                          }
                        </span>
                      </p>
                    )}
                    {!!el?.count && (
                      <p>
                        шт: <span>{el.count}</span>
                      </p>
                    )}
                    {!!el?.info?.day && (
                      <p>
                        дней: <span>{el.info.day}</span>
                      </p>
                    )}
                    {!!el?.vacancy && (
                      <p>
                        вакансия:{' '}
                        <span>
                          {
                            user?.vacancies.find(
                              (item) => item.id == el.vacancy
                            ).position
                          }
                        </span>
                      </p>
                    )}
                  </div>
                  <p className='preHeader__auth-cart-item-price'>
                    {el?.info?.pbp_price !== null
                      ? +el?.info?.pbp_price * +el?.count
                      : +el?.info?.price}
                    сом
                  </p>
                  <div
                    className='preHeader__auth-cart-item-remove'
                    onClick={() => handleRemove(el.tariff)}>
                    <IoTrashOutline size={20} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <p className='preHeader__auth-cart-result'>
            <span>Итого:</span>{' '}
            <span>
              {cartList.reduce((acc, rec) => {
                if (rec?.info?.pbp_price !== null) {
                  return +acc + +rec?.info?.pbp_price * +rec?.count;
                }
                return +acc + +rec?.info?.price;
              }, 0)}{' '}
              сом
            </span>
          </p>
          <button
            className='preHeader__auth-cart-btn'
            onClick={() => navigate('/company/cart')}>
            К оплате
          </button>
        </>
      ) : (
        <div
          className='preHeader__auth-cart-title'
          style={{ textAlign: 'center' }}>
          Корзина пуста...
        </div>
      )}
    </div>
  );
}

export default CartSub;
