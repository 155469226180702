import { createSlice } from '@reduxjs/toolkit';
import RemoveCookie from '../../hooks/cookies/removeCookie';
import SetCookie from '../../hooks/cookies/setCookie';
import emptyCache from '../../hooks/emptyCache';

const initialState = {
  user: null,
  user_status: null,
  user_favorites: [],
  user_postings: [],
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => {
      RemoveCookie('access');
      RemoveCookie('refresh');
      RemoveCookie('status');
      RemoveCookie('logged');
      localStorage.removeItem('persist:root');
      emptyCache();
      return initialState;
    },
    setUser: (state, action) => {
      if (!!action.payload && !!action.payload.user_status) {
        state.user = action.payload;
        state.user_status = action.payload.user_status;
        SetCookie('logged', true);
      }
    },
    setUserStatus: (state, action) => {
      state.user_status = action.payload;
    },
    setPostingsAndFavorites: (state, action) => {
      let postings = action.payload?.vacancies || action.payload?.resumes;
      state.user_favorites = action.payload.favorites.map(
        (el) => el.vacancies || el.resume
      );
      state.user_postings = postings.reduce(
        (acc, rec) => [...acc, ...rec.postings].flat(),
        []
      );
    },
  },
});

export const { logout, setUser, setUserStatus, setPostingsAndFavorites } =
  userSlice.actions;
export default userSlice.reducer;
