import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import Skeleton from 'react-loading-skeleton';

function PartnersSliderSkeleton({ slideSliceLength }) {
  return (
    <>
      <h3 className='employers__title'>
        <Skeleton width={200} />
      </h3>
      <Swiper autoplay={{ delay: 15000 }} modules={[Autoplay]} loop='true'>
        {[...Array(2).fill()].map((x, i) => {
          return (
            <SwiperSlide key={i} className='employers__cards'>
              {[...Array(slideSliceLength).fill()].map((el, idx) => (
                <a href='#' key={idx} className='employers__cards-card'>
                  <Skeleton width={240} height={112} />
                </a>
              ))}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default PartnersSliderSkeleton;
