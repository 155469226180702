import { HandySvg } from 'handy-svg';
import { useContext, useState } from 'react';
import ManagerSub from './components/ManagerSub';
import CartSub from './components/CartSub';
//Media
import document from '../../assets/svg/document.svg';
import like from '../../assets/svg/like.svg';
import person from '../../assets/svg/person.svg';
import manager from '../../assets/svg/manager.svg';
import settings from '../../assets/svg/settings.svg';
import logoutIco from '../../assets/svg/logout.svg';
import { BsChevronUp } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {
  AiOutlineMessage,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/features/userSlice';
import { FaUser } from 'react-icons/fa';
import { ContentContext } from '../../utils/ContentContext';
import GetCookie from '../../hooks/cookies/getCookie';
import useChatsData from '../../hooks/useChatsData';

function MessageIcon() {
  const { chatsData } = useChatsData();
  const key =
    GetCookie('status') === 'entity' ? 'ent_msg_count' : 'app_msg_count';
  const newMsgs = chatsData.reduce((acc, rec) => {
    return !!rec[key] > 0 ? acc + 1 : acc;
  }, 0);

  return (
    <NavLink className='preHeader__auth-links' to='/chat'>
      {!!newMsgs ? (
        <p className='preHeader__auth-links--count'>
          {newMsgs > 9 ? '9+' : newMsgs}
        </p>
      ) : null}
      <AiOutlineMessage size={22} />
    </NavLink>
  );
}

function AuthLogged() {
  const { user_status } = useSelector((state) => state.userState);
  const { user } = useSelector((state) => state.userState);
  const dispatch = useDispatch();
  const [managerShow, setManagerShow] = useState(false);
  const [subShow, setSubShow] = useState(false);
  const { cartShow, setCartShow } = useContext(ContentContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      {user_status === 'applicant' && (
        <div className='preHeader__auth-logged'>
          <div className='preHeader__auth-profile'>
            {!user?.image && (
              <div className='preHeader__auth-profile-avatar'>
                <FaUser size='32' />
              </div>
            )}
            {user?.image && (
              <img
                className='preHeader__auth-profile-avatar ava'
                src={user?.image}
                width='100%'
                alt={user?.username}
              />
            )}
            <h2
              onMouseEnter={() => setSubShow(true)}
              onMouseLeave={() => setSubShow(false)}>
              Личный кабинет <BsChevronUp />
              {subShow && (
                <div
                  className='preHeader__auth-profile-sub preHeader__auth-profile-sub--applicant'
                  data-aos='fade-down'>
                  <Link to='/me/my-resumes'>
                    <HandySvg src={document} width='24' height='24' /> Мои
                    резюме
                  </Link>
                  <Link to='/me/my-responses'>
                    <HandySvg src={like} width='24' height='24' /> Мои отклики
                  </Link>
                  <Link to='/me/settings'>
                    <HandySvg src={settings} width='24' height='24' /> Настройки
                  </Link>
                  <hr />
                  <Link to='/' onClick={handleLogout}>
                    <HandySvg src={logoutIco} width='24' height='24' /> Выйти
                  </Link>
                </div>
              )}
            </h2>
          </div>
          <MessageIcon />
          <NavLink className='preHeader__auth-links' to='/fav'>
            <AiOutlineStar size={22} />
          </NavLink>
        </div>
      )}
      {user_status === 'entity' && (
        <div className='preHeader__auth-logged'>
          <div className='preHeader__auth-profile'>
            <h2
              onMouseEnter={() => setSubShow(true)}
              onMouseLeave={() => setSubShow(false)}>
              Личный кабинет <BsChevronUp />
              {subShow && (
                <div
                  className='preHeader__auth-profile-sub preHeader__auth-profile-sub--entity'
                  data-aos='fade-down'
                  data-aos-duration='300'>
                  <h4>{user?.profile?.company}</h4>
                  <strong>
                    <p>
                      <span>Логин: </span>
                      <span>{user?.username}</span>
                    </p>
                  </strong>
                  <strong>
                    <p>
                      <span>Баланс: </span>
                      <span>{user?.wallet?.amount} сом</span>
                    </p>
                  </strong>
                  <hr />
                  <Link to='/company/'>
                    <HandySvg src={person} width='24' height='24' /> Личный
                    кабинет
                  </Link>
                  {user?.profile?.is_moderation && (
                    <Link onClick={() => setManagerShow(true)}>
                      <HandySvg src={manager} width='24' height='24' />{' '}
                      Связаться с менеджером
                    </Link>
                  )}
                  <Link to='/' onClick={handleLogout}>
                    <HandySvg src={logoutIco} width='24' height='24' /> Выйти
                  </Link>
                </div>
              )}
              {managerShow && <ManagerSub setManagerShow={setManagerShow} />}
            </h2>
          </div>
          <MessageIcon />
          <i
            className='preHeader__auth-links'
            onClick={() => setCartShow(true)}>
            <AiOutlineShoppingCart size={22} />
            {cartShow && <CartSub setCartShow={setCartShow} />}
          </i>
        </div>
      )}
      {user_status === 'main' ||
      user_status === 'moderator' ||
      user_status === 'manager' ? (
        <button onClick={() => navigate('/main')}>Войти в админку</button>
      ) : (
        ''
      )}
    </>
  );
}

export default AuthLogged;
