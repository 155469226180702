import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";

export const adminGetResumes = createAsyncThunk(
  "resumesadmin/adminGetResumes",
  async (page) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/resumes/?page=${page || 1}`,
      });
      return response;
    } catch (e) {
      return [];
    }
  }
);
export const adminGetResumesById = createAsyncThunk(
  "resumesadmin/adminGetResumesById",
  async (id) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/resumes/${id}/`,
      });
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getModerationResumes = createAsyncThunk(
  "resumesadmin/getModerationResumes",
  async (page) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/resumes-moderation/?page=${page || 1}`,
      });
      return response;
    } catch (e) {
      return [];
    }
  }
);
export const getResumByIdModeration = createAsyncThunk(
  "resumesadmin/getResumByIdModeration",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/resumes-moderation/${id}/`,
      });
      return res;
    } catch (e) {
      return {};
    }
  }
);

export const editModerationResume = createAsyncThunk(
  "resumesadmin/editModerationResume",
  async (props, { dispatch }) => {
    delete props.body?.id;
    try {
      const response = await ApiFetch({
        url: `myadmin/resumes-moderation/${props.id}/`,
        method: "PUT",
        body: props.body,
      });
      dispatch(getModerationResumes());
      return response;
    } catch (e) {
      return e;
    }
  }
);
export const editModerationOldResume = createAsyncThunk(
  "resumesadmin/editModerationResume",
  async (props, { dispatch }) => {
    delete props.body?.id;
    try {
      const response = await ApiFetch({
        url: `resume/${props.id}/`,
        method: "PUT",
        body: props.body,
      });
      dispatch(adminGetResumes());
      return response;
    } catch (e) {
      return e;
    }
  }
);

export const getFilterResumes = createAsyncThunk(
  "resumesadmin/getFilterResumes",
  async (url) => {
    try {
      const res = await ApiFetch({ url: `myadmin/resumes/search/?${url}` });
      return res;
    } catch (e) {
      return [];
    }
  }
);
const allResumes = createSlice({
  name: "resumesadmin",
  initialState: {
    data: {},
    moderationdata: {},
    status: null,
    resumeById: [],
    doneResumeById: [],
  },
  extraReducers: {
    [adminGetResumesById.fulfilled]: (state, { payload }) => {
      state.doneResumeById = [payload];
    },
    [getResumByIdModeration.fulfilled]: (state, { payload }) => {
      state.resumeById = [payload];
    },
    [adminGetResumes.fulfilled]: (state, actions) => {
      state.data = actions.payload;
      state.status = "success";
    },
    [adminGetResumes.rejected]: (state) => {
      state.status = "error";
    },
    [getModerationResumes.fulfilled]: (state, actions) => {
      state.moderationdata = actions.payload;
      state.status = "success";
    },
    [getModerationResumes.rejected]: (state) => {
      state.status = "error";
    },
    [getFilterResumes.fulfilled]: (state, actions) => {
      state.data = actions.payload;
      state.status = "success";
    },
  },
});
export default allResumes;
