import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: {},
};
export const employerSettingsSlice = createSlice({
  initialState,
  name: 'settingsSlice',
  reducers: {
    addSettingsState: (state, action) => {
      state.settings = {
        ...state.settings,
        [action.payload.name]: action.payload.value,
      };
    },
    setSettingsState: (state, action) => {
      state.settings = action.payload || {};
    },
  },
});

export default employerSettingsSlice.reducer;

export const { addSettingsState, setSettingsState } =
  employerSettingsSlice.actions;
