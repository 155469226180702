import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';

export const vipResumesApi = createApi({
  reducerPath: 'api/vipResumes',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    getVipResumes: build.query({
      query: () => `resumes/vip`,
    }),
  }),
});

export const { useGetVipResumesQuery } = vipResumesApi;
