import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';
import { userApi } from './userApi';

export const vacanciesApi = createApi({
  reducerPath: 'api/vacancy',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  tagTypes: ['Vacancy'],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    getVacancies: build.query({
      query: ({ page = 1, pageSize }) => {
        return `vacancies/?page=${
          typeof page != 'object' ? page : '1'
        }&page_size=${pageSize || 40}`;
      },
      providesTags: ['Vacancy'],
    }),
    getVacancyByIdToPrivate: build.query({
      query: (id) => `vacancy-one/${id}/`,
    }),
    setPostings: build.mutation({
      query(data) {
        const { vacancy, resume, text } = data;
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: '/postings/create/',
          method: 'POST',
          body: {
            vacancy,
            resume,
            text,
          },
        };
      },
    }),
    vacancyUpTime: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/vacancy-uptime/`,
          method: 'POST',
          body: body,
        };
      },
    }),
    addVacancyFetch: build.mutation({
      query(data) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: '/vacancy/',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    changeArchiveStatusVacancy: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/vacancy-archive/`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    editVacancyFetch: build.mutation({
      query(body) {
        let { data, id } = body;
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/vacancy/${id}/`,
          method: 'PUT',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    deleteVacancyFetch: build.mutation({
      query(id) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/vacancy/${id}/`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    addFavoriteVacancy: build.mutation({
      query(body) {
        const { isType, id, type } = body;
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/favorite/${type}/`,
          body: { [isType]: id },
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useVacancyUpTimeMutation,
  useGetVacanciesQuery,
  useGetVacancyByIdToPrivateQuery,
  useAddVacancyFetchMutation,
  useEditVacancyFetchMutation,
  useDeleteVacancyFetchMutation,
  useAddFavoriteVacancyMutation,
  useChangeArchiveStatusVacancyMutation,
  useSetPostingsMutation,
} = vacanciesApi;
