import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CardsList from '../../components/CardsList/CardsList';
import { goToTop } from '../../hooks/goToTopScroll';
import { useGetCategoryCardsMutation } from '../../redux';
import VacancesSubList from '../Home/VacancesSubList/VacancesSubList';
import ResumesSubList from '../Resumes/ResumesSubList/ResumesSubList';
import CardsSkeletonList from '../../components/CardsList/CardsSkeletonList';

function SearchByCategory({ filter }) {
  useEffect(() => goToTop(), []);

  const { pathname, hash } = useLocation();

  let currentCategory = hash.length
    ? hash.slice(1)
    : pathname.slice(pathname.lastIndexOf('/') + 1);

  const [getCategoryCards, { data = [], isLoading, isFetching }] =
    useGetCategoryCardsMutation();

  useEffect(() => {
    let body = { data: { spec_slug: currentCategory }, url: filter };
    getCategoryCards(body);
  }, [currentCategory]);

  return (
    <>
      {filter === 'vacancies' && <VacancesSubList />}
      {filter === 'resumes' && <ResumesSubList />}
      {(isLoading || isFetching) && (
        <CardsSkeletonList isVacancy={filter === 'vacancies' || false} />
      )}
      {!isLoading && data.length ? (
        <CardsList cards={data} />
      ) : (
        <p className='favourites__text'>Пусто</p>
      )}
    </>
  );
}

export default SearchByCategory;
