// import { useCallback, useRef } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
import { useGetVipVacanciesQuery } from '../../../redux';
// import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
// import Card from '../../../components/Card/Card';
// import { sliceArrayHook } from '../../../hooks/sliceArrayHook';
// import { Autoplay, Virtual } from 'swiper';
import CardsList from '../../../components/CardsList/CardsList';
import CardsSkeletonList from '../../../components/CardsList/CardsSkeletonList';

function VacanciesTop() {
  // const contentCards = useRef(null);
  const { data = [], isLoading } = useGetVipVacanciesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  // const handlePrev = useCallback(() => {
  //   if (!contentCards.current) return;
  //   contentCards.current.swiper.slidePrev();
  // }, []);

  // const handleNext = useCallback(() => {
  //   if (!contentCards.current) return;
  //   contentCards.current.swiper.slideNext();
  // }, []);

  return (
    <div className='contentContent__top-cards'>
      {!isLoading && data.length ? (
        // <Swiper
        //   // autoplay={{ delay: 10000 }}
        //   // modules={[Autoplay, Virtual]}
        //   // virtual
        //   loop
        //   // autoHeight={true}
        //   ref={contentCards}>
        //   {[...Array(Math.ceil(data?.length / 5)).fill()].map((x, i) => {
        //     return (
        //       <SwiperSlide key={`key_${i}`} virtualIndex={i}>
        //         {sliceArrayHook(
        //           data?.map((card) => <Card key={card.id} data={card} />),
        //           5,
        //           i + 1
        //         )}
        //       </SwiperSlide>
        //     );
        //   })}
        // </Swiper>
        <CardsList cards={data} />
      ) : null}
      {isLoading ? (
        // <Swiper
        //   autoplay={{ delay: 10000 }}
        //   modules={[Autoplay]}
        //   autoHeight={true}
        //   ref={contentCards}>
        //   <SwiperSlide>
        //     {[...Array(3).fill()].map((x, i) => (
        //       <CardSkeleton key={i} company={true} vip={true} />
        //     ))}
        //   </SwiperSlide>
        // </Swiper>
        <CardsSkeletonList count={2} isVacancy={true} />
      ) : null}
      {/* {!isLoading && !data.length && <p className='favourites__text'>Пусто</p>} */}
      {/* <div className='contentContent__nav'>
        <div className='contentContent__nav-prevBtn' onClick={handlePrev}>
          <RiArrowLeftSLine width={24} height={24} />
        </div>
        <div className='contentContent__nav-nextBtn' onClick={handleNext}>
          <RiArrowRightSLine width={24} height={24} />
        </div>
      </div> */}
    </div>
  );
}

export default VacanciesTop;
