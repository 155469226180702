import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryDataList: null,
};

export const categoryDataListSlice = createSlice({
  initialState,
  name: 'categoryDataListSlice',
  reducers: {
    setCategoryDataList: (state, action) => {
      state.categoryDataList = action.payload;
    },
    setCategoryIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export default categoryDataListSlice.reducer;

export const { setCategoryDataList, setCategoryIsLoading } =
  categoryDataListSlice.actions;
