import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//Imports api, slices
import { authApi } from './api/authApi';
import { resumesApi } from './api/resumes';
import { vipResumesApi } from './api/vipResumes';
import { userApi } from './api/userApi';
import { categoryApi } from './api/category';
import { categoryDataList } from './api/categoryDataList';
import { vacanciesApi } from './api/vacancies';
import { vipVacanciesApi } from './api/vipVacancies';
import { employerProfileApi } from './api/employerSettings';
import { userSlice } from './features/userSlice';
import { categoryDataListSlice } from './features/categoryDataListSlice';
import { addResumeSlice } from './features/addResumeSlice';
import { employerSettingsSlice } from './features/employerSettingsSlice';
import { vacancySlice } from './features/vacancySlice';
import { searchApi } from './api/searchApi';
import { searchParamsSlice } from './features/searchParamsSlice';
import { replenishApi } from './api/replenish';
import { mockApi } from './api/mockApi';
import GetPositionsSlice from './features/addpositions';
import WorkPageSlice from './features/ActionAdmin/WorkAction';
import companiesSlice from './features/ActionAdmin/companiesAction';
import analysisCompanySlice from './features/ActionAdmin/analysisCompany';
import AllVacanciesSlice from './features/ActionAdmin/allVacancies';
import AllBanersSlice from './features/ActionAdmin/allTerms';
import allResumes from './features/ActionAdmin/allResumes';
import AllBannersSlice from './features/ActionAdmin/allBaners';
import EmployerSlice from './features/ActionAdmin/EmployerSlice';
import getTarif from './features/ActionAdmin/getTarif';
import balansAdmin from './features/ActionAdmin/balansAdmin';
import admininfoGet from './features/ActionAdmin/adminInfoGet';
import phoneAdminSlice from './features/ActionAdmin/phoneAdminSlice';

// WS
import { chatApi } from './ws/chatApi';

const rootReducer = combineReducers({
  [vacanciesApi.reducerPath]: vacanciesApi.reducer,
  [vipVacanciesApi.reducerPath]: vipVacanciesApi.reducer,
  [resumesApi.reducerPath]: resumesApi.reducer,
  [vipResumesApi.reducerPath]: vipResumesApi.reducer,
  [mockApi.reducerPath]: mockApi.reducer,
  [employerProfileApi.reducerPath]: employerProfileApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [categoryDataList.reducerPath]: categoryDataList.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [replenishApi.reducerPath]: replenishApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  userState: userSlice.reducer,
  currentCategoryData: categoryDataListSlice.reducer,
  addResumeState: addResumeSlice.reducer,
  searchParamsState: searchParamsSlice.reducer,
  vacancyState: vacancySlice.reducer,
  settingsState: employerSettingsSlice.reducer,
  positions: GetPositionsSlice.reducer,
  workpage: WorkPageSlice.reducer,
  analysisconpany: analysisCompanySlice.reducer,
  main_vacancies: AllVacanciesSlice.reducer,
  main_banners: AllBanersSlice.reducer,
  adminResumes: allResumes.reducer,
  main_banner: AllBannersSlice.reducer,
  adminEmployers: EmployerSlice.reducer,
  tarifAdminAdd: getTarif.reducer,
  addBalansCompany: balansAdmin.reducer,
  adminProfile: admininfoGet.reducer,
  companies: companiesSlice.reducer,
  phoneAdmin: phoneAdminSlice.reducer,
});

const persistConfig = {
  timeout: 500,
  key: 'root',
  version: 1.1,
  storage,
  blacklist: [
    'api/vacancy',
    'api/vipVacancy',
    'api/resumes',
    'api/vipResumes',
    'api/mockApi',
    'api/search',
    'api/employerProfile',
    'api/category',
    'api/categoryDataList',
    'api/replenishApi',
    'api/chatApi',
    'currentCategoryData',
    'companies',
    'workpage',
    'settingsState',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      vacanciesApi.middleware,
      vipVacanciesApi.middleware,
      resumesApi.middleware,
      vipResumesApi.middleware,
      categoryApi.middleware,
      mockApi.middleware,
      categoryDataList.middleware,
      authApi.middleware,
      employerProfileApi.middleware,
      userApi.middleware,
      searchApi.middleware,
      replenishApi.middleware,
      chatApi.middleware
    ),
});
