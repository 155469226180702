import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vacancyData: {},
};
export const vacancySlice = createSlice({
  initialState,
  name: 'vacancyDateSlice',
  reducers: {
    addParamOfVacancyState: (state, action) => {
      state.vacancyData = {
        ...state.vacancyData,
        [action.payload.name]: action.payload.value,
      };
    },
    setParamOfVacancyState: (state, action) => {
      state.vacancyData = action.payload;
    },
    resetParamOfVacancyState: (state) => {
      state.vacancyData = {};
    },
  },
});

export default vacancySlice.reducer;

export const {
  addParamOfVacancyState,
  resetParamOfVacancyState,
  setParamOfVacancyState,
} = vacancySlice.actions;
