import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";

export const getAllBaners = createAsyncThunk(
  "main_banners/getAllBaners",
  async () => {
    try {

      const responce = await ApiFetch({
        url: `myadmin/terms-list/`,
      });

      return responce;
    } catch (error) {
      return [];
    }
  }
);

export const putAllCroki = createAsyncThunk(
  "main_banners/putAllCroki",
  async (newData, { dispatch }) => {
    try {
      const putres = await ApiFetch({
        url: `myadmin/terms-crud/${newData.id}/`,
        method: "PUT",
        body: {
          manager: newData.manager_id,
          status: newData.status,
          comment: newData.comment,
          company: newData.company_id,
          tariff_dead_time: newData.tariff_dead_time,
          created_at: newData.created_at,
          tariff_price: newData.tariff_price,
          tariff: newData.tariff,
        },
      });
      dispatch(getAllBaners());
      return putres;
    } catch (error) {
      return error;
    }
  }
);

export const crokiDelete = createAsyncThunk(
  "main_banners/crokiDelete",
  async (id, { dispatch }) => {
    try {
      const deleteres = await ApiFetch({
        url: `myadmin/terms-crud/${id}/`,
        method: "DELETE",
      });
      dispatch(deleteres(deleteres));
      return deleteres;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  main_banners: [],
  status: null,
  newdata: [],
  statusdelete: false,
};

const AllBanersSlice = createSlice({
  name: "main_banners",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllBaners.fulfilled]: (state, { payload }) => {
      state.main_banners = payload;
      state.status = "success";
    },
    [getAllBaners.rejected]: (state) => {
      state.status = "error";
    },
    [putAllCroki.fulfilled]: (state) => {
      state.fulfilled = "success";
      state.status = "success";
    },
    [putAllCroki.rejected]: (state) => {
      state.status = "error";
    },
    [crokiDelete.fulfilled]: (state) => {
      state.statusdelete = !state.statusdelete;
    },
  },
});

export const allBannersActions = AllBanersSlice.actions;
export default AllBanersSlice;
