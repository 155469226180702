import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import GetCookie from '../../../hooks/cookies/getCookie';
import ApiFetch from '../../api/fetchApi';
import { InitialUrl } from '../../api/fetchApi';
import { findbyidCompanies } from './companiesAction';

export const getAllAnalisisCompany = createAsyncThunk(
  'analysisconpany/getAllAnalisisCompany',
  async (page) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/companies-moderation/?page=${page || 1}`,
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);

export const putAllAnalisisCompany = createAsyncThunk(
  'analysisconpany/putAllAnalisisCompany',
  async (newData, { dispatch }) => {
    delete newData?.body?.id;
    delete newData?.body?.user;
    delete newData?.body?.image;
    try {
      const responce = await ApiFetch({
        url: `entity/profile/${newData.id}/`,
        method: 'PUT',
        body: newData.body,
      });
      dispatch(getAllAnalisisCompany());
      return responce;
    } catch (e) {
      return [];
    }
  }
);
export const getManagerList = createAsyncThunk(
  'analysisconpany/getManagerList',
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/manager-list/`,
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);
const baseDOMAIN = 'https://hrgroup.kg';
const baseURL = `${baseDOMAIN}/api/v1/`;
const APIadmin = axios.create({
  baseURL,
  headers: {
    Authorization: 'Bearer ' + GetCookie('access'),
  },
});
export const sendPhotoCompany = createAsyncThunk(
  'analysisconpany/sendPhotoCompany',
  async (props, { dispatch }) => {
    try {
      var formData = new FormData();
      formData.append('image', props.body?.image, props?.body?.image?.name);
      formData.append('profile', props.body?.profile);
      const res = await APIadmin({
        method: 'PUT',
        url: `profile/image/`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      dispatch(findbyidCompanies(props.id));
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const deleteCompany = createAsyncThunk(
  'analysisconpany/deleteCompany',
  async (props, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: 'profile/image/',
        method: 'DELETE',
        body: { profile: props.id },
      });
      dispatch(findbyidCompanies(props.id));
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const bornManager = createAsyncThunk(
  'analysisconpany/bornManager',
  async (props) => {
    try {
      const res = await ApiFetch({
        url: 'myadmin/manager-update/',
        method: 'POST',
        body: props,
      });
      return res;
    } catch (e) {
      return e;
    }
  }
);
///----------------------------------------------------------------------------------
const initialState = {
  allCompaniesForAnalysies: {},
  managerList: [],
  status: false,
};
const analysisCompanySlice = createSlice({
  name: 'analysisconpany',
  initialState,
  extraReducers: {
    [bornManager.fulfilled]: (state) => {
      state.status = !state.status;
    },
    [bornManager.rejected]: (state) => {
      state.status = !state.status;
    },
    [getManagerList.fulfilled]: (state, { payload }) => {
      state.managerList = payload;
    },
    [getAllAnalisisCompany.fulfilled]: (state, { payload }) => {
      state.allCompaniesForAnalysies = payload;
    },
    [putAllAnalisisCompany.fulfilled]: (state) => {
      state.fulfilled = 'success';
    },
  },
});

export const AnalysisCompanyActions = analysisCompanySlice.actions;
export default analysisCompanySlice;
