import { useContext, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { MdOutlineClose } from 'react-icons/md';
import { TiEye } from 'react-icons/ti';
import { IoBriefcaseOutline, IoPersonOutline } from 'react-icons/io5';
import { BsCheckLg } from 'react-icons/bs';
import { ContentContext } from '../../utils/ContentContext';
import {
  useForgotPwdSubmitMutation,
  useForgotPwdVerifyMutation,
  useLoginUserMutation,
  useRegisterUserMutation,
  useVerifyEmailCheckMutation,
  useVerifyEmailSendMutation,
} from '../../redux/api/authApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import ReactInputMask from 'react-input-mask';

function AuthTemplate() {
  const { setSendPopup, sendPopup } = useContext(ContentContext);
  const { authType, setAuthType } = useContext(AuthContext);
  const closeHandler = () => {
    setAuthType('');
    setSendPopup({ data: {}, status: false });
  };
  const moreDescComponent = () => {
    return (
      Object.keys(sendPopup.data).length !== 0 && (
        <p className='authPopup__form-desc'>
          Для добавления в избранные: <i>"{sendPopup.data.postPos}"</i>
        </p>
      )
    );
  };
  return (
    <>
      <button
        className='preHeader__auth-login'
        onClick={() => setAuthType('login')}>
        Войти
      </button>
      <button
        className='preHeader__auth-register'
        onClick={() => setAuthType('register')}>
        Регистрация
      </button>

      {authType.length !== 0 && (
        <div className='popup' data-aos='zoom-out'>
          <div className='popup__bg' onClick={closeHandler}></div>
          <div className='popup__content'>
            <MdOutlineClose
              size={22}
              className='popup__close'
              onClick={closeHandler}
            />
            <div className='authPopup'>
              <nav className='authPopup__nav'>
                <ul>
                  {authType !== 'forgot-pwd' && (
                    <>
                      <li
                        onClick={() => setAuthType('login')}
                        className={`${authType === 'login' ? 'active' : ''}`}>
                        Вход
                      </li>
                      <li
                        onClick={() => setAuthType('register')}
                        className={`${
                          authType === 'register' ? 'active' : ''
                        }`}>
                        Регистрация
                      </li>
                    </>
                  )}
                  {authType === 'forgot-pwd' && (
                    <li className='active'>Забыли пароль</li>
                  )}
                </ul>
              </nav>
              {authType === 'login' && (
                <LoginForm
                  setAuthType={setAuthType}
                  moreDescComponent={moreDescComponent}
                />
              )}
              {authType === 'forgot-pwd' && (
                <ForgotPwdForm
                  setAuthType={setAuthType}
                  moreDescComponent={moreDescComponent}
                />
              )}
              {authType === 'register' && (
                <RegisterForm
                  setAuthType={setAuthType}
                  moreDescComponent={moreDescComponent}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function LoginForm({ moreDescComponent, setAuthType }) {
  const { pathname } = useLocation();
  const [password, setPassword] = useState(false);

  const [loginUser, { isError, isSuccess, isLoading, error }] =
    useLoginUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onTouched' });

  const onRegisterSubmit = async ({ username, password }) => {
    const isCommonPage = pathname === '/';
    const body = {
      username: username.trim(),
      password,
    };
    const generalRedirectUrl = !isCommonPage ? pathname : '';
    if (username && password) {
      await loginUser({
        userData: body,
        redirectUrls: {
          entityUrl: generalRedirectUrl,
          applicantUrl: generalRedirectUrl,
        },
      });
    }
  };
  useEffect(() => {
    // if (isSuccess) {
    //   toast.success('Успешно авторизованы');
    // }
    if (isError) {
      toast.error(error?.data?.detail);
    }
  }, [isError, isSuccess]);

  return (
    <form onSubmit={handleSubmit(onRegisterSubmit)} className='authPopup__form'>
      {moreDescComponent()}
      <input
        {...register('username', {
          required: 'Обязательно к заполнению',
        })}
        className={`${errors?.username && 'error'}`}
        type='text'
        placeholder='Логин'
      />
      {errors?.username && (
        <p className='authPopup__form-err'>
          {errors?.username?.message || 'Ошибка'}
        </p>
      )}
      <label>
        <input
          {...register('password', {
            required: 'Неверный пароль',
          })}
          className={`${errors?.password && 'error'}`}
          type={`${password ? 'text' : 'password'}`}
          placeholder='Пароль'
        />
        <TiEye size={22} onClick={() => setPassword(!password)} />
      </label>
      {errors?.password && (
        <p className='authPopup__form-err'>
          {errors?.password?.message || 'Ошибка'}
        </p>
      )}
      <input type='submit' value='Войти' disabled={!isValid && isLoading} />
      <p onClick={() => setAuthType('forgot-pwd')}>Забыли пароль?</p>
    </form>
  );
}

function RegisterForm({ moreDescComponent }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [state, setState] = useState(1);
  const [sendCodeInterval, setSendCodeInterval] = useState(0);
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [statusBool, setStatusBool] = useState(false);

  const [
    verifyEmailSend,
    {
      isSuccess: isVerifySendSuccess,
      error: verifySendError,
      data: verifySendData,
    },
  ] = useVerifyEmailSendMutation();

  // const [
  //   verifyEmailCheck,
  //   {
  //     isSuccess: isVerifyGetSuccess,
  //     isError: isVerifyGetError,
  //     error: verifyGetError,
  //     data: verifyGetData,
  //   },
  // ] = useVerifyEmailCheckMutation();

  const [
    registerUser,
    {
      isSuccess: isRegisterSuccess,
      isLoading: isRegisterLoading,
      isError: isRegisterError,
      error: registerError,
      data: registerData,
    },
  ] = useRegisterUserMutation();

  const resendCodeTimer = async () => {
    let body = {
      email: mail,
    };
    toast.success(verifySendData.response);
    dispatch(verifyEmailSend(body));
    setSendCodeInterval(60);
  };

  useEffect(() => {
    sendCodeInterval > 0 &&
      setTimeout(() => setSendCodeInterval(sendCodeInterval - 1), 1000);
  }, [sendCodeInterval]);

  useEffect(() => {
    if (isVerifySendSuccess && state === 1) {
      toast.success(verifySendData.response);
      setState(3);
      // setSendCodeInterval(60);
    }
    // if (isVerifyGetSuccess && state === 2) {
    //   if (!isVerifyGetError) {
    //     toast.success(verifyGetData.response);
    //     setState(3);
    //   }
    // }
    let registerToast = 'register toast';
    if (isRegisterLoading) {
      toast('Регистрация...', {
        toastId: registerToast,
        type: 'loading',
        isLoading: isRegisterLoading,
      });
    }
    if (isRegisterSuccess && state === 3) {
      toast.update(registerToast, {
        render: 'Успешная регистрация',
        type: 'success',
        autoClose: 3000,
        isLoading: isRegisterLoading,
      });
    }
    if (isRegisterError) {
      toast.update(registerToast, {
        render:
          registerError.data.email[0] ||
          registerError.data.password[0] ||
          'Произошла ошибка во время регистрации',
        type: 'error',
        autoClose: 3000,
        isLoading: isRegisterLoading,
      });
      // console.log(registerError.data);
    }
  }, [isVerifySendSuccess, isRegisterLoading, isRegisterSuccess]);

  const formSchema = Yup.object().shape({
    mail: Yup.string().required('Введите почту'),
    password: Yup.string()
      .required('Обязательно к заполнению')
      // .matches(RegExp('(.*[a-z].*)'), 'Пароль должен содержать строчные буквы')
      // .matches(RegExp('(.*[A-Z].*)'), 'Пароль должен содержать заглавные буквы')
      // .matches(RegExp('(.*\\d.*)'), 'Пароль должен содержать цифры')
      .min(8, 'Cоздайте пароль из 8 символов'),
    phone: Yup.string().required('Обязательно к заполнению'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      mail: '',
      password: '',
      status: '',
    },
    resolver: yupResolver(formSchema),
  });

  const onLoginSubmit = async (data) => {
    if (state === 1) {
      let body = {
        email: data.mail,
        password: data.password,
      };
      await verifyEmailSend(body);
    }
    // if (state === 2) {
    //   let body = {
    //     email: mail,
    //     activation_code: data.code,
    //   };
    //   await verifyEmailCheck(body);
    // }
    if (state === 3) {
      let body = {
        email: mail,
        user_status: data.status,
        password: data.password,
        phone: phone,
      };
      await registerUser(body);
    }
  };

  const handleEntitySelect = () => {
    let registerToast = 'register toast';
    if (width <= 491) {
      setStatusBool(false);
      toast(
        'Зарегистрируйтесь с полной версии сайта(через компьютер) для полноценной работы',
        {
          toastId: registerToast,
          autoClose: 1500,
          type: 'warning',
        }
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onLoginSubmit)} className='authPopup__form'>
        {moreDescComponent()}
        {state === 1 && (
          <>
            <input
              {...register('mail', {
                required: 'Введите почту',
                value: mail,
              })}
              className={`${errors?.mail && 'error'}`}
              type='mail'
              placeholder='Email'
              onChange={({ target }) => setMail(target.value)}
            />
            {errors?.mail && (
              <p className='authPopup__form-err'>
                {errors?.mail?.message || 'Ошибка'}
              </p>
            )}
            <ReactInputMask
              {...register('phone', {
                required: 'Введите номер телефона',
                value: phone,
              })}
              placeholder='+996'
              mask='+\9\96 999 999 999'
              type='tel'
              onChange={({ target }) => setPhone(target.value)}
            />
            {errors?.phone && (
              <p className='authPopup__form-err'>
                {`${errors?.phone?.message}` || 'Ошибка'}
              </p>
            )}
            <input
              {...register('password', {
                value: password,
              })}
              className={`${errors?.password && 'error'}`}
              type='password'
              placeholder='Придумайте пароль'
              onChange={({ target }) => setPassword(target.value)}
            />
            {errors?.password && (
              <p className='authPopup__form-err'>
                {`${errors?.password?.message}` || 'Ошибка'}
              </p>
            )}
            {verifySendError && (
              <p className='authPopup__form-err'>
                {`${verifySendError?.data?.error}` || 'Ошибка'}
              </p>
            )}
            {/* <ul className='authPopup__form-required'>
              <li>
                <div className={errors?.password}></div>
                <span>создайте пароль из 8 символов</span>
              </li>
              <li>
                <div className={errors?.password}></div>
                <span>строчные буквы</span>
              </li>
              <li>
                <div className={errors?.password}></div>
                <span>прописные буквы</span>
              </li>
              <li>
                <div className={errors?.password}></div>
                <span>цифры</span>
              </li>
            </ul> */}
          </>
        )}
        {/* {state === 2 && (
          <>
            <p className='authPopup__form-desc'>
              Мы отправили письмо с кодом на почту <b>{mail}</b>, введите код из
              письма
            </p>
            <input
              {...register('code', {
                required: 'Обязательно к заполнению',
              })}
              className={`${errors?.code && 'error'}`}
              type='text'
              placeholder='Введите код'
            />
            {errors?.code && (
              <p className='authPopup__form-err'>
                {errors?.code?.message || 'Ошибка'}
              </p>
            )}
            {verifyGetError && (
              <p className='authPopup__form-err'>
                {`${verifyGetError?.data?.response}` || 'Ошибка'}
              </p>
            )}
          </>
        )} */}
        {state === 3 && (
          <>
            <p className='authPopup__form-desc'>Ваш статус:</p>
            <div className='authPopup__form-wrapper'>
              <label className='authPopup__form-status'>
                <input
                  {...register('status', {
                    required: true,
                  })}
                  className={`${errors?.status && 'error'}`}
                  type='radio'
                  value='applicant'
                  onChange={() => setStatusBool(true)}
                />
                <div className='authPopup__form-status-check'>
                  <BsCheckLg
                    className='authPopup__form-status-check-svg'
                    size={13}
                  />
                </div>
                <div className='authPopup__form-status-content'>
                  <IoBriefcaseOutline
                    className='authPopup__form-status-content-svg'
                    size={25}
                  />
                  <p>Ищу работу</p>
                </div>
              </label>
              {width > 490 && (
                <label className='authPopup__form-status'>
                  <input
                    {...register('status', {
                      required: true,
                    })}
                    className={`${errors?.status && 'error'}`}
                    type='radio'
                    value='entity'
                    onChange={() => setStatusBool(true)}
                  />
                  <div className='authPopup__form-status-check'>
                    <BsCheckLg
                      className='authPopup__form-status-check-svg'
                      size={13}
                    />
                  </div>
                  <div className='authPopup__form-status-content'>
                    <IoPersonOutline
                      className='authPopup__form-status-content-svg'
                      size={25}
                    />
                    <p>Ищу сотрудника</p>
                  </div>
                </label>
              )}
            </div>
          </>
        )}
        {state === 1 && <input type='submit' value='Продолжить' />}
        {state === 2 && (
          <input type='submit' value='Подтвердить' disabled={!isValid} />
        )}
        {state === 3 && !isRegisterLoading && (
          <input type='submit' value='Регистрация' disabled={!statusBool} />
        )}
        {state === 3 && isRegisterLoading && (
          <input type='submit' value='Регистрация' disabled />
        )}
        {/* {state === 2 && (
          <p>
            {sendCodeInterval > 0 && (
              <>
                <span>Отправить код повторно можно через </span>
                <span>{sendCodeInterval}</span>
              </>
            )}
            {sendCodeInterval <= 0 && (
              <span
                onClick={resendCodeTimer}
                style={{ textDecoration: 'underline' }}>
                Отправить код
              </span>
            )}
          </p>
        )} */}
      </form>
    </>
  );
}

function ForgotPwdForm({ moreDescComponent, setAuthType }) {
  const [state, setState] = useState(1);
  const [sendCodeInterval, setSendCodeInterval] = useState(0);
  const [mail, setMail] = useState('');

  const [
    forgotPwdVerify,
    {
      isSuccess: isVerifySendSuccess,
      error: verifySendError,
      data: verifySendData,
    },
  ] = useForgotPwdVerifyMutation();

  const [
    forgotPwdSubmit,
    {
      isSuccess: isVerifyGetSuccess,
      isError: isVerifyGetError,
      error: verifyGetError,
      data: verifyGetData,
    },
  ] = useForgotPwdSubmitMutation();

  const resendCodeTimer = () => {
    let body = {
      email: mail,
    };
    toast.success(verifySendData.response);
    forgotPwdVerify(body);
    setSendCodeInterval(60);
  };

  useEffect(() => {
    sendCodeInterval > 0 &&
      setTimeout(() => setSendCodeInterval(sendCodeInterval - 1), 1000);
  }, [sendCodeInterval]);

  useEffect(() => {
    if (isVerifySendSuccess && state === 1) {
      toast.success(verifySendData.response);
      setState(2);
      setSendCodeInterval(60);
    }
    if (isVerifyGetSuccess && state === 2) {
      if (!isVerifyGetError) {
        toast.success(verifyGetData.response);
        setState(3);
      }
    }
  }, [isVerifySendSuccess, isVerifyGetSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onTouched' });

  const onLoginSubmit = (data) => {
    if (state === 1) {
      let body = {
        email: data.mail,
      };
      // console.log(body);
      forgotPwdVerify(body);
    }
    if (state === 2) {
      let body = {
        email: mail,
        activation_code: data.code,
      };
      forgotPwdSubmit(body);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onLoginSubmit)} className='authPopup__form'>
        {moreDescComponent()}
        {state === 1 && (
          <>
            <input
              {...register('mail', {
                required: 'Введите почту',
                value: mail,
              })}
              className={`${errors?.mail && 'error'}`}
              type='mail'
              placeholder='Email'
              onChange={({ target }) => setMail(target.value)}
            />
            {errors?.mail && (
              <p className='authPopup__form-err'>
                {errors?.password?.message || 'Ошибка'}
              </p>
            )}
            {verifySendError && (
              <p className='authPopup__form-err'>
                {`${verifySendError?.data?.error} ${verifySendError?.status}` ||
                  'Ошибка'}
              </p>
            )}
          </>
        )}
        {state === 2 && (
          <>
            <p className='authPopup__form-desc'>
              Мы отправили письмо с кодом на почту <b>{mail}</b>, введите код из
              письма
            </p>
            <input
              {...register('code', {
                required: 'Обязательно к заполнению',
              })}
              className={`${errors?.code && 'error'}`}
              type='text'
              placeholder='Введите код'
            />
            {errors?.code && (
              <p className='authPopup__form-err'>
                {errors?.code?.message || 'Ошибка'}
              </p>
            )}
            {verifyGetError && (
              <p className='authPopup__form-err'>
                {`${verifyGetError?.data?.response}` || 'Ошибка'}
              </p>
            )}
          </>
        )}
        {state === 3 && (
          <>
            <p className='authPopup__form-desc'>
              Мы отправили письмо на почту <b>{mail}</b>
            </p>
          </>
        )}
        {state === 1 && <input type='submit' value='Продолжить' />}
        {state === 2 && (
          <input type='submit' value='Подтвердить' disabled={!isValid} />
        )}
        {state === 2 && (
          <p>
            {sendCodeInterval > 0 && (
              <>
                <span>Отправить код повторно можно через </span>
                <span>{sendCodeInterval}</span>
              </>
            )}
            {sendCodeInterval <= 0 && (
              <span
                onClick={resendCodeTimer}
                style={{ textDecoration: 'underline' }}>
                Отправить код
              </span>
            )}
          </p>
        )}
        {state === 3 && <p onClick={() => setAuthType('login')}>Вход</p>}
      </form>
    </>
  );
}

export default AuthTemplate;
