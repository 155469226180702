import { useEffect } from 'react';
import AOS from 'aos';
// all-route
import AllRoute from './route/AllRoute';

// configs
import './configs/i18n';

// Styles
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/style.scss';
import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
function App() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);
  return (
    <>
      <AllRoute />
      <div id='modal-root'></div>
    </>
  );
}

export default App;
