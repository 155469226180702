import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';
import { transformBeforeFetch } from '../../hooks/transfromBeforeFetch';
import { userApi } from './userApi';
import axios from 'axios';

export const resumesApi = createApi({
  reducerPath: 'api/resumes',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    getResumes: build.query({
      query: (page) => `resumes/?page=${typeof page != 'object' ? page : '1'}`,
    }),
    getResumeById: build.query({
      query: (id) => `resumes/${id}/`,
    }),
    getResumeByIdToPrivate: build.query({
      query: (id) => ({
        url: `resume-one/${id}/`,
        headers: {
          Authorization: `Bearer ${GetCookie('access')}`,
        },
      }),
    }),
    resumeUpTime: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/resume-uptime/`,
          method: 'POST',
          body: body,
        };
      },
    }),
    getResumePDF: build.mutation({
      query(id) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/resume/pdf/`,
          method: 'POST',
          body: { resume_id: id },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const downloadLink = document.createElement('a');
          downloadLink.href = data.file;
          downloadLink.target = '_blank';

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error('Ошибка загрузки файла:', error);
        }
      },
    }),
    checkPosting: build.mutation({
      query(body) {
        const { data, id } = body;
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/postings/update/${id}/`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    addResumeFetch: build.mutation({
      query(data) {
        const { addResume, photo, userID } = data;
        const formData = transformBeforeFetch(addResume, userID, photo);
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: '/resume/',
          method: 'POST',
          body: formData,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { meta } = await queryFulfilled;
        if (meta.response.status == '201')
          dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    editResumeFetch: build.mutation({
      query(body) {
        let { data, userID, photo, id } = body;
        const formData = transformBeforeFetch(data, userID, photo);
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/resume/${id}/`,
          method: 'PUT',
          body: formData,
        };
      },
    }),
    editResumeFetchPatch: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/resume-status-patch/`,
          method: 'POST',
          body: body,
        };
      },
    }),
    deleteResumeFetch: build.mutation({
      query(id) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/resume/${id}/`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetResumesQuery,
  useGetResumeByIdQuery,
  useLazyGetResumeByIdQuery,
  useGetResumeByIdToPrivateQuery,
  useLazyGetResumeByIdToPrivateQuery,
  useResumeUpTimeMutation,
  useAddResumeFetchMutation,
  useEditResumeFetchPatchMutation,
  useEditResumeFetchMutation,
  useDeleteResumeFetchMutation,
  useCheckPostingMutation,
  useGetResumePDFMutation,
} = resumesApi;
