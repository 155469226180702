import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetVacanciesQuery } from '../../../redux';
import CardsList from '../../../components/CardsList/CardsList';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import CardsSkeletonList from '../../../components/CardsList/CardsSkeletonList';

function VacanciesBottom() {
  const { width } = useWindowDimensions();
  const pageSize = width >= 580 ? 40 : 20;
  const { data = [], isLoading } = useGetVacanciesQuery(
    { pageSize },
    { refetchOnMountOrArgChange: true }
  );
  const { t } = useTranslation();
  return (
    <div className='contentContent__bottom'>
      <h3 className='contentContent__bottom__title'>
        {t('Home.newContent.vacancyTitle')}
      </h3>
      <div className='regionSwiper'>
        <div className='regionSwiper__top'>
          <div className='regionSwiper__list-bottom'></div>
          <div className='regionSwiper__cards'>
            {!isLoading && data?.results.length && (
              <CardsList cards={data.results} />
            )}
            {isLoading && <CardsSkeletonList isVacancy={true} />}
            {!isLoading && !data?.results.length && (
              <p className='favourites__text'>Пусто</p>
            )}
          </div>
          <Link
            to={{
              pathname: '/vacancies-all',
              search: '?page=2',
            }}
            className='regionSwiper__link-more unSelect'>
            {t('Home.newContent.viewBtn')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VacanciesBottom;
