import { useEffect, useRef, useState } from 'react';
import { HandySvg } from 'handy-svg';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { MultiSelect } from 'react-multi-select-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import {
  cities,
  searchType,
  salaryOptions,
  educationType,
  socGroupsType,
  periodFrom,
  expOptions,
  genderOptions,
  scheduleOptions,
} from '../../constants/searchForm';
import {
  aggeOptions,
  driverLicenses,
  typeOfEmployment,
} from '../../constants/resumeInfo';
import languages from '../../constants/languagesList';
import filter from '../../assets/svg/filter.svg';
import { useGetPositionsQuery } from '../../redux/api/searchApi';
import { MdOutlineClose } from 'react-icons/md';
import { toast } from 'react-toastify';

function Header() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { width } = useWindowDimensions();

  //Data
  const [queriesKeys, setQueriesKeys] = useState({});
  const [queriesData, setQueriesData] = useState({});

  const [citiesValue, setCitiesValue] = useState([]);
  const [languagesValue, setLanguagesValue] = useState([]);
  const [licenseValue, setDriverLicenseValue] = useState([]);

  const [positionTemp, setPositionTemp] = useState('');

  const {
    position,
    time,
    salary,
    edu,
    exp,
    schedule,
    group,
    type,
    gender,
    agge,
  } = queriesKeys;

  const { data: positionsData = [], isLoading: isPositionsLoading } =
    useGetPositionsQuery(
      {},
      { skip: width < 1266, refetchOnMountOrArgChange: true }
    );

  // const [searchResult, { isLoading: isSearchLoading, isSuccess }] =
  //   useSearchResultMutation({ skip: true });

  //States
  const searchState = pathname === '/' || pathname === '/resumes';
  const isVacanciesPage =
    pathname === '/' ||
    pathname === '/search/' ||
    pathname.includes('vacancies') ||
    (pathname.includes('/categories/') &&
      !pathname.includes('resumes/categories'));
  const isResumesPage =
    pathname === '/resumes' ||
    pathname === '/resumes/' ||
    pathname === '/resumes-all' ||
    pathname === '/search/resumes' ||
    pathname.includes('resumes/categories');

  const [filterShow, setFilterShow] = useState(false);
  const [filterShowPop, setFilterShowPop] = useState(false);

  const [positionsOptions, setPositionsOptions] = useState([]);

  const filterPositions = (inputValue) => {
    const inputValueLower = inputValue.toLowerCase();
    const filteredOptions = positionsOptions.filter((i) =>
      i.label.toLowerCase().trim().startsWith(inputValueLower)
    );
    const includesOptions = positionsOptions.filter(
      (i) =>
        i.label.toLowerCase().trim().includes(inputValueLower) &&
        !filteredOptions.includes(i)
    );
    return [...filteredOptions, ...includesOptions];
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        callback(filterPositions(inputValue));
      }, 1000);
    }
  };

  useEffect(() => {
    let transformed = Object.entries(queriesKeys).reduce((accum, [k, v]) => {
      if (v !== undefined && Array.isArray(v)) {
        accum[k] = v.reduce((acc, rec) => (acc += `$${rec.value}`), '');
      } else accum[k] = v?.value;
      return accum;
    }, {});
    setQueriesData(transformed);
  }, [queriesKeys]);

  useEffect(() => {
    if (!isPositionsLoading) {
      let toOptions = positionsData.reduce(
        (acc, rec) => [...acc, { value: rec.title, label: rec.title }],
        []
      );
      setPositionsOptions(toOptions);
    }
  }, [isPositionsLoading]);

  function setDefaultQueriesData() {
    const handlers = {
      city: setCitiesValue,
      language: setLanguagesValue,
      driver_license: setDriverLicenseValue,
    };
    const options = {
      agge: aggeOptions(),
      driver_license: driverLicenses,
      // schedule: typeOfEmployment,
      schedule: [...scheduleOptions, ...typeOfEmployment],
      city: cities,
      type: searchType,
      time: periodFrom,
      salary: salaryOptions,
      edu: educationType,
      group: socGroupsType,
      exp: expOptions,
      gender: genderOptions,
      language: languages,
    };
    const query = Object.entries(getUrlParams()).reduce((acc, key) => {
      let option = null;
      switch (key[0]) {
        case 'position':
          option = { value: key[1], label: key[1] };
          break;
        case 'city':
        case 'language':
        case 'driver_license':
          const filterArray = key[1].split('$').filter(Boolean);
          const filteredData = options[key[0]].filter((arrOption) =>
            filterArray.some(
              (filter) =>
                arrOption.value.includes(filter) ||
                arrOption.label.includes(filter)
            )
          );
          option = filteredData;
          handlers[key[0]](filteredData);
          break;

        default:
          option = options[key[0]]?.find(
            (option) => option.value === key[1] || option.label === key[1]
          ) || { value: key[1], label: key[1] };
          break;
      }
      return { ...acc, [key[0]]: option };
    }, {});
    let totalQueries = { ...queriesKeys, ...query };
    if (isResumesPage) {
      let { group, ...resumesQueriesKeys } = totalQueries;
      setQueriesKeys({
        ...resumesQueriesKeys,
        type: { value: 'resumes', label: 'Резюме' },
      });
    }
    if (isVacanciesPage) {
      let { driver_license, gender, agge, language, ...vacanciesQueriesKeys } =
        totalQueries;
      setQueriesKeys({
        ...vacanciesQueriesKeys,
        type: { value: 'vacancies', label: 'Вакансии' },
      });
    }
  }

  useEffect(() => {
    setQueriesKeys({
      ...queriesKeys,
      city: citiesValue,
      language: languagesValue,
      driver_license: licenseValue,
    });
  }, [citiesValue, languagesValue, licenseValue]);

  const handleInputChange = (value, action, key) => {
    if (action.action === 'input-change')
      setQueriesKeys({ ...queriesKeys, [key]: { value, label: value } });
  };

  const handleChangeOnBlur = () =>
    positionTemp.trim() &&
    setQueriesKeys({
      ...queriesKeys,
      position: { value: positionTemp, label: positionTemp },
    });

  const handleChange = (data, inputData) => {
    if (inputData.name === 'type') {
      if (data.value === 'vacancies') navigate('/');
      if (data.value === 'resumes') navigate('/resumes');
    }
    if (inputData.name === 'position') {
      setPositionTemp(data?.value || data?.label || '');
    }
    setQueriesKeys({
      ...queriesKeys,
      [inputData.name]: data,
    });
  };

  const customValueRenderer = (selected, _option) => {
    return selected.length
      ? selected.map(({ label }, idx) => `${idx > 0 ? ', ' : ''}${label}`)
      : cities === _option
      ? 'Город'
      : languages === _option
      ? 'Языки'
      : driverLicenses === _option
      ? 'Вод. права'
      : '';
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected && '#070707',
      backgroundColor: state.isSelected
        ? 'rgba(0, 56, 101, 0.15);'
        : 'transparent',
      whiteSpace: 'pre-wrap',
    }),
  };

  const showFilterHandler = () => {
    if (width >= 1266) {
      setFilterShow(!filterShow);
    }
    if (width < 1266) {
      setFilterShowPop(true);
    }
  };

  const resetKeys = () => {
    const { position, city, type } = queriesKeys;
    const result = { position, city, type };
    setQueriesKeys(result);
  };

  const resetFilter = () => {
    setFilterShowPop(false);
    resetKeys();
    const { position, city, type } = queriesData;
    const clearRestQueries = removeEmpty({ position, city });
    const searchQuery = objectToUrlSearchParams(clearRestQueries);
    const page =
      Object.keys(clearRestQueries).length > 0 ? '&page=1' : 'page=1';
    navigate({
      pathname: '/search/' + type,
      search: '?' + searchQuery + page,
    });
  };

  function removeEmpty(obj) {
    Object.keys(obj).forEach((key) => {
      if (!obj[key] || key === 'page') {
        delete obj[key];
      }
    });
    return obj;
  }

  function objectToUrlSearchParams(obj) {
    const searchParams = new URLSearchParams();

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        searchParams.set(key, obj[key]);
      }
    }
    return searchParams.toString();
  }

  function getUrlParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return params;
  }

  useEffect(() => {
    if (!searchState) {
      setFilterShow(true);
    }
    setDefaultQueriesData();
  }, [pathname, search]);

  const onSubmitSearch = () => {
    setFilterShowPop(false);
    const { type, ...resQueries } = queriesData;
    const clearRestQueries = removeEmpty(resQueries);
    const searchQuery = objectToUrlSearchParams(clearRestQueries);
    const page = '&page=1';
    if (Object.keys(clearRestQueries).length) {
      navigate({
        pathname: '/search/' + type,
        search: '?' + searchQuery + page,
      });
    } else {
      toast.warning('Пожалуйста, установите хотя бы один параметр для фильтра');
    }
  };

  let FilterPopup = () => {
    return (
      <div className='handlePopup'>
        <MdOutlineClose
          size={25}
          className='handlePopup__close'
          fill='#fff'
          onClick={() => setFilterShowPop(false)}
        />
        <div className='handlePopup__content'>
          <div
            className='headerSearch__form-moreBtn'
            onClick={showFilterHandler}>
            <HandySvg src={filter} width='24' height='24' />
            <span>Фильтр</span>
          </div>
          <div className='headerSearch__form'>
            <Select
              isClearable
              isSearchable={false}
              className='headerSearch__form-initial'
              name='time'
              placeholder='За период'
              value={time}
              options={periodFrom}
              onChange={handleChange}
              blurInputOnSelect
            />
            <Select
              isClearable
              isSearchable={false}
              options={salaryOptions}
              name='salary'
              className='headerSearch__form-initial'
              styles={customStyles}
              placeholder='Зарплата'
              value={salary}
              onChange={handleChange}
              blurInputOnSelect
            />
            <Select
              isClearable
              isSearchable={false}
              className='headerSearch__form-initial'
              styles={customStyles}
              name='exp'
              placeholder='Стаж'
              value={exp}
              options={expOptions}
              onChange={handleChange}
              blurInputOnSelect
            />
            <Select
              isClearable
              isSearchable={false}
              className='headerSearch__form-initial'
              placeholder='График работы'
              name='schedule'
              value={schedule}
              // options={typeOfEmployment}
              options={scheduleOptions}
              onChange={handleChange}
              blurInputOnSelect
            />
            <Select
              isClearable
              isSearchable={false}
              options={educationType}
              name='edu'
              className='headerSearch__form-initial'
              placeholder='Образование'
              value={edu}
              onChange={handleChange}
              blurInputOnSelect
            />
            <Select
              isClearable
              isSearchable={false}
              options={socGroupsType}
              name='group'
              className='headerSearch__form-initial'
              placeholder='Социальные группы'
              value={group}
              onChange={handleChange}
              blurInputOnSelect
            />
            <button
              type='submit'
              onClick={onSubmitSearch}
              className='headerSearch__form-submitBtn headerSearch__form-initial'>
              Поиск
            </button>
            <label onClick={resetFilter}>Сбросить фильтры</label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <header className={`headerSearch ${searchState && width >= 460 && 'jeep'}`}>
      {searchState && (
        <h2 className='headerSearch__title'>
          {isVacanciesPage && t('SearchFilter.vacancies_title')}
          {isResumesPage && t('SearchFilter.resumes_title')}
        </h2>
      )}
      <div
        style={{ zIndex: !filterShowPop && width < 1266 ? '2' : 'unset' }}
        className={`headerSearch__form ${
          filterShow && width >= 1266 ? 'show' : ''
        } ${isResumesPage ? 'resume' : ''}`}>
        {width >= 1266 && (
          <>
            <div className='headerSearch__form-left'>
              <Select
                isClearable
                name='position'
                key='position'
                className='headerSearch__form-initial'
                styles={customStyles}
                placeholder={`${
                  isVacanciesPage ? 'Должность' : 'Поиск сотрудника'
                }`}
                value={position}
                onBlur={handleChangeOnBlur}
                onInputChange={(value, action) => {
                  setPositionTemp(value);
                  handleInputChange(value, action, 'position');
                }}
                onChange={handleChange}
                noOptionsMessage={() => null}
              />
              {/* <AsyncCreatableSelect
                isClearable
                name='position'
                className='headerSearch__form-initial'
                openMenuOnFocus={false}
                openMenuOnClick={false}
                styles={customStyles}
                loadOptions={loadOptions}
                defaultOptions={positionsOptions}
                placeholder={`${
                  isVacanciesPage ? 'Должность' : 'Поиск сотрудника'
                }`}
                value={position}
                onBlur={handleChangeOnBlur}
                // blurInputOnSelect={false}
                onInputChange={(e) => setPositionTemp(e)}
                onChange={handleChange}
                noOptionsMessage={() => 'Нет результатов!'}
                formatCreateLabel={(userInput) => `Поиск: ${userInput}`}
                allowCreateWhileLoading={true}
                loadingMessage={(userInput) =>
                  userInput.length >= 3 ? 'Загрузка позиции...' : null
                }
              /> */}
              {isVacanciesPage && (
                <>
                  <Select
                    isClearable
                    isSearchable={false}
                    className='headerSearch__form-hidden'
                    name='time'
                    placeholder='За период'
                    value={time}
                    options={periodFrom}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    options={salaryOptions}
                    name='salary'
                    className='headerSearch__form-hidden'
                    styles={customStyles}
                    placeholder='Зарплата'
                    value={salary}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    className='headerSearch__form-hidden'
                    styles={customStyles}
                    name='exp'
                    placeholder='Стаж'
                    value={exp}
                    options={expOptions}
                    onChange={handleChange}
                  />
                </>
              )}
              {isResumesPage && (
                <>
                  <Select
                    isClearable
                    isSearchable={false}
                    name='time'
                    className='headerSearch__form-hidden onePart secondInitial'
                    placeholder='Резюме за период'
                    value={time}
                    options={periodFrom}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    options={salaryOptions}
                    name='salary'
                    className='headerSearch__form-hidden onePart secondInitial'
                    styles={customStyles}
                    placeholder='Зарплата'
                    value={salary}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    options={genderOptions}
                    name='gender'
                    className='headerSearch__form-hidden onePart'
                    placeholder='Пол'
                    value={gender}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    options={educationType}
                    name='edu'
                    className='headerSearch__form-hidden onePart'
                    placeholder='Образование'
                    value={edu}
                    onChange={handleChange}
                  />
                </>
              )}
            </div>
            <div className='headerSearch__form-right'>
              <MultiSelect
                options={cities}
                name='city'
                className='headerSearch__form-initial cities'
                valueRenderer={customValueRenderer}
                disableSearch={true}
                hasSelectAll={false}
                value={citiesValue}
                onChange={setCitiesValue}
              />
              <Select
                options={searchType}
                name='type'
                className='headerSearch__form-initial'
                styles={customStyles}
                value={type}
                onInputChange={(value, action) => {
                  handleInputChange(value, action, 'type');
                }}
                onChange={handleChange}
              />
              <button
                type='submit'
                onClick={onSubmitSearch}
                className='headerSearch__form-submitBtn headerSearch__form-initial'>
                Поиск
              </button>
              {isVacanciesPage && (
                <>
                  <Select
                    isClearable
                    isSearchable={false}
                    className='headerSearch__form-hidden'
                    placeholder='График работы'
                    name='schedule'
                    value={schedule}
                    // options={typeOfEmployment}
                    options={scheduleOptions}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    options={educationType}
                    name='edu'
                    className='headerSearch__form-hidden'
                    placeholder='Образование'
                    value={edu}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    options={socGroupsType}
                    name='group'
                    className='headerSearch__form-hidden'
                    placeholder='Социальные группы'
                    value={group}
                    onChange={handleChange}
                  />
                </>
              )}
              {isResumesPage && (
                <>
                  <Select
                    isClearable
                    isSearchable={false}
                    className='headerSearch__form-hidden secondInitial'
                    name='schedule'
                    placeholder='График работы'
                    value={schedule}
                    options={typeOfEmployment}
                    // options={scheduleOptions}
                    onChange={handleChange}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    className='headerSearch__form-hidden secondInitial'
                    styles={customStyles}
                    name='exp'
                    placeholder='Стаж'
                    value={exp}
                    onChange={handleChange}
                    options={expOptions}
                  />
                  <Select
                    isClearable
                    isSearchable={false}
                    className='headerSearch__form-hidden secondInitial'
                    name='agge'
                    placeholder='Возраст'
                    options={aggeOptions()}
                    value={agge}
                    onChange={handleChange}
                  />
                  <MultiSelect
                    name='driver_license'
                    className='headerSearch__form-hidden'
                    valueRenderer={customValueRenderer}
                    value={licenseValue}
                    disableSearch={true}
                    hasSelectAll={false}
                    options={driverLicenses}
                    onChange={setDriverLicenseValue}
                  />
                  <MultiSelect
                    options={languages}
                    className='headerSearch__form-hidden onePart last'
                    name='language'
                    valueRenderer={customValueRenderer}
                    value={languagesValue}
                    hasSelectAll={false}
                    disableSearch={true}
                    onChange={setLanguagesValue}
                  />
                </>
              )}
            </div>
          </>
        )}
        {width < 1266 && (
          <>
            <div className='headerSearch__form-left'>
              {/* <AsyncCreatableSelect
                isClearable
                name='position'
                key='position'
                className='headerSearch__form-initial'
                styles={customStyles}
                loadOptions={loadOptions}
                defaultOptions={positionsOptions}
                placeholder={`${
                  isVacanciesPage ? 'Должность' : 'Поиск сотрудника'
                }`}
                value={position}
                onBlur={handleChangeOnBlur}
                blurInputOnSelect={false}
                onInputChange={(e) => setPositionTemp(e)}
                onChange={handleChange}
                noOptionsMessage={() => 'Нет результатов!'}
                loadingMessage={() => 'Загрузка позиции...'}
                allowCreateWhileLoading={true}
                formatCreateLabel={(userInput) => `Позиция: ${userInput}`}
              /> */}
              <Select
                isClearable
                name='position'
                key='position'
                className='headerSearch__form-initial'
                styles={customStyles}
                placeholder={`${
                  isVacanciesPage ? 'Должность' : 'Поиск сотрудника'
                }`}
                value={position}
                onBlur={handleChangeOnBlur}
                onInputChange={(value, action) => {
                  setPositionTemp(value);
                  handleInputChange(value, action, 'position');
                }}
                onChange={handleChange}
                noOptionsMessage={() => null}
              />
              <MultiSelect
                options={cities}
                name='city'
                className='headerSearch__form-initial cities'
                valueRenderer={customValueRenderer}
                disableSearch={true}
                hasSelectAll={false}
                value={citiesValue}
                onChange={setCitiesValue}
              />
              <button
                onClick={onSubmitSearch}
                type='submit'
                className='headerSearch__form-submitBtn headerSearch__form-initial'>
                Поиск
              </button>
            </div>
            {filterShowPop ? <FilterPopup /> : null}
          </>
        )}
        {(searchState || width <= 1266) && (
          <div
            className='headerSearch__form-moreBtn'
            onClick={showFilterHandler}>
            <HandySvg src={filter} width='24' height='24' />
            <span>Фильтр</span>
          </div>
        )}
      </div>
      {/* <div className='tree-container'>
        <div className='star'></div>
        <div className='spiral-container'>
          <ul className='spiral one'>
            <li className='light-wrapper light-wrapper-1'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-2'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-3'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-4'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-5'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-6'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-7'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-8'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-9'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-10'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-11'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-12'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-13'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-14'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-15'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-16'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-17'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-18'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-19'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-20'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-21'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-22'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-23'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-24'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-25'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-26'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-27'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-28'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-29'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-30'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-31'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-32'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-33'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-34'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-35'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-36'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-37'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-38'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-39'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-40'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-41'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-42'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-43'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
          </ul>
          <ul className='spiral two'>
            <li className='light-wrapper light-wrapper-1'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-2'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-3'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-4'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-5'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-6'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-7'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-8'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-9'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-10'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-11'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-12'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-13'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-14'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-15'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-16'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-17'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-18'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-19'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-20'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-21'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-22'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-23'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-24'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-25'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-26'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-27'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-28'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-29'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-30'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-31'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-32'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-33'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-34'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-35'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-36'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-37'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-38'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-39'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-40'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-41'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-42'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-43'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
          </ul>
          <ul className='spiral three'>
            <li className='light-wrapper light-wrapper-1'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-2'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-3'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-4'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-5'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-6'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-7'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-8'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-9'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-10'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-11'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-12'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-13'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-14'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-15'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-16'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-17'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-18'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-19'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-20'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-21'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-22'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-23'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-24'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-25'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-26'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-27'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-28'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-29'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-30'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-31'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-32'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-33'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-34'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-35'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-36'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-37'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-38'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-39'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-40'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-41'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-42'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
            <li className='light-wrapper light-wrapper-43'>
              <div className='stabilise'>
                <div className='light'></div>
              </div>
            </li>
          </ul>
        </div>
      </div> */}
    </header>
  );
}

export default Header;
