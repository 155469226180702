import { useState } from 'react';
import SeoHelmet from '../../hoc/SeoHelmet';
import { useSearchResultMutation } from '../../redux/api/searchApi';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import CardsList from '../../components/CardsList/CardsList';
import { goToTop } from '../../hooks/goToTopScroll';
import CardsSkeletonList from '../../components/CardsList/CardsSkeletonList';

function Search() {
  const { pathname, search } = useLocation();
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const type = pathname.includes('resumes') ? 'resumes' : 'vacancies';
  const queryParams = validateQueryParams();
  let pageParam = searchParams.get('page');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setPage(pageParam), [pageParam]);

  function objectToQueryString(obj) {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join('&');
  }

  function validateQueryParams() {
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    const resQueries = objectToQueryString(
      Object.entries(params).reduce((acc, el) => {
        let val = typeof el[1] === 'number' ? el[1].replace(' ', '') : el[1];
        return { ...acc, [el[0]]: val };
      }, {})
    );
    return resQueries;
  }

  useEffect(() => {
    goToTop();
  }, [pathname]);

  const [searchResult, { data: searchResData = [], isLoading }] =
    useSearchResultMutation();

  useEffect(() => {
    const fetchData = async () => {
      await searchResult({
        type: type,
        queries: queryParams,
      });
    };
    fetchData().catch((err) => console.log(err));
  }, [search]);

  return (
    <>
      <SeoHelmet
        title='Поиск'
        innerTitle='Поиск'
        description='Сайт hrgroup.kg — это удобное, простое решение при поиске сотрудников. Легкий интерфейс и простая процедура публикаций сделает поиск работы и сотрудников максимально удобным. Мы хотим помочь каждому соискателю найти достойную работу, а каждому работодателю достойного сотрудника.'
        location={window.location.href}
      />
      <div className='allResVac searching'>
        {isLoading && <CardsSkeletonList isVacancy={type === 'vacancies'} />}
        {!searchResData && !isLoading ? (
          <h3 className='allResVac__title'>Не найдено</h3>
        ) : null}
        {!!searchResData && !isLoading && (
          <CardsList
            cards={searchResData.results}
            setPage={setPage}
            page={page}
            paginationCount={searchResData.count}
          />
        )}
      </div>
    </>
  );
}

export default Search;
