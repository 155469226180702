import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import Skeleton from 'react-loading-skeleton';

function AsideSkeleton() {
  return (
    <>
      <Swiper loop='true' autoplay={{ delay: 10000 }} modules={[Autoplay]}>
        {[...Array(3).fill()]?.map((el, idx) => {
          return (
            <SwiperSlide key={idx}>
              <Skeleton width={272} height={488} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default AsideSkeleton;
