import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useLayoutEffect, useState } from 'react';
import { useRef } from 'react';
import { GrEmoji } from 'react-icons/gr';
import { RiSendPlane2Line } from 'react-icons/ri';
import { useKeyPress } from '../../hooks/useKeyPress';

const ChatForm = ({ handleSendMessage }) => {
  const [message, setMessage] = useState('');
  const [showEmojis, setShowEmojis] = useState(false);
  const [emojisPos, setEmojisPos] = useState(76);

  const emojiPicker = useRef(null);
  const textbox = useRef(null);

  function adjustHeight() {
    textbox.current.style.height = 'inherit';
    textbox.current.style.height = `${textbox.current.scrollHeight}px`;
    let height = textbox.current.scrollHeight + 46;
    setEmojisPos(height >= 302 ? 310 : height);
  }

  useLayoutEffect(adjustHeight, []);

  let shiftPressed = useKeyPress('Shift');

  useOnClickOutside(emojiPicker, () => setShowEmojis(false));

  function handleKeyDown({ target: { value } }) {
    setMessage(value);
    adjustHeight();
  }

  const addEmoji = (e) => {
    let sym = e.unified.split('-');
    let codesArray = [];
    sym.forEach((el) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);
    setMessage(message + emoji);
    textbox.current.focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage(message.split('\n').join('\n\n'));
    setMessage('');
    setShowEmojis(false);
    textbox.current.style.height = '36px';
    textbox.current.focus();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13 && !shiftPressed) {
      handleSubmit(e);
    }
  };

  return (
    <>
      {showEmojis && (
        <div
          data-aos='fade-up'
          className='messages__form-picker'
          ref={emojiPicker}
          style={{ bottom: `${emojisPos}px` }}>
          <Picker
            theme='light'
            data={data}
            emojiSize='20'
            previewPosition='none'
            navPosition='bottom'
            onEmojiSelect={addEmoji}
          />
        </div>
      )}
      <form onSubmit={handleSubmit} className='messages__form'>
        <GrEmoji
          onClick={() => setShowEmojis(!showEmojis)}
          size={24}
          color='#707991'
          className='messages__form-emoji'
        />
        <label className='messages__form-label'>
          <textarea
            rows={1}
            ref={textbox}
            placeholder='Сообщение'
            value={message}
            onChange={handleKeyDown}
            onKeyDown={handleKeypress}
          />
        </label>
        <button type='submit' className='messages__form-submit'>
          <RiSendPlane2Line size={24} color='#707991' />
        </button>
      </form>
    </>
  );
};

export default ChatForm;
