import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';

export const categoryApi = createApi({
  reducerPath: 'api/category',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  tagTypes: ['Category'],
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (build) => ({
    getCategories: build.query({
      query: () => `/category/`,
      providesTags: ['Category'],
    }),
    getSubCategories: build.mutation({
      query(data) {
        return {
          url: 'subcategory/',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useGetCategoriesQuery, useGetSubCategoriesMutation } =
  categoryApi;
