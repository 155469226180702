import { useParams } from 'react-router-dom';
import GetCookie from '../../hooks/cookies/getCookie';
import { baseDOMAIN } from '../../configs/axiosConfig';
import useChatsData from '../../hooks/useChatsData';
import SeoHelmet from '../../hoc/SeoHelmet';

import placeHolderUser from '../../assets/images/placeholderUser.webp';
import { Link } from 'react-router-dom';

const CompanionInfo = () => {
  const { room } = useParams();
  const { chatsData } = useChatsData();

  const companionInfo = chatsData.find(({ title }) => title === room);

  if (!companionInfo) return null;

  let {
    entity_image = '',
    applicant_image = '',
    app_name,
    app_email,
    ent_name,
  } = companionInfo;

  let isEntity = GetCookie('status') === 'entity';

  let image = isEntity ? applicant_image : entity_image;
  let name = isEntity ? app_name || app_email : ent_name;
  // let link = !isEntity ? `/companies/${entity}` : '#';
  let link = '#';

  return (
    <>
      <SeoHelmet
        title={`${name} - чат`}
        innerTitle={`${name} - чат`}
        description={name}
      />
      <div className='messages__companion'>
        <Link to={link} className='messages__companion-ava'>
          <img
            src={image ? `https://${baseDOMAIN}${image}` : placeHolderUser}
            alt={name}
          />
        </Link>
        <div className='messages__companion-info'>
          <h3 className='messages__companion-name'>{name}</h3>
          {/* <p className='messages__companion-status'>last seen 5 mins ago</p> */}
        </div>
      </div>
    </>
  );
};

export default CompanionInfo;
