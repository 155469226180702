import React, { memo } from 'react';
import { AiFillStar } from 'react-icons/ai';
import Skeleton from 'react-loading-skeleton';

function CardSkeletonInner({ company = false, vip = false }) {
  return (
    <div className='card' style={{ paddingLeft: `${vip ? '32px' : '20px'}` }}>
      <div className='card__main' style={{ position: 'unset' }}>
        {vip && (
          <div className='card-vip'>
            <p>
              <AiFillStar />
            </p>
            <p>
              <AiFillStar />
              <span>ТОП</span>
            </p>
            <p>
              <AiFillStar />
              <span>ТОП</span>
            </p>
            <p>
              <AiFillStar />
              <span>ТОП</span>
            </p>
          </div>
        )}
        <div className='card__main-info'>
          <div className='card__main-info-breadcumbs'>
            <Skeleton width={200} />
          </div>
          <div className='card__main-info-text'>
            <h3>
              <Skeleton width={230} />
            </h3>
          </div>
          <div className='card__main-salary'>
            <Skeleton width={100} />
          </div>
        </div>
        <div className='card__main-logo'>
          <Skeleton circle width={'100%'} height={'100%'} />
        </div>
      </div>
      <div className='card__more'>
        <div
          className='card__more-name'
          // style={{ textTransform: !profile ? 'unset' : 'uppercase' }}
        >
          {company && <Skeleton width={250} />}
          {!company && <Skeleton count={2} width={250} />}
        </div>
        <span className={`card__more-description`}>
          {vip && <Skeleton count={2} />}
        </span>
      </div>
      <div className='card__handlers'>
        <Skeleton width={150} height={44} />
        <div className='card__handlers-fav unSelect'>
          <Skeleton width={150} />
        </div>
      </div>
    </div>
  );
}

const CardSkeleton = memo(CardSkeletonInner);

export default CardSkeleton;
