import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';
import { userApi } from './userApi';

export const employerProfileApi = createApi({
  reducerPath: 'api/employerProfile',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  tagTypes: ['Tags'],
  endpoints: (build) => ({
    getEmployerProfile: build.query({
      query: () => `employerProfile`,
    }),
    getEmployerTags: build.query({
      query() {
        return {
          url: '/posting/tag/r/',
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
        };
      },
      providesTags: ['Tags'],
    }),
    addEmployerTag: build.mutation({
      query({ title }) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/posting/tag/cud/`,
          method: 'POST',
          body: { title },
        };
      },
      providesTags: ['Tags'],
    }),
    updateEmployerTag: build.mutation({
      query({ title, id }) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/posting/tag/cud/${id}`,
          method: 'PUT',
          body: { title },
        };
      },
      providesTags: ['Tags'],
    }),
    bindEmployerTag: build.mutation({
      query({ posting, posting_tag }) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/posting/tag/bind/`,
          method: 'POST',
          body: { posting, posting_tag },
        };
      },
      providesTags: ['Tags'],
    }),
    deleteEmployerTag: build.mutation({
      query({ id }) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/posting/tag/cud/${id}`,
          method: 'DELETE',
        };
      },
      providesTags: ['Tags'],
    }),
    addEmployerProfileFetch: build.mutation({
      query(data) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: '/entity/profile/',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch }) {
        await dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    editEmployerProfileFetch: build.mutation({
      query(body) {
        let { data, id } = body;
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/entity/profile/${id}/`,
          method: 'PUT',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch }) {
        await dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    deleteEmployerProfileFetch: build.mutation({
      query(id) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/entity/profile/${id}/`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(args, { dispatch }) {
        await dispatch(userApi.endpoints.getMe.initiate(null));
      },
    }),
    getPostingsTags: build.query({
      query: () => 'posting/tag/r/',
    }),
  }),
});

export const {
  useAddEmployerProfileFetchMutation,
  useEditEmployerProfileFetchMutation,
  useDeleteEmployerProfileFetchMutation,
  useAddEmployerTagMutation,
  useUpdateEmployerTagMutation,
  useDeleteEmployerTagMutation,
  useBindEmployerTagMutation,
  useGetEmployerProfileQuery,
  useGetPostingsTagsQuery,
  useGetEmployerTagsQuery,
} = employerProfileApi;
