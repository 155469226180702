import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import GetCookie from '../../hooks/cookies/getCookie';

export const mockApi = createApi({
  reducerPath: 'api/mockApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  endpoints: (build) => ({
    getAllTariffs: build.query({
      query: () => `alltariffs/`,
    }),
    postTarrifs: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `transaction/minus/`,
          method: 'POST',
          body: { tariffs: body },
        };
      },
    }),
    getContactsPbp: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `get-contacts/pbp/`,
          method: 'POST',
          body: { resume: body },
        };
      },
    }),
    getActiveEmployers: build.query({
      query: () => `active-employers/`,
    }),
    getActiveBanners: build.query({
      query: () => `active-banners/`,
    }),
    getEmployerInfo: build.query({
      query: (id) => `entity/profiles/${id}/`,
    }),
    getAllSpecalizations: build.query({
      query: () => `/specs/`,
    }),
    getCompanies: build.query({
      query: (page = 1) =>
        `/companies-list/?page=${typeof page != 'object' ? page : '1'}`,
    }),
    sendCallRequest: build.mutation({
      query(body) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `call-request-create/`,
          method: 'POST',
          body: body,
        };
      },
    }),
    sendPostingRequest: build.mutation({
      query(body) {
        // console.log(body);
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access')}`,
          },
          url: `/postings/is-viewed/`,
          method: 'POST',
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetActiveBannersQuery,
  useGetActiveEmployersQuery,
  useGetAllTariffsQuery,
  useGetAllSpecalizationsQuery,
  useGetCompaniesQuery,
  useSendCallRequestMutation,
  useSendPostingRequestMutation,
  useGetEmployerInfoQuery,
  usePostTarrifsMutation,
  useGetContactsPbpMutation,
} = mockApi;
