import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";

export const getWorkPage_plan = createAsyncThunk(
  "admin/getWorkPage_plan",
  async () => {
    try {
      const res = await ApiFetch({
        url: "myadmin/plan/",
      });
      return res.data;
    } catch (error) {
      return error;
    }
  }
);
export const getWorkPage_plan_list = createAsyncThunk(
  "admin/getWorkPage_plan_list",
  async () => {
    try {
      const res = await ApiFetch({
        url: "myadmin/plan-list/",
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const put_plan_list = createAsyncThunk(
  "admin/put_plan_list",
  async (obj, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/plan-crud/${obj.id}/`,
        method: "PUT",
        body: {
          manager: +obj.manager_id,
          amount: +obj.amount,
          done: +obj.done,
        },
      });
      dispatch(getWorkPage_plan_list());
      return res;
    } catch (error) {
      return [];
    }
  }
);
export const post_plan_list = createAsyncThunk(
  "admin/post_plan_list",
  async (obj, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/plan-crud/`,
        method: "POST",
        body: {
          manager: +obj.manager,
          amount: +obj.amount,
          done: +obj.done,
        },
      });
      dispatch(getWorkPage_plan_list());
      return res;
    } catch (error) {
      return [];
    }
  }
);
export const delete_plan_list = createAsyncThunk(
  "admin/delete_plan_list",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/plan-crud/${id}/`,
        method: "DELETE",
      });
      console.log(res);
      return res;
    } catch (error) {
      return error;
    }
  }
);
//-------------------------------------------------------------------
export const getWorkPage_pay_history = createAsyncThunk(
  "admin/works/pay_history",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/pay-history/`,
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const getWorkPage_pay_list = createAsyncThunk(
  "admin/works/pay_list",
  async (url) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/pay-list/search/?${url || "page=1"}`,
      });
      return res;
    } catch (error) {
      return [];
    }
  }
);

//----------------------------------------------------------------------------------
export const getWorkPage_terms = createAsyncThunk(
  "admin/works/terms",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/terms/`,
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);
//-------------------------------------------------------------------------------------
export const getWorkPage_limits = createAsyncThunk(
  "workpage/getWorkPage_limits",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/limits/`,
      });
      return res;
    } catch (error) {
      return [];
    }
  }
);
export const getWorkPage_limits_list = createAsyncThunk(
  "admin/limits_list",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/limits-list/`,
      });
      return res;
    } catch (error) {
      return [];
    }
  }
);
export const putRequest_limits_list = createAsyncThunk(
  "admin/works/put_limits_list",
  async (obj, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/limits-crud/${obj.isOpenEditModal.id}/`,
        method: "PUT",
        body: {
          company: obj.isOpenEditModal.company_id,
          manager: obj.isOpenEditModal.manager_id,
          status: obj.getValuesSelect.value,
          comment: obj.isOpenEditModal.comment,
          tariff: obj.isOpenEditModal.tariff,
          tariff_dead_time: obj.isOpenEditModal.tariff_dead_time,
          tariff_price: obj.isOpenEditModal.tariff_price,
        },
      });
      dispatch(getWorkPage_limits_list());
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const delete_limits_list = createAsyncThunk(
  "workpage/delete_limits_list",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/limits-crud/${id}/`,
        method: "DELETE",
      });
      return res;
    } catch (error) {
      return [];
    }
  }
);

////-----------------------------------------------------------------------------------------
export const getWorkPage_dumps = createAsyncThunk(
  "admin/works/dumps",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/dumps/`,
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const getWorkPage_dumps_list = createAsyncThunk(
  "admin/works/dumps_list",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/dumps-list/`,
      });
      return res;
    } catch (error) {
      return [];
    }
  }
);
export const delete_dumps_list = createAsyncThunk(
  "admin/dumps_list",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/dumps-crud/${id}/`,
        method: "DELETE",
      });

      return res;
    } catch (error) {
      return error;
    }
  }
);

export const putREquest_dumps_list = createAsyncThunk(
  "admin/dumps_list",
  async (obj, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/dumps-crud/${obj.id}/`,
        method: "PUT",
        body: {
          status: obj.status,
          comment: obj.comment,
          last_transaction_amount: obj.last_transaction_amount,
          company: obj.company_id,
        },
      });
      dispatch(getWorkPage_dumps_list());
      return res;
    } catch (error) {
      return error;
    }
  }
);
///////////////------------------------------
export const getWorkPage_debtors = createAsyncThunk(
  "admin/works/debtors",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/debtors/`,
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);
export const getWorkPage_debtors_list = createAsyncThunk(
  "admin/works/debtors_list",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/debtors-list/`,
      });
      return res;
    } catch (error) {
      return error;
    }
  }
);

export const deptorsUpdate = createAsyncThunk(
  "admin/deptorsUpdate",
  async (obj, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/debtors-crud/${obj.data.id}/`,
        method: "PUT",
        body: {
          status: obj.state.status,
          comment: obj.state.comment,
          company: obj.data.company_id,
          transaction_amount: obj.data.transaction_amount,
        },
      });
      dispatch(getWorkPage_debtors_list());
      obj.onClose();
      return res;
    } catch (error) {
      return error;
    }
  }
);

export const delete_deptors = createAsyncThunk(
  "admin/delete_deptors",
  async (id, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/debtors-crud/${id}/`,
        method: "DELETE",
      });
      dispatch(getWorkPage_debtors_list());
      return res;
    } catch (error) {
      return error;
    }
  }
);
///--------------------------------------------------------------------------------------------
const initialState = {
  plan: {},
  terms: {},
  limits: {},
  dumps: {},
  debtors: {},
  pay_history: {},
  plan_list: {},
  dumps_list: [],
  statusdumpdelete: false,
  pay_list: [],
  limits_list: [],
  status_delete_limits_list: false,
  status_delete_debtors: "success",
  debtors_list: [],
  plandeletestatus: false,
};

const WorkPageSlice = createSlice({
  name: "workpage",
  initialState,
  extraReducers: {
    /// plan list
    [getWorkPage_plan.fulfilled]: (state, { payload }) => {
      state.plan = payload;
    },
    [getWorkPage_plan_list.fulfilled]: (state, { payload }) => {
      state.plan_list = payload;
    },
    [put_plan_list.fulfilled]: (state, { payload }) => {
      state.plan_list = payload;
    },
    [delete_plan_list.fulfilled]: (state) => {
      state.plandeletestatus = !state.plandeletestatus;
    },
    // pay history
    [getWorkPage_pay_history.fulfilled]: (state, { payload }) => {
      state.pay_history = payload;
    },
    [getWorkPage_pay_list.fulfilled]: (state, { payload }) => {
      state.pay_list = payload;
    },
    // terms
    [getWorkPage_terms.fulfilled]: (state, { payload }) => {
      state.terms = payload;
    },
    // limits
    [getWorkPage_limits.fulfilled]: (state, { payload }) => {
      state.limits = payload;
    },
    [getWorkPage_limits_list.fulfilled]: (state, { payload }) => {
      state.limits_list = payload;
    },
    [delete_limits_list.fulfilled]: (state) => {
      state.status_delete_limits_list = !state.status_delete_limits_list;
    },
    // dumps
    [getWorkPage_dumps.fulfilled]: (state, { payload }) => {
      state.dumps = payload;
    },
    [getWorkPage_dumps_list.fulfilled]: (state, { payload }) => {
      state.dumps_list = payload;
    },
    [delete_dumps_list.fulfilled]: (state) => {
      state.statusdumpdelete = !state.statusdumpdelete;
    },
    // debtors
    [getWorkPage_debtors.fulfilled]: (state, { payload }) => {
      state.debtors = payload;
    },
    [getWorkPage_debtors_list.fulfilled]: (state, { payload }) => {
      state.debtors_list = payload;
    },
    [deptorsUpdate.fulfilled]: (state) => {
      state.status = "success";
    },
    [delete_deptors.fulfilled]: (state) => {
      state.status_delete_debtors = !state.status_delete_debtors;
    },
  },
});

export const WorkPageActions = WorkPageSlice.actions;
export default WorkPageSlice;
