import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";

export const getAllCompanies = createAsyncThunk(
  "companies/getAllCompanies",
  async () => {
    try {
      const res = await ApiFetch({
        url: "myadmin/companies/",
      });
      return res;
    } catch (error) {
      return [];
    }
  }
);
export const findbyidCompanies = createAsyncThunk(
  "companies/findbyidCompanies",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/companies/${id}/`,
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const deleteWorkers = createAsyncThunk(
  "companies/deleteWorkers",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: `entity/personal/${id}/`,
        method: "DELETE",
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const addWorkers = createAsyncThunk(
  "companies/addWorkers",
  async (props) => {
    try {
      const res = await ApiFetch({
        url: "entity/personal/",
        method: "POST",
        body: props,
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const editWorkers = createAsyncThunk(
  "companies/editWorkers",
  async (props) => {
    try {
      const res = await ApiFetch({
        url: `entity/personal/${props.id}/`,
        method: "PUT",
        body: props,
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);

export const postCommentAdmin = createAsyncThunk(
  "companies/postCommentAdmin",
  async (props, { dispatch }) => {
    try {
      const res = await ApiFetch({
        url: props.url,
        method: props.method,
        body: props.body,
      });
      dispatch(getAllCompanies());
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const editRequesites = createAsyncThunk(
  "companies/editRequesites",
  async (props) => {
    delete props?.body?.id;
    delete props?.body?.user;
    delete props?.body?.image;
    try {
      const res = await ApiFetch({
        url: `entity/profile/${props?.id}/`,
        method: "PUT",
        body: {
          ...props.body,
        },
      });
      return res;
    } catch (e) {
      return [];
    }
  }
);
export const deleteRequestAdmin = createAsyncThunk(
  "companies/deleteRequestAdmin",
  async (id) => {
    try {
      const del = await ApiFetch({
        url: "profile/requisite-delete/",
        method: "POST",
        body: { requisite: id },
      });
      return del;
    } catch (e) {
      return [];
    }
  }
);
////---search-companies------------------------------------------

export const getManagersInSearsh = createAsyncThunk(
  "companies/getManagersInSearsh",
  async () => {
    try {
      const response = await ApiFetch({
        url: `myadmin/manager-list/`,
      });
      return response;
    } catch (error) {
      return [];
    }
  }
);

export const getSearchedCompanies = createAsyncThunk(
  "companies/getSearchedCompanies",
  async (url) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/companies/search/?${url}`,
      });
      return response;
    } catch (error) {
      return [];
    }
  }
);
///---------------------------------------------------------------------
const initialState = {
  allCompanies: [],
  managers: [],
  status: false,
  findbyIdCompanies: null,
  deletestatus: false,
  add_workers_status: false,
  loader: false,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  extraReducers: {
    [deleteRequestAdmin.fulfilled]: (state) => {
      state.status = !state.status;
    },
    [editRequesites.fulfilled]: (state) => {
      state.status = !state.status;
    },
    [editWorkers.fulfilled]: (state) => {
      state.add_workers_status = !state.add_workers_status;
    },
    [addWorkers.fulfilled]: (state) => {
      state.add_workers_status = !state.add_workers_status;
    },
    [deleteWorkers.fulfilled]: (state) => {
      state.deletestatus = !state.deletestatus;
    },
    [getAllCompanies.fulfilled]: (state, { payload }) => {
      state.allCompanies = payload;
    },
    [getSearchedCompanies.pending]: (state) => {
      state.loader = true;
    },
    [getSearchedCompanies.fulfilled]: (state, { payload }) => {
      state.allCompanies = payload;
      state.loader = false;
    },
    [getSearchedCompanies.rejected]: (state) => {
      state.loader = false;
    },
    [getSearchedCompanies.fulfilled]: (state, { payload }) => {
      state.allCompanies = payload;
    },
    [getManagersInSearsh.fulfilled]: (state, { payload }) => {
      state.managers = payload;
    },
    [postCommentAdmin.fulfilled]: (state) => {
      state.status = !state.status;
    },
    [findbyidCompanies.fulfilled]: (state, { payload }) => {
      state.findbyIdCompanies = payload;
    },
  },
});

export default companiesSlice;
