import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiFetch from "../../api/fetchApi";

export const getAllVacancies = createAsyncThunk(
  "main_allvacancies/getAllVacancies",
  async () => {
    try {
      const res = await ApiFetch({
        url: `myadmin/vacancies/`,
      });
      return res;
    } catch (error) {
      return {};
    }
  }
);

export const getVacanciesById = createAsyncThunk(
  "main_allvacancies/getVacanciesById",
  async (id) => {
    try {
      const res = await ApiFetch({
        url: `myadmin/vacancies/${id}/`,
      });
      return res;
    } catch (error) {
      return {};
    }
  }
);
export const getModerationVacancies = createAsyncThunk(
  "main_allvacancies/getModerationVacancies",
  async (page) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/vacancies-moderation/?page=${page || 1}`,
      });
      return response;
    } catch (e) {
      return {};
    }
  }
);
export const getModerationVacanciesById = createAsyncThunk(
  "main_allvacancies/getModerationVacanciesById",
  async (id) => {
    try {
      const response = await ApiFetch({
        url: `myadmin/vacancies-moderation/${id}/`,
      });
      return response;
    } catch (e) {
      return {};
    }
  }
);
export const getFilterVacancies = createAsyncThunk(
  "resumesadmin/getFilterVacancies",
  async (url) => {
    try {
      const res = await ApiFetch({ url: `myadmin/vacancies/search/?${url}` });
      return res;
    } catch (e) {
      return [];
    }
  }
);

const initialState = {
  main_vacancies: {},
  moderationvacancies: {},
  status: null,
  moderationvacanciesById: [],
  getvacanById: [],
};

const AllVacanciesSlice = createSlice({
  name: "main_allvacancies",
  initialState,
  extraReducers: {
    [getVacanciesById.fulfilled]: (state, { payload }) => {
      state.getvacanById = [payload];
    },
    [getModerationVacanciesById.fulfilled]: (state, { payload }) => {
      state.moderationvacanciesById = [payload];
    },
    [getAllVacancies.fulfilled]: (state, { payload }) => {
      state.main_vacancies = payload;
      state.status = "success";
    },
    [getAllVacancies.rejected]: (state) => {
      state.status = "error";
    },
    [getModerationVacancies.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.moderationvacancies = payload;
    },
    [getModerationVacancies.rejected]: (state) => {
      state.status = "error";
    },
    [getFilterVacancies.fulfilled]: (state, actions) => {
      state.main_vacancies = actions.payload;
    },
  },
});
export const allVacanciesActions = AllVacanciesSlice.actions;
export default AllVacanciesSlice;
