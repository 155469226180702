import { useContext } from 'react';
import { ContentContext } from '../../utils/ContentContext';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/present-animation.json';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CreatedPopup() {
  const { createdPopup, setCreatedPopup } = useContext(ContentContext);
  const navigate = useNavigate();

  const { user, user_status } = useSelector((s) => s.userState);

  const toPersonalRoute = () => {
    navigate(
      user !== null && (user_status === 'entity' ? `/company/` : '/me/')
    );
    setCreatedPopup(false);
  };

  const toMainRoute = () => {
    navigate('/');
    setCreatedPopup(false);
  };

  const defaultOptions = {
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className='popup'>
      <div
        className='popup__bg'
        onClick={() => setCreatedPopup(!createdPopup)}></div>
      <div className='popup__content'>
        <div className='created'>
          <div className='created__content'>
            <Lottie options={defaultOptions} height={120} width={172} />
            <div className='created__text'>
              <h3>Ваша заявка принята</h3>
              <p>
                После одобрения модераторами вам поступит оповещение на почту
              </p>
            </div>
            <div className='created__btns'>
              <button onClick={toMainRoute}>
                Остаться на главной странице
              </button>
              <button onClick={toPersonalRoute}>В личный кабинет</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatedPopup;
