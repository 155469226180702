import React from 'react';

const useTextWithLinks = (text, isHtml = false) => {
  const urlRegex = /((https?:\/\/)|(www\.))[^\s]+[^\s.,;:!"')\]\*]/gi;
  const mailRegex = /\S+@\S+\.\S+/gi;
  const phoneRegex = /(\+?\d[-.\s()]*){7,}/gi;
  const domainRegex =
    /(^|\s)((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}\b)/gi;
  // const patternSiteNames =
  //   /(Google|YouTube|Amazon|Facebook|Twitter|LinkedIn|Instagram|Netflix|eBay|Pinterest|PayPal|Reddit|Wikipedia|Tumblr|Dropbox|SoundCloud|Flickr|Quora|StackOverflow|GitHub|Medium|Vimeo|WordPress)\b/gi;

  const linkifiedText = text
    .replace(urlRegex, (url) => {
      let href = url;
      if (!url.includes('://')) {
        href = `http://${url}`;
      }
      return isHtml
        ? `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`
        : `[${url}](${href})`;
    })
    .replace(mailRegex, (email) => {
      return isHtml
        ? `<a href="mailto:${email}">${email}</a>`
        : `[${email}](mailto:${email})`;
    })
    .replace(phoneRegex, (phone) => {
      return isHtml
        ? `<a href="tel:${phone}">${phone}</a>`
        : ` [${phone}](tel:${phone})`;
    })
    .replace(domainRegex, (match, prefix, domain) => {
      let href = `http://${domain}`;
      return isHtml
        ? `${prefix}<a href="${href}" target="_blank" rel="noopener noreferrer">${domain}</a>`
        : `${prefix}[${domain}](${href})`;
    });
  // .replace(
  //   patternSiteNames,
  //   '<a href="http://www.$&.com" target="_blank" rel="noopener noreferrer">$&</a>'
  // );

  return linkifiedText;
};

export default useTextWithLinks;
