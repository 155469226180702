import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../../configs/axiosConfig';
import {
  setPostingsAndFavorites,
  setUser,
  setUserStatus,
} from '../features/userSlice';
import SetCookie from '../../hooks/cookies/setCookie';
import GetCookie from '../../hooks/cookies/getCookie';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}`,
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getMe: builder.query({
      query(data) {
        return {
          headers: {
            authorization: `Bearer ${GetCookie('access') || data.access}`,
          },
          url: `me-info/${
            GetCookie('status') === 'main' ||
            GetCookie('status') === 'moderator' ||
            GetCookie('status') === 'manager'
              ? 'applicant'
              : GetCookie('status') || data.user_status || data.status
          }/`,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
          dispatch(setPostingsAndFavorites(data));
          // dispatch(setUserStatus(data.user_status));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetMeQuery, useLazyGetMeQuery } = userApi;
